import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LinearProgress, Typography } from '@mui/material'
import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import { Stack } from '@mui/system'
import Table from '../../../components/Table'
import generateColumns from './columns'

export default function VehicleTypeDetailChassisTable({
  vehicleTypeId,
  history,
}) {
  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: {
      vehicleTypeId,
    },
    rowsSelected: [],
    tableData: null,
    loading: false,
    error: false,
  })

  useEffect(() => {
    if (!vehicleTypeId) {
      return
    }

    setTableState((state) => ({
      ...state,
      loading: !state.tableData,
      error: false,
    }))

    dataProvider
      .getList('chassis', {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      })
      .then((response) => {
        setTableState((state) => ({
          ...state,
          tableData: response.data,
          loading: false,
          error: false,
        }))
      })
      .catch(() => {
        setTableState((state) => ({
          ...state,
          tableData: null,
          loading: false,
          error: true,
        }))
        showNotification('error', t('errors.api-error'))
      })
  }, [tableState.page, tableState.sort, vehicleTypeId])

  useEffect(() => {
    if (!tableState.error) {
      return
    }

    showNotification('error', t('errors.api-error'))
  }, [tableState.error])

  if (tableState.loading) {
    return <LinearProgress />
  }

  if (!tableState.tableData || tableState.error) {
    return (
      <Stack direction="row" justifyContent="center" p={2}>
        <Typography>{t('errors.api-error')}</Typography>
      </Stack>
    )
  }

  const tableColumns = generateColumns({
    t,
    chassis: tableState.tableData,
  })

  const onChassisClick = (rowIndex) => {
    const chassisId = tableState.tableData.items[rowIndex].id
    history.push(`/chassis/detail/${chassisId}`)
  }

  return (
    <Table
      id="chassis-list"
      columns={tableColumns}
      data={tableState.tableData}
      page={tableState.page}
      sort={tableState.sort}
      onChangePage={(currentPage) => {
        setTableState((state) => ({ ...state, page: currentPage }))
      }}
      onColumnSortChange={(changedColumn, direction) => {
        const newSort = {
          field: changedColumn,
          direction: direction.toUpperCase(),
        }
        setTableState((state) => ({ ...state, sort: newSort }))
      }}
      onRowClick={(_, cellMeta) => {
        onChassisClick(cellMeta.rowIndex)
      }}
    />
  )
}
