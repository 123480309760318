import enums from '../../../utils/enums'

export default function generate({ t, chassis }) {
  return [
    {
      name: 'nParc',
      label: t('common.nParc'),
    },
    {
      name: 'nChassis',
      label: t('common.chassis'),
      options: {
        sort: false,
      },
    },
    {
      name: 'vehicleType.title',
      label: t('common.vehicleType'),
    },
    {
      name: 'operateur',
      label: t('common.operateur'),
      options: {
        sort: false,
      },
    },
    {
      name: 'depot',
      label: t('common.depot'),
      options: {
        sort: false,
      },
    },
    {
      name: 'location',
      label: t('common.location'),
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const wasValidationOverriden =
            chassis.items[tableMeta.rowIndex].wasValidationOverriden || false
          if (wasValidationOverriden) {
            return t('conformity.valid-overriden')
          }
          switch (val) {
            case enums.CONFORMITY.INVALID:
              return t('conformity.invalid')
            case enums.CONFORMITY.VALID:
              return t('conformity.valid')
            case enums.CONFORMITY.AWAITING_CONTROL:
              return t('conformity.waiting-result')
            default:
              return t('errors.not-valid')
          }
        },
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
