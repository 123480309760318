import React from 'react'
import FilePicker from '../FilePicker'

export default function ImagePicker({ onChange, onDelete, initialImages }) {
  return (
    <FilePicker
      onChange={onChange}
      onDelete={onDelete}
      initialFiles={initialImages}
      filesLimit={10}
      acceptedFiles={['image/jpeg', 'image/png']}
    />
  )
}
