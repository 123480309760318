import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import validators from '../../../../utils/validators'
import ImagePicker from '../../../ImagePicker'

export default function ModifyControlItemDialog({
  open,
  onClose,
  onModified,
  originalControlItem,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [nItem, setNItem] = useState()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()
  const [images, setImages] = useState([])

  useEffect(() => {
    setNItem(originalControlItem.nItem)
    setTitle(originalControlItem.title)
    setDescription(originalControlItem.description)
  }, [originalControlItem])

  const resetState = () => {
    // Clean form
    setNItem('')
    setTitle('')
    setDescription('')
    setError(null)
    setLoading(false)
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (nItem.length === 0) {
      setError('nItem_required')
      return
    }

    if (!validators.isValidNItem(nItem)) {
      setError('nItem_invalid')
      return
    }

    if (title.length === 0) {
      setError('title_required')
      return
    }

    if (description.length === 0) {
      setError('description_required')
      return
    }
    const data = {
      nItem,
      title,
      description,
    }
    setLoading(true)

    // Only upload new images
    const uploadedImageNames = originalControlItem.photos.map((url) =>
      url.substring(url.lastIndexOf('/') + 1)
    )
    Promise.all(
      images.map((image) => {
        const uploadedImageUrlIdx = uploadedImageNames.indexOf(image.name)
        if (uploadedImageUrlIdx !== -1) {
          return originalControlItem.photos[uploadedImageUrlIdx]
        }
        return dataProvider
          .upload({ file: image })
          .then((response) => response.photoUrl)
      })
    )
      .then((urls) => {
        data.photos = urls

        return dataProvider
          .update('control-item', { data, id: originalControlItem.id })
          .then(() => {
            showNotification('success', t('modify-control-item-dialog.success'))
            onModified()
          })
          .catch(() => {
            showNotification('error', t('errors.api-error'))
          })
      })
      .catch(() => {
        showNotification('error', t('errors.api-error'))
      })
      .then(() => resetState())
  }

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        onCloseDialog()
      }}
      onConfirm={onConfirm}
      title={t('modify-control-item-dialog.title')}
      loading={loading}
      openButtonTitle={t('common.modify')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'nItem_required' || error === 'nItem_invalid'}
            helperText={
              error === 'nItem_invalid'
                ? t('errors.not-valid')
                : t('common.required')
            }
            id="nItem"
            label={t('common.nItem')}
            fullWidth
            value={nItem}
            onChange={(event) => setNItem(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'title_required'}
            helperText={t('common.required')}
            id="title"
            label={t('common.title')}
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'description_required'}
            helperText={t('common.required')}
            id="description"
            label={t('common.description')}
            multiline
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item>
          <ImagePicker
            onChange={setImages}
            initialImages={originalControlItem.photos}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
