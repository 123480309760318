import axios from 'axios'
import config from '../config'
import Storages from '../context/user/storages'
import Storage from '../context/user/storage'
import SerialQueue from '../utils/queue'

function debug(/*message*/) {
  // console.log(message)
}

const axiosRefreshClient = axios.create({
  baseURL: `${config.apiBaseUrl}/su/auth`,
})

const logout = () => {
  Storage.clearAll()
  window.location = '/login'
}

const shouldIntercept = (error) => {
  try {
    return error.response.status === 401
  } catch (e) {
    return false
  }
}

const setTokenData = (tokenData = {}) => {
  Storages.tokens.persist({
    token: tokenData.token,
    refreshToken: tokenData.refreshToken,
  })
}

const inflateTokens = () => {
  const savedTokens = Storages.tokens.inflate()
  if (savedTokens && savedTokens.token && savedTokens.refreshToken) {
    return savedTokens
  }
  return null
}

const handleTokenRefresh = () => {
  const { refreshToken } = inflateTokens()
  return new Promise((resolve, reject) => {
    axiosRefreshClient
      .post('refresh', { refreshToken })
      .then(({ data }) => {
        const tokenData = {
          token: data.token,
          refreshToken: data.refreshToken,
        }
        resolve(tokenData)
      })
      .catch((err) => {
        reject(err)
        logout()
      })
  })
}

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `Bearer ${token}`
}

export default (axiosClient, customOptions = {}) => {
  const serialQueue = new SerialQueue()
  let isRefreshing = false

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  }

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }
    debug('INTERCEPTING')

    // eslint-disable-next-line no-underscore-dangle
    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      debug('IS REFRESHING')
      return serialQueue.enqueue(() => {
        const tokens = inflateTokens()
        // eslint-disable-next-line no-underscore-dangle
        if (tokens && tokens.token) {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, tokens.token)
          return axiosClient.request(originalRequest)
          // eslint-disable-next-line max-len
        }
        debug('Token unavailable!')
      })
    }

    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true
    isRefreshing = true
    debug('Will REFRESH')
    return serialQueue.enqueue(() => {
      debug('START REFRESHING')
      return new Promise((resolve, reject) => {
        options.handleTokenRefresh
          .call(options.handleTokenRefresh)
          .then((tokenData) => {
            options.setTokenData(tokenData, axiosClient)
            options.attachTokenToRequest(originalRequest, tokenData.token)
            resolve(axiosClient.request(originalRequest))
          })
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
            debug('STOPED REFRESHING!')
          })
      })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}
