import React from 'react'

// components
import { Grid, Button, IconButton } from '@mui/material'
import dateFnsFormat from 'date-fns/format'
import { ReactComponent as MoreIcon } from '../../assets/Icons_More_Horizontal.svg'
import enums from '../../utils/enums'
import { hasPermission } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'
import { consumeEvent } from '../../utils/wrapers'

export default function generate({
  t,
  classes,
  onControlDateClicked,
  onMoreActions,
  user,
  chassis,
  onModifyChassis,
}) {
  const controlsColumn = {
    name: 'controls',
    label: t('common.controls'),
    options: {
      sort: false,
      customBodyRender: (val, tableMeta) => {
        const renderControlDate = (control) => {
          const formatedDate = dateFnsFormat(
            Date.parse(control.controlDate),
            'dd/MM/yyyy'
          )
          const classNameMap = {
            [enums.CONFORMITY.INVALID]: classes.linkButtonRed,
            [enums.CONFORMITY.VALID]: classes.linkButtonGreen,
            [enums.CONFORMITY.AWAITING_CONTROL]: undefined,
          }
          const validationWasOverriden =
            typeof control.validationOverrideDetailsId !== 'undefined'
          const conformity = validationWasOverriden
            ? enums.CONFORMITY.VALID
            : control.conformity
          const className = classNameMap[conformity]
          return (
            <Grid item key={control.id}>
              <Button
                aria-haspopup="true"
                className={className}
                variant="text"
                // eslint-disable-next-line max-len
                onClick={consumeEvent((event) =>
                  onControlDateClicked(
                    event.currentTarget,
                    tableMeta.rowIndex,
                    control
                  )
                )}
              >
                {formatedDate} {validationWasOverriden && '*'}
              </Button>
            </Grid>
          )
        }
        return <Grid container>{val.map(renderControlDate)}</Grid>
      },
    },
  }

  return [
    {
      name: 'nParc',
      label: t('common.nParc'),
    },
    {
      name: 'nChassis',
      label: t('common.chassis'),
      options: {
        sort: true,
        customBodyRender: (val, tableMeta) => {
          if (
            hasPermission(
              Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_OPERATOR_DEPOT_LOCATION,
              user
            )
          ) {
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={consumeEvent(() =>
                  onModifyChassis(tableMeta.rowIndex)
                )}
                color="primary"
              >
                {chassis.items[tableMeta.rowIndex].nChassis}
              </Button>
            )
          }
          return chassis.items[tableMeta.rowIndex].nChassis
        },
      },
    },
    {
      name: 'vehicleType.title',
      label: t('common.vehicleType'),
    },
    {
      name: 'operateur',
      label: t('common.operateur'),
      options: {
        sort: true,
      },
    },
    {
      name: 'depot',
      label: t('common.depot'),
      options: {
        sort: true,
      },
    },
    {
      name: 'location',
      label: t('common.location'),
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: true,
        customBodyRender: (val, tableMeta) => {
          const wasValidationOverriden =
            chassis.items[tableMeta.rowIndex].wasValidationOverriden || false
          if (wasValidationOverriden) {
            return t('conformity.valid-overriden')
          }
          switch (val) {
            case enums.CONFORMITY.INVALID:
              return t('conformity.invalid')
            case enums.CONFORMITY.VALID:
              return t('conformity.valid')
            case enums.CONFORMITY.AWAITING_CONTROL:
              return t('conformity.waiting-result')
            default:
              return t('errors.not-valid')
          }
        },
      },
    },
    controlsColumn,
    {
      name: 'actions',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <IconButton
            className={[classes.allItemsButton].join(' ')}
            onClick={consumeEvent((event) =>
              onMoreActions(event.currentTarget, tableMeta.rowIndex)
            )}
            size="large"
          >
            <MoreIcon id="icon" />
          </IconButton>
        ),
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
