import React from 'react'

// components
import { Button } from '@mui/material'

export default function generate({
  t,
  classes,
  renderConformity,
  onShowPhotos,
}) {
  return [
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val) => renderConformity(val),
      },
    },
    {
      name: 'controlItem.nItem',
      label: t('common.nItem'),
      options: {
        sort: false,
      },
    },
    {
      name: 'controlItem.title',
      label: t('common.title'),
      options: {
        sort: false,
      },
    },
    {
      name: 'description',
      label: t('common.descriptionmn-non-conformity'),
      options: {
        sort: false,
      },
    },
    {
      name: 'photos',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val.length > 0) {
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onShowPhotos(val)}
                color="primary"
              >
                {t('common.photo')}
              </Button>
            )
          }
          return t('common.noPhoto')
        },
      },
    },
  ]
}
