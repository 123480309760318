import React from 'react'

// components
import { Button, Grid } from '@mui/material'

export default function generate({ t, classes, items, onModify, onDelete }) {
  return [
    {
      name: 'name',
      label: t('members-list.member-table-header'),
    },
    {
      name: 'vehicleTypes',
      label: t('members-list.vehicle-types-table-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (items[tableMeta.rowIndex] !== undefined) {
            const vehicleTypes = items[tableMeta.rowIndex].vehicleTypes || []
            return vehicleTypes.map((item) => (
              <Grid item key={item.id}>
                <span>{item.title}</span>
              </Grid>
            ))
          }

          return ''
        },
      },
    },
    {
      name: 'chassis',
      label: t('members-list.chassis-table-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (items[tableMeta.rowIndex] !== undefined) {
            const chassis = items[tableMeta.rowIndex].chassis || []
            return chassis.map((item) => (
              <Grid item key={item.id}>
                <span>{item.nChassis}</span>
              </Grid>
            ))
          }

          return ''
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDelete(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
