function get(httpClient) {
  return httpClient({
    url: '/permissions',
    method: 'GET',
  })
}

function post(role, permission, httpClient) {
  const body = JSON.stringify({ role, permission })

  return httpClient({
    url: '/permissions',
    method: 'POST',
    data: body,
  })
}

function deletePermission(role, permission, httpClient) {
  const body = JSON.stringify({ role, permission })

  return httpClient({
    url: '/permissions',
    method: 'DELETE',
    data: body,
  })
}

export default {
  get,
  post,
  deletePermission,
}
