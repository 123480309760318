import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  disabledIcon: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  documentsList: {
    padding: 0,
  },
  documentsListTitle: {
    paddingLeft: 5,
    paddingRight: 5,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.captGrey.light,
  },
  documentsListItem: {
    padding: 5,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.captGrey.light,
    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
  noPadding: {
    padding: 0,
  },
}))
