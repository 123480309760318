import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function CreateMemberDialog({ open, onClose, onCreated }) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [chassisIds, setChassisIds] = useState([])

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setName('')
    setChassisIds([])
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    setLoading(true)
    dataProvider.member
      .post(
        name,
        chassisIds.map((type) => type.id)
      )
      .then(() => {
        dataProvider.cache.reset()
        showNotification('success', t('create-member-dialog.success'))
        onCreated()
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'MEMBER_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('create-member-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-member-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-member-dialog.member-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setChassisIds}
            label={t('create-member-dialog.chassis')}
            resource="/members/autocomplete-chassis"
            error={error === 'chassisIds_required'}
            multiple
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
