import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function VehicleTypeAssociationDialog({
  open,
  onClose,
  onConfirm,
  originalDocumentTypes = [],
}) {
  const { t } = useTranslation()

  const [documentTypes, setDocumentTypes] = useState([])

  useEffect(() => {
    setDocumentTypes(originalDocumentTypes)
  }, [originalDocumentTypes])

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      onClose()
    }
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={() => onConfirm(documentTypes)}
      title={t('common.add')}
      openButtonTitle={t('common.confirm')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            onChange={(vals) => {
              const normalizedVals = vals.map((val) => {
                if (typeof val.requiredForConformance !== 'boolean') {
                  const mutableVal = val
                  mutableVal.requiredForConformance = false
                  return mutableVal
                }
                return val
              })
              setDocumentTypes(normalizedVals)
            }}
            value={documentTypes}
            label={t('vehicle-type-associate-document-type-dialog.documents')}
            resource="/document-type/autocomplete"
            multiple
            canDelete
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value) {
                setDocumentTypes(
                  documentTypes.concat({
                    id: (documentTypes.length + 1) * -1,
                    title: (e.target.value || '').trim(),
                    requiredForConformance: false,
                  })
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
