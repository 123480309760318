import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import CanvasDatagrid from './CanvasDatagrid'
import useStyles from './styles'
import validators from '../../../../../../utils/validators'
import NoticeBanner from '../../../../../NoticeBanner'
import { ReactComponent as WarningIcon } from '../../../../../../assets/Icons_Warning.svg'
import { useTranslation } from 'react-i18next'

function getErrors(rowsData) {
  const newErrors = new Set()
  rowsData.forEach((row, i) => {
    if (row.nItem == null || row.nItem === '') {
      const key = `${i}-${0}`
      newErrors.add(key)
    } else if (!validators.isValidNItem(row.nItem)) {
      const key = `${i}-${0}`
      newErrors.add(key)
    }
    if (row.title == null || row.title === '') {
      const key = `${i}-${1}`
      newErrors.add(key)
    }
    if (row.description == null || row.description === '') {
      const key = `${i}-${2}`
      newErrors.add(key)
    }
  })
  return newErrors
}

export default function PreviewFileStep({
  workbookData,
  details,
  onConfirm = () => {},
}) {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const canvasRef = useRef(null)

  const [errors, setErrors] = useState({
    set: new Set(),
    count: 0,
  })

  useEffect(() => {
    const errorsSet = getErrors(workbookData)
    setErrors({
      set: errorsSet,
      count: errorsSet.size,
    })
  }, [workbookData])

  const prepareAndSubmit = () => {
    const canvasDataGridData =
      (canvasRef.current && canvasRef.current.data) || []
    const mappedData = canvasDataGridData.map((row) => ({
      description: row.description,
      nItem: row.nItem,
      photosJson: row.photosJson,
      title: row.title,
    }))
    onConfirm(mappedData, details) // Images will probably be in the state of the parent
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">Prévisualisation et edition</Typography>
      </Grid>
      {errors.count > 0 && (
        <Grid item style={{ display: 'flex' }}>
          <NoticeBanner
            message={t(
              'create-vehicle-type-dialog.bulk-upload-wizard.preview-error-label',
              { nErrors: errors.count }
            )}
            color={theme.palette.danger.main}
            IconElement={WarningIcon}
          />
        </Grid>
      )}
      <Grid item className={classes.gridContainer}>
        {workbookData && (
          <CanvasDatagrid
            ref={canvasRef}
            data={workbookData}
            onEditSuccess={(row, column) => {
              setErrors((errors) => {
                const key = `${row}-${column}`
                errors.set.delete(key)
                return {
                  ...errors,
                  count: errors.set.size,
                }
              })
            }}
            onEditFailure={(row, column) => {
              setErrors((errors) => {
                const key = `${row}-${column}`
                errors.set.add(key)
                return {
                  ...errors,
                  count: errors.set.size,
                }
              })
            }}
            onAddRow={(rowIndex) => {
              setErrors((errors) => {
                errors.set.add(`${rowIndex}-${0}`)
                errors.set.add(`${rowIndex}-${1}`)
                errors.set.add(`${rowIndex}-${2}`)
                return {
                  ...errors,
                  count: errors.set.size,
                }
              })
            }}
            onDeleteRow={(rowIndex) => {
              setErrors((errors) => {
                errors.set.forEach((key) => {
                  if (key.startsWith(`${rowIndex}-`)) {
                    errors.set.delete(key)
                  }
                })
                return {
                  ...errors,
                  count: errors.set.size,
                }
              })
            }}
          />
        )}
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={errors.count > 0}
          onClick={prepareAndSubmit}
        >
          Importer
        </Button>
      </Grid>
    </Grid>
  )
}
