function getOverrideValidationConstraints(ids, httpClient) {
  const body = JSON.stringify({ chassisIds: ids || [] })

  return httpClient({
    url: '/chassis/override-validation-constraints',
    method: 'POST',
    data: body,
  }).then((res) => res.data)
}

function overrideValidation(ids, httpClient) {
  const body = JSON.stringify({ chassisIds: ids || [] })

  return httpClient({
    url: '/chassis/override-validation',
    method: 'POST',
    data: body,
  }).then((res) => res.data)
}

function deleteBulk(chassisIds, httpClient) {
  const body = JSON.stringify(chassisIds)

  return httpClient({
    url: `/chassis/bulk`,
    method: 'DELETE',
    data: body,
  })
}

export default {
  getOverrideValidationConstraints,
  overrideValidation,
  deleteBulk,
}
