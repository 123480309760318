import React, { useState } from 'react'
import {
  Button,
  Grid,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material'
import FilePicker from '../../../../../FilePicker'
import NoticeBanner from '../../../../../NoticeBanner'
import { ReactComponent as DocumentIcon } from '../../../../../../assets/Icons_Document.svg'
import { ReactComponent as WarningIcon } from '../../../../../../assets/Icons_Warning.svg'
import Template from './type-vehicle-items-template.xlsx'
import { useTranslation } from 'react-i18next'

const MAX_FILE_SIZE_IN_BYTES = 100000000 // 100 MB

export default function ImportFileStep({
  selectedFileName,
  emptyFile,
  importingFile,
  onConfirm = () => {},
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [state, setState] = useState({
    importedFiles: [],
    selectedFileName,
    selectedFile: null,
  })
  const currentFileMatchesSuper = selectedFileName === state.selectedFileName

  const onConfirmClick = (evt) => {
    evt.stopPropagation()
    onConfirm(!!state.selectedFile, state.selectedFile, state.selectedFileName)
  }

  const onImportFiles = async (files) => {
    if (!Array.isArray(files) || files.length === 0) {
      return
    }
    const file = files[0]
    setState({
      importedFiles: files,
      selectedFileName: file.name,
      selectedFile: file,
    })
  }

  const onRemoveDocumentClick = (evt) => {
    evt.stopPropagation()
    setState({
      importedFiles: [],
      selectedFileName: null,
      selectedFile: null,
    })
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h5">Items</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          1 - Assurez-vous d’utiliser notre template xlsx. Téléchargez-le{' '}
          <a href={Template} download="type-vehicle-items-template.xlsx">
            ici
          </a>
          .
        </Typography>
        <Typography variant="body2">
          2 - Assurez-vous que les informations correspondent aux bonnes
          colonnes;
        </Typography>
        <Typography variant="body2">
          3 - Vous pouvez importer le fichier en format .xlsx ou csv.
        </Typography>
      </Grid>
      {state.selectedFileName ? (
        emptyFile && currentFileMatchesSuper ? (
          <Grid item style={{ display: 'flex' }}>
            <NoticeBanner
              message={t(
                'create-vehicle-type-dialog.bulk-upload-wizard.import-empty-file-error',
                { fileName: state.selectedFileName }
              )}
              color={theme.palette.danger.main}
              IconElement={WarningIcon}
              onButtonClick={onRemoveDocumentClick}
            />
          </Grid>
        ) : (
          <Grid item style={{ display: 'flex' }}>
            <NoticeBanner
              message={state.selectedFileName}
              color={theme.palette.primary.main}
              backgroundColor="#E8F3FA"
              IconElement={DocumentIcon}
              onButtonClick={onRemoveDocumentClick}
            />
          </Grid>
        )
      ) : (
        <Grid item>
          <FilePicker
            initialFiles={state.importedFiles}
            filesLimit={1}
            maxFileSize={MAX_FILE_SIZE_IN_BYTES}
            acceptedFiles={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
              'text/csv',
            ]}
            onChange={onImportFiles}
          />
        </Grid>
      )}
      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={!state.selectedFileName || importingFile}
          onClick={onConfirmClick}
        >
          {importingFile && (
            <Grid item mr={1.5} mt={0.5}>
              <CircularProgress
                size="1rem"
                style={{ color: theme.palette.secondary.contrastText }}
                disableShrink
                visibility={importingFile ? 'visible' : 'hidden'}
              />
            </Grid>
          )}
          Confirmer
        </Button>
      </Grid>
    </Grid>
  )
}
