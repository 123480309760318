import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import AutoCompleteInput from '../../AutoCompleteInput'

export default function ModifyEmailListDialog({
  open,
  onClose,
  onModified,
  emailListToEdit,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState(emailListToEdit && emailListToEdit.name)
  const [description, setDescription] = useState(
    emailListToEdit && emailListToEdit.description
  )
  const [emails, setEmails] = useState(
    (emailListToEdit &&
      emailListToEdit.emails.map((email) => ({
        id: email.id,
        title: email.email,
      }))) ||
      []
  )

  const resource =
    'emails/lists' + (emailListToEdit && '/' + emailListToEdit.id) + '/edit'

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setName(emailListToEdit && emailListToEdit.name)
    setDescription(emailListToEdit && emailListToEdit.description)
    setEmails(
      (emailListToEdit &&
        emailListToEdit.emails.map((email) => ({
          id: email.id,
          title: email.email,
        }))) ||
        []
    )
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (description.length === 0) {
      setError('description_required')
      return
    }

    if (emails.length === 0) {
      setError('emailIds_required')
      return
    }

    const body = {
      data: {
        name,
        description,
        emailIds: emails.map((email) => email.id),
      },
    }

    setLoading(true)
    dataProvider
      .put(resource, body)
      .then(() => {
        dataProvider.cache.reset()
        showNotification('success', t('modify-email-list-dialog.success'))
        onModified()
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'EMAIL_LIST_NOT_FOUND') {
          showNotification(
            'error',
            t('modify-email-list-dialog.not-found-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    if (emailListToEdit) {
      setName(emailListToEdit.name)
      setDescription(emailListToEdit.description)
      setEmails(
        emailListToEdit.emails.map((email) => ({
          id: email.id,
          title: email.email,
        }))
      )
    }
  }, [emailListToEdit])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-email-list-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('modify-email-list-dialog.list-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'description_required'}
            helperText={t('common.required')}
            id="description"
            label={t('modify-email-list-dialog.description')}
            fullWidth
            multiline
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setEmails}
            value={emails}
            label={t('modify-email-list-dialog.emails')}
            resource="/emails/autocomplete"
            error={error === 'emailIds_required'}
            helperText={t('common.required')}
            multiple
            required
            canDelete
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value) {
                setEmails(
                  setEmails.concat({
                    id: (setEmails.length + 1) * -1,
                    title: (e.target.value || '').trim(),
                  })
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
