const CONFORMITY = {
  INVALID: 0,
  VALID: 1,
  AWAITING_CONTROL: 2,
  isValid(value) {
    let val = value
    if (typeof value !== 'number') {
      if (value === '0' || value === '1' || value === '2') {
        val = Number(value)
      }
    }
    return (
      val === this.INVALID ||
      val === this.VALID ||
      val === this.AWAITING_CONTROL
    )
  },
}

export default { CONFORMITY }
