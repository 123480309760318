import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

export default function OverrideValidationChassisDialog({
  open,
  onClose,
  onSuccess,
  chassisIds,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [constraints, setConstraints] = useState({})
  const translations = constraints.translations || {}

  const resetState = () => {
    setConstraints({})
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    setLoading(true)
    dataProvider.chassis
      .overrideValidation(chassisIds)
      .then(() => {
        dataProvider.cache.reset()
        setLoading(false)
        showNotification(
          'success',
          t('override-validation-chassis-dialog.success')
        )
        onSuccess()
        resetState()
      })
      .catch(() => {
        setLoading(false)
        showNotification('error', t('errors.api-error'))
      })
  }

  useEffect(() => {
    if (open && Array.isArray(chassisIds) && chassisIds.length > 0) {
      setLoading(true)
      dataProvider.chassis
        .getOverrideValidationConstraints(chassisIds)
        .then((data) => setConstraints(data))
        .finally(() => setLoading(false))
    }
  }, [chassisIds, open, dataProvider.chassis])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('override-validation-chassis-dialog.title')}
      loading={loading}
      hideActions={
        constraints.constraints &&
        constraints.constraints.notOkControlsCount === 0
      }
    >
      <Grid container direction="column" spacing={4}>
        {translations.constraint && (
          <Grid item>
            <Typography>{translations.constraint}</Typography>
          </Grid>
        )}
        {translations.question && (
          <Grid item>
            <Typography>{translations.question}</Typography>
          </Grid>
        )}
      </Grid>
    </BaseDialog>
  )
}
