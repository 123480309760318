import React from 'react'
import LRU from 'lru-cache'
import { stringify } from 'query-string'

import axios from 'axios'
import config from '../config'
import AuthRetrier from '../api/authRetrier'

import ChassisDocumentsAPI from '../api/chassis/documents'
import ChassisAPI from '../api/chassis'
import ControlAPI from '../api/control'
import AutocompleteEmailsAPI from '../api/utils/autocomplete-emails'
import ConstructorAPI from '../api/constructor'
import OperatorAPI from '../api/operator'
import ContractorAPI from '../api/contractor'
import MembersAPI from '../api/members'
import PermissionsAPI from '../api/permissions'
import VehicleTypeAPI from '../api/vehicle-type'
import BulkUploadAPI from '../api/bulk-upload'
import Storages from './user/storages'

const cacheOptions = {
  max: 100,
  maxAge: 1000 * 60, // 1 minute in miliseconds
}

const cache = new LRU(cacheOptions)

const DataApiContext = React.createContext()
const worldHTTPClient = axios.create()

let httpClient = null

const getAuthToken = () => {
  const tokens = Storages.tokens.inflate()
  if (tokens) {
    const { token } = tokens
    return token
  }
  return null
}

const getHTTPClient = () => {
  if (!httpClient) {
    console.log('Creating http client')
    const headers = {
      'Content-Type': 'application/json',
    }
    const token = getAuthToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    httpClient = axios.create({
      baseURL: `${config.apiBaseUrl}/su`,
      headers,
    })
    AuthRetrier(httpClient)
  }
  return httpClient
}

const resetHTTPClient = () => {
  console.log('Reseting http client')
  cache.reset()
  httpClient = null
}

function prepareUrlForQuery(resource, range, filters, sort, merge) {
  const { page, limit } = range || {}
  let query = {
    ...merge,
  }
  if (filters) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(filters)) {
      query[`filters[${key}]`] = value
    }
  }
  if (page) {
    query = { ...query, 'range[page]': page }
  }
  if (limit) {
    query = { ...query, 'range[limit]': limit }
  }
  if (sort) {
    const { field, direction } = sort
    const parts = field.split('.')
    if (parts.length > 0) {
      const sortKey = parts.reduce((key, part) => `${key}[${part}]`, 'sort')
      query = {
        ...query,
        [sortKey]: direction,
      }
    }
  }

  const stringifiedQuery = stringify(query)
  return [resource, stringifiedQuery].filter((el) => el).join('?')
}

function ApiDataProvider({ children }) {
  const dataProvider = {
    get: (resource, params) => {
      let url = `${resource}`
      if (params.query) {
        url += `?${stringify(params.query)}`
      }
      const cachedData = cache.get(url)
      if (cachedData) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    post: (resource, params) => {
      cache.reset()
      const url = `${resource}`
      return getHTTPClient()({
        url,
        method: 'POST',
        data: JSON.stringify(params.data),
      })
    },
    put: (resource, params) => {
      cache.reset()
      const url = `${resource}`
      return getHTTPClient()({
        url,
        method: 'PUT',
        data: JSON.stringify(params.data),
      })
    },
    getList: (resource, params, merge, useCache = true) => {
      const url = prepareUrlForQuery(
        resource,
        params.range,
        params.filters,
        params.sort,
        merge
      )
      const cachedData = cache.get(url)
      if (cachedData && useCache) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    getOne: (resource, params) => {
      const url = `${resource}/${params.id}`
      const cachedData = cache.get(url)
      if (cachedData) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    update: (resource, params) => {
      cache.reset()
      return getHTTPClient()({
        url: `${resource}/${params.id}/edit`,
        method: 'PUT',
        data: JSON.stringify(params.data),
      })
    },
    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient({
            method: 'PUT',
            url: `${resource}/${id}`,
            body: JSON.stringify(params.data),
          })
        )
      ),
    create: (resource, params) => {
      cache.reset()
      return getHTTPClient()({
        url: `${resource}`,
        method: 'POST',
        data: JSON.stringify(params.data),
      })
    },
    delete: (resource, params) => {
      cache.reset()
      return getHTTPClient()({
        url: `${resource}/${params.id}`,
        method: 'DELETE',
      })
    },
    deleteMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          getHTTPClient()({ url: `/${resource}/${id}`, method: 'DELETE' })
        )
      ),
    deleteBulk: (resource, body) => {
      cache.reset()
      return getHTTPClient()({
        url: resource,
        data: JSON.stringify(body),
        method: 'DELETE',
      })
    },
    upload: (params) => {
      const { file } = params
      return getHTTPClient()({
        url: '/upload',
        method: 'POST',
      }).then((response) => {
        const headersUpload = response.data.uploadHeaders || {}
        headersUpload['Content-Type'] = file.type
        return worldHTTPClient({
          url: response.data.uploadUrl,
          method: response.data.uploadHttpMethod,
          headers: headersUpload,
          data: file,
        }).then(() => Promise.resolve({ photoUrl: response.data.photoUrl }))
      })
    },
    prepareUrlForQuery,
    generateExportUrl: (resource, params, type) => {
      const url = prepareUrlForQuery(
        `${resource}/${type}`,
        params.range,
        params.filters,
        params.sort,
        {
          token: getAuthToken(),
        }
      )

      return `${config.apiBaseUrl}/exports/${url}`
    },
    generatePVAdmissionExportUrl: (controlId) => {
      if (typeof controlId !== 'number') {
        return null
      }
      return `${config.apiBaseUrl}/exports/control/${controlId}/pv-admission`
    },

    // New API context structure
    chassis: {
      getOverrideValidationConstraints: (ids) =>
        ChassisAPI.getOverrideValidationConstraints(ids, getHTTPClient()),
      overrideValidation: (ids) =>
        ChassisAPI.overrideValidation(ids, getHTTPClient()),
      documents: {
        get: (chassisId) => ChassisDocumentsAPI.get(chassisId, getHTTPClient()),
        upload: (response, file) =>
          ChassisDocumentsAPI.upload(response, file, worldHTTPClient),
        requestUpload: (chassisId) =>
          ChassisDocumentsAPI.requestUpload(chassisId, getHTTPClient()),
        post: (chassisId, type, url, originalName) =>
          ChassisDocumentsAPI.post(
            chassisId,
            type,
            url,
            originalName,
            getHTTPClient()
          ),
        deleteDocuments: (chassisId, types) =>
          ChassisDocumentsAPI.deleteDocuments(
            chassisId,
            types,
            getHTTPClient()
          ),
        downloadDocuments: (chassisId, types) =>
          ChassisDocumentsAPI.downloadDocuments(
            chassisId,
            types,
            getHTTPClient()
          ),
      },
      deleteBulk: (chassisIds) =>
        ChassisAPI.deleteBulk(chassisIds, getHTTPClient()),
    },

    control: {
      getDeleteWaitingResultConstraints: (ids) =>
        ControlAPI.getDeleteWaitingResultConstraints(ids, getHTTPClient()),
      deleteWaitingResult: (ids) =>
        ControlAPI.deleteWaitingResult(ids, getHTTPClient()),
      emailResult: (controlId, emails, sendTo) =>
        ControlAPI.emailResult(controlId, emails, sendTo, getHTTPClient()),
      emailResultByList: (controlId, emailLists) =>
        ControlAPI.emailResultByList(controlId, emailLists, getHTTPClient()),
    },

    utils: {
      autocompleteEmails: () =>
        AutocompleteEmailsAPI.autocompleteEmails(getHTTPClient()),
      autocompleteEmailLists: (query) =>
        AutocompleteEmailsAPI.autocompleteEmailLists(query, getHTTPClient()),
    },

    cache,

    constructor: {
      getList: (resource, params, useCache) =>
        ConstructorAPI.getList(resource, params, getHTTPClient(), useCache),
      post: (name, vehicleTypeIds) =>
        ConstructorAPI.post(name, vehicleTypeIds, getHTTPClient()),
      put: (constructorId, name, vehicleTypeIds) =>
        ConstructorAPI.put(
          constructorId,
          name,
          vehicleTypeIds,
          getHTTPClient()
        ),
    },
    operator: {
      getList: (resource, params, useCache) =>
        OperatorAPI.getList(resource, params, getHTTPClient(), useCache),
      post: (name, depots, memberIds) =>
        OperatorAPI.post(name, depots, memberIds, getHTTPClient()),
      put: (operatorId, name, depots, memberIds) =>
        OperatorAPI.put(operatorId, name, depots, memberIds, getHTTPClient()),
    },
    contractor: {
      getList: (resource, params, useCache) =>
        ContractorAPI.getList(resource, params, getHTTPClient(), useCache),
      post: (firstName, lastName, email) =>
        ContractorAPI.post(firstName, lastName, email, getHTTPClient()),
      put: (contractorId, firstName, lastName) =>
        ContractorAPI.put(contractorId, firstName, lastName, getHTTPClient()),
    },
    member: {
      getList: (resource, params, useCache) =>
        MembersAPI.getList(resource, params, getHTTPClient(), useCache),
      post: (name, chassisIds) =>
        MembersAPI.post(name, chassisIds, getHTTPClient()),
      put: (memberId, name, chassisIds) =>
        MembersAPI.put(memberId, name, chassisIds, getHTTPClient()),
      resetCache: () => {
        MembersAPI.cache.reset()
      },
    },
    permissions: {
      get: () => PermissionsAPI.get(getHTTPClient()),
      post: (role, permission) =>
        PermissionsAPI.post(role, permission, getHTTPClient()),
      deletePermission: (role, permission) =>
        PermissionsAPI.deletePermission(role, permission, getHTTPClient()),
    },
    vehicleType: {
      associateDocumentTypes: (vehicleTypeId, body) =>
        VehicleTypeAPI.associateDocumentTypes(
          vehicleTypeId,
          body,
          getHTTPClient()
        ),
      startItemsBulkUpload: () =>
        BulkUploadAPI.startVehicleTypeItems(getHTTPClient()),
      performBulkUpload: (uploadDetails, file) =>
        BulkUploadAPI.performUpload(uploadDetails, file, worldHTTPClient),
    },
  }

  return (
    <DataApiContext.Provider value={{ dataProvider }}>
      {children}
    </DataApiContext.Provider>
  )
}

function useDataApi() {
  const context = React.useContext(DataApiContext)
  if (context === undefined) {
    throw new Error('useDataState must be used within a ApiDataProvider')
  }
  return context
}

export { ApiDataProvider, useDataApi, resetHTTPClient }
