function emailResult(controlId, emails, sendTo, httpClient) {
  return httpClient({
    url: `/control/${controlId}/email-result`,
    data: JSON.stringify({
      emails,
      sendTo,
    }),
    method: 'POST',
  })
}

export default emailResult
