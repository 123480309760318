import React from 'react'

// components
import { Button, Typography } from '@mui/material'

export default function generate({
  t,
  classes,
  reorganizeItemFromId,
  onReorganize,
  onShowPhotos,
  onModify,
  onDelete,
}) {
  return [
    {
      name: 'itemOrder',
      label: t('common.item-number'),
      options: {
        sortThirdClickReset: true,
      },
    },
    {
      name: 'nItem',
      label: t('common.nItem'),
      options: {
        sortThirdClickReset: true,
      },
    },
    {
      name: 'title',
      label: t('common.title'),
      options: {
        sort: false,
      },
    },
    {
      name: 'description',
      label: t('common.description'),
      options: {
        sort: false,
        customBodyRender: (val) => (
          <Typography
            style={{
              wordBreak: 'break-all',
              fontSize: 15,
            }}
          >
            {val}
          </Typography>
        ),
      },
    },
    {
      name: 'photos',
      label: t('common.photo'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val && Array.isArray(val) && val.length > 0) {
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onShowPhotos(tableMeta.rowIndex)}
                color="primary"
              >
                {t('common.see')}
              </Button>
            )
          }
          return t('common.noPhoto')
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (reorganizeItemFromId !== null) {
            return ''
          }
          return (
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onModify(tableMeta.rowIndex)}
              color="primary"
            >
              {t('common.modify')}
            </Button>
          )
        },
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (reorganizeItemFromId !== null) {
            return ''
          }
          return (
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onDelete(tableMeta.rowIndex)}
              color="primary"
            >
              {t('common.delete')}
            </Button>
          )
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          let buttonText
          if (reorganizeItemFromId === null) {
            buttonText = t('common.reorganize')
          } else if (reorganizeItemFromId.id === val) {
            buttonText = t('common.cancel')
          } else {
            buttonText = t('common.here')
          }

          return (
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onReorganize(tableMeta.rowIndex)}
              color="primary"
            >
              {buttonText}
            </Button>
          )
        },
      },
    },
  ]
}
