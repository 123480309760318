class Storage {
  constructor(key) {
    this.key = key
  }

  inflate() {
    try {
      const encoded = localStorage.getItem(this.key)
      if (!encoded) {
        return null
      }
      const json = b64ToUtf8(encoded)
      return JSON.parse(json)
    } catch (err) {
      this.clear()
    }
  }

  persist(data) {
    const jsonStr = JSON.stringify(data)
    const encoded = utf8ToB64(jsonStr)
    localStorage.setItem(this.key, encoded)
  }

  clear() {
    localStorage.removeItem(this.key)
  }
}

Storage.clearAll = () => {
  localStorage.clear()
}

function utf8ToB64(str) {
  return window.btoa(unescape(encodeURIComponent(str)))
}

function b64ToUtf8(str) {
  return decodeURIComponent(escape(window.atob(str)))
}

export default Storage

export { utf8ToB64, b64ToUtf8 }
