import React from 'react'

// components
import { Button, Typography } from '@mui/material'
import dateFnsFormat from 'date-fns/format'
import enums from '../../utils/enums'

export default function generate({
  t,
  classes,
  onControlClick,
  renderConformity,
  computeControlledByLabel,
}) {
  return [
    {
      name: 'controlNumber',
      label: t('common.number'),
    },
    {
      name: 'controlDate',
      label: t('controls-list.controlDate'),
      options: {
        customBodyRender: (val, tableMeta) => {
          const formatedDate = dateFnsFormat(Date.parse(val), 'dd/MM/yyyy')
          const conformity = tableMeta.rowData[1]
          switch (conformity) {
            case enums.CONFORMITY.INVALID:
              return <Typography color="error">{formatedDate}</Typography>
            case enums.CONFORMITY.VALID:
              return (
                <Typography className={classes.greenText}>
                  {formatedDate}
                </Typography>
              )
            case enums.CONFORMITY.WAITING_CONTROL:
              return (
                <Typography className={classes.orangeText}>
                  {formatedDate}
                </Typography>
              )
            default:
              return <Typography>{formatedDate}</Typography>
          }
        },
      },
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val) => renderConformity(val),
      },
    },
    {
      name: 'submittedByUser',
      label: t('common.controlBy'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) =>
          computeControlledByLabel(tableMeta.rowData[1], val),
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        sort: false,
        customBodyRender: (val) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onControlClick(val)}
            color="primary"
          >
            {t('common.see')}
          </Button>
        ),
      },
    },
  ]
}
