import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  FormControl,
  IconButton,
} from '@mui/material'
import NativeSelect from '@mui/material/NativeSelect'
import { useQueryState } from '../../utils/query-state'
import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'
import Loading from '../../components/Loading'
import DatePicker from '../../components/DatePicker'
import { ReactComponent as HTMLIcon } from '../../assets/Icons_Html.svg'
import { ReactComponent as XLSIcon } from '../../assets/Icons_Xls.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons-Delete.svg'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import DeleteWaitingResultControlsDialog from '../../components/Control/components/DeleteWaitingResultControlsDialog'
import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import enums from '../../utils/enums'
import generateColumns from './columns'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function ControlsList({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { user } = useUserState()
  const hasPermissionToDeleteWaitingResultControls = useMemo(
    () => hasPermission(Permissions.PAGE_CONTROLS_DELETE_WAITING_RESULT, user),
    [user]
  )

  const query = useQuery()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [startDate, setStartDate] = useQueryState({
    paramName: 'startDate',
    history,
    query,
    fromQuery: useCallback((value) => (value ? new Date(value) : null), []),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [endDate, setEndDate] = useQueryState({
    paramName: 'endDate',
    history,
    query,
    fromQuery: useCallback(
      (value) => (value ? new Date(value) : new Date()),
      []
    ),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [vehicleType, setVehicleType] = useQueryState({
    paramName: 'vehicleType',
    history,
    query,
  })
  const [chassis, setChassis] = useQueryState({
    paramName: 'chassis',
    history,
    query,
  })
  const [conformity, setConformity] = useQueryState({
    paramName: 'conformity',
    history,
    query,
    defaultValue: '',
  })
  const [location, setLocation] = useQueryState({
    history,
    query,
    paramName: 'location',
  })
  const [nParc, setNParc] = useQueryState({
    history,
    query,
    paramName: 'nParc',
  })
  const [submittedByUser, setSubmittedByUser] = useQueryState({
    history,
    query,
    paramName: 'submittedByUser',
  })
  const [member, setMember] = useQueryState({
    history,
    query,
    paramName: 'member',
  })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      startDate: startDate instanceof Date ? startDate.toISOString() : null,
      endDate: endDate instanceof Date ? endDate.toISOString() : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      chassisId: chassis ? chassis.id : null,
      location: location ? location.id : null,
      conformity: enums.CONFORMITY.isValid(conformity) ? conformity : null,
      nParc: nParc ? nParc.title : null,
      submittedByUserId: submittedByUser ? submittedByUser.id : null,
      memberId: member ? member.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [
    startDate,
    endDate,
    vehicleType,
    chassis,
    location,
    conformity,
    nParc,
    submittedByUser,
    member,
  ])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({
    paramName: 'sort',
    history,
    query,
  })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page',
    defaultValue: 0,
    history,
    query,
  })
  const [tableState, setTableState] = useState({
    page: tablePage,
    sort: tableSort,
    filters: computedFilters,
    rowsSelected: [],
  })
  const [tableData, setTableData] = useState(null)

  const [
    deleteWaitingResultControlsDialog,
    setDeleteWaitingResultControlsDialog,
  ] = useState({
    open: false,
    controlIds: [],
  })

  const resource = 'control'

  useEffect(() => {
    dataProvider
      .getList(resource, {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      })
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = () => {
    setTableState({
      ...tableState,
      page: 0,
      filters: computedFilters,
      rowsSelected: [],
    })
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setChassis(null)
    setVehicleType(null)
    setLocation(null)
    setConformity('')
    setNParc(null)
    setSubmittedByUser(null)
    setMember(null)
    setTableState({
      ...tableState,
      page: 0,
      filters: null,
      rowsSelected: [],
    })
  }

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({
        page: tableState.page,
        sort: null,
        filters: null,
        rowsSelected: [],
      })
    } else {
      setTableState({
        page: 0,
        sort: null,
        filters: null,
        rowsSelected: [],
      })
    }
  }

  const onExportPvAdmission = (rowIndex) => {
    const url = dataProvider.generatePVAdmissionExportUrl(
      tableData.items[rowIndex].id
    )
    if (url) {
      window.open(url, '_blank')
    }
  }

  const onExportHtml = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'control/list',
      tableState,
      'html'
    )
    window.open(exportUrl, '_blank')
  }

  const onExportCsv = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'control/list',
      tableState,
      'csv'
    )
    window.open(exportUrl, '_blank')
  }

  const onControlClick = (id) => {
    history.push(`/controls/detail/${id}`)
  }

  const onDeleteButtonClick = (event) => {
    event.stopPropagation()

    const controlIds = tableState.rowsSelected.map(
      (idx) => tableData.items[idx].id
    )
    setDeleteWaitingResultControlsDialog({
      controlIds,
      open: true,
    })
  }

  const tableColumns = generateColumns({
    t,
    classes,
    tableData,
    onControlClick,
    onExportPvAdmission,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Grid container direction="column">
      <DeleteWaitingResultControlsDialog
        open={deleteWaitingResultControlsDialog.open}
        onClose={() => {
          setDeleteWaitingResultControlsDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        onSuccess={() => {
          refreshData(true)
          setDeleteWaitingResultControlsDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        controlIds={deleteWaitingResultControlsDialog.controlIds}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <PageTitle title={t('controls-list.title')} />
        </Grid>
        <Grid container item xs={6} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={<HTMLIcon color="primary" />}
              color="primary"
              variant="contained"
              fullWidth
              onClick={onExportHtml}
            >
              {t('common.export-pdf')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<XLSIcon color="primary" />}
              color="primary"
              variant="contained"
              fullWidth
              onClick={onExportCsv}
            >
              {t('common.export-csv')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={classes.filtersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid container item xs={12} md={6} alignItems="flex-end">
            <Grid item xs={12} md={5}>
              <DatePicker
                id="startDate"
                value={startDate}
                onChange={setStartDate}
                label={t('common.startDate')}
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" align="center">
                Au
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePicker
                id="endDate"
                value={endDate}
                onChange={setEndDate}
                label={t('common.endDate')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setVehicleType}
              label={t('common.vehicleType')}
              resource="vehicle-type/autocomplete"
              value={vehicleType}
              query={{ includeDisabled: false }}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setChassis}
              label={t('common.chassis')}
              resource="chassis/chassis-number-autocomplete"
              value={chassis}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xs={12} md={3}>
            <FormControl variant="standard" className={classes.selector}>
              <InputLabel
                id="conformity-label"
                shrink={enums.CONFORMITY.isValid(conformity)}
              >
                {t('common.conformity')}
              </InputLabel>
              <NativeSelect
                labelid="conformity-label"
                inputProps={{
                  name: t('common.conformity'),
                  id: 'conformity',
                }}
                onChange={(event) => setConformity(event.target.value)}
                value={conformity}
              >
                <option aria-label="None" value="" />
                <option value={enums.CONFORMITY.VALID}>
                  {t('conformity.valid')}
                </option>
                <option value={enums.CONFORMITY.INVALID}>
                  {t('conformity.invalid')}
                </option>
                <option value={enums.CONFORMITY.AWAITING_CONTROL}>
                  {t('conformity.waiting-result')}
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setNParc}
              label={t('common.nParc')}
              resource="chassis/parc-number-autocomplete"
              value={nParc}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setLocation}
              label={t('common.location')}
              resource="chassis/location-autocomplete"
              value={location}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setSubmittedByUser}
              label={t('common.submitted-by-user')}
              resource="chassis/user-autocomplete"
              value={submittedByUser}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setMember}
              label={t('common.member')}
              resource="members/autocomplete"
              value={member}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justifyContent="flex-end"
          className={classes.actionsContainer}
        >
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="secondary"
              variant="contained"
              onClick={clearFilters}
              fullWidth
            >
              {t('common.clean')}
            </Button>
          </Grid>
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={updateFilters}
            >
              {t('common.search')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          {hasPermissionToDeleteWaitingResultControls && (
            <Grid item>
              <IconButton
                className={[classes.disabledButton, classes.menuItemRow].join(
                  ' '
                )}
                onClick={onDeleteButtonClick}
                disabled={
                  tableState &&
                  tableState.rowsSelected &&
                  tableState.rowsSelected.length === 0
                }
                size="large"
              >
                <DeleteIcon id="icon" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer} xs={12}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({
              ...tableState,
              page: currentPage,
              rowsSelected: [],
            })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort, rowsSelected: [] })
          }}
          selectableRows={
            hasPermissionToDeleteWaitingResultControls && 'multiple'
          }
          rowsSelected={
            hasPermissionToDeleteWaitingResultControls &&
            tableState.rowsSelected
          }
          onRowSelectionChange={
            hasPermissionToDeleteWaitingResultControls &&
            ((rowsSelected, allRows) => {
              setTableState((state) => ({
                ...state,
                rowsSelected: allRows.map((row) => row.dataIndex),
              }))
            })
          }
        />
      </Grid>
    </Grid>
  )
}
