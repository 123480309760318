import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

// styles
import useStyles from './styles'

export default function Loading({ minHeight = '100vh' }) {
  const classes = useStyles({ minHeight })

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.container}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}
