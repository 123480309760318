import { v4 } from 'uuid'

export default function workify(worker) {
  let _callbackMapping = new Map()
  worker.onmessage = ({ data: { callId, output } }) => {
    if (!_callbackMapping.has(callId)) {
      console.log('Callback for', callId, 'not found. onmessage:', output)
      return
    }
    const callbackFn = _callbackMapping.get(callId)
    _callbackMapping.delete(callId)
    callbackFn(output)
  }
  worker.performAction = ({ action, args }, callbackFn) => {
    const callId = v4()
    _callbackMapping.set(callId, callbackFn)
    const message = {
      action,
      args,
      callId,
    }
    worker.postMessage(message)
  }
  return worker
}

export function createWorker(workerSelf, actions) {
  workerSelf.onmessage = ({ data: { action, args, callId } }) => {
    if (action in actions) {
      const output = actions[action].call(self, ...args)
      self.postMessage({ callId, output })
    }
  }
}
