import { emphasize } from '@mui/material'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  root: {
    padding: '20px',
  },
  circularStep: {
    width: 44,
    height: 44,
    borderRadius: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.selected': {
      cursor: 'default',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&.disabled': {
      cursor: 'default',
      backgroundColor: theme.palette.common.white,
      border: '2px solid #E0E0E0',
      color: '#BDBDBD',
    },
    '&.done': {
      cursor: 'pointer',
      backgroundColor: '#27AE60',
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: emphasize('#27AE60'),
      },
    },
  },
  lineBetweenStep: {
    minWidth: '100px',
    height: 4,
    '&.selected': {
      backgroundColor: '#E5E5E5',
    },
    '&.disabled': {
      backgroundColor: '#E5E5E5',
    },
    '&.done': {
      backgroundColor: '#27AE60',
    },
  },
  lineBetweenStepInner: {
    width: '50%',
    backgroundColor: theme.palette.primary.main,
    height: 4,
    '&.selected': {
      display: 'block',
    },
    '&.disabled': {
      display: 'none',
    },
    '&.done': {
      display: 'none',
    },
  },
}))
