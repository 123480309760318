import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import { Grid, Button, useMediaQuery, useTheme } from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'
import useStyles from './styles'
import { useUserState, hasPermission } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

// eslint-disable-next-line max-len
import CreateContractorDialog from '../../components/Users/components/CreateUserDialog'
// eslint-disable-next-line max-len
import ModifyContractorDialog from '../../components/Users/components/ModifyUserDialog'
import Table from '../../components/Table'
import generateColumns from './columns'

export default function ContractorsList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { user } = useUserState()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)
  const [openCreateContractorDialog, setOpenCreateContractorDialog] =
    useState(false)
  const [openModifyContractorDialog, setOpenModifyContractorDialog] =
    useState(false)
  const [contractorToModify, setContractorToModify] = useState(false)
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'contractors'

  useEffect(() => {
    dataProvider.contractor
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        false
      )
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModify = (rowIndex) => {
    setContractorToModify(tableData.items[rowIndex])
    setOpenModifyContractorDialog(true)
  }

  const onDelete = (rowIndex) => {
    const { id, firstName } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('contractors-list.confirm-contractor-deletion', { firstName }),
      () => {
        dataProvider
          .delete('users', { id })
          .then(() => {
            refreshData()
            showNotification('success', t('contractors-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      }
    )
  }

  const hasDeletePermission = hasPermission(
    Permissions.PAGE_USERS_DELETE_USER,
    user
  )

  const tableColumns = generateColumns({
    t,
    classes,
    onModify,
    onDelete,
    hasDeletePermission,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateContractorDialog
        title={t('create-user-dialog.contractor-title')}
        open={openCreateContractorDialog}
        onClose={() => setOpenCreateContractorDialog(false)}
        onCreated={() => {
          setOpenCreateContractorDialog(false)
          refreshData()
        }}
        isContractor
      />
      <ModifyContractorDialog
        title={t('modify-user-dialog.contractor-title')}
        open={openModifyContractorDialog}
        onClose={() => {
          setOpenModifyContractorDialog(false)
          setContractorToModify({})
        }}
        onModified={() => {
          refreshData(true)
          setOpenModifyContractorDialog(false)
        }}
        userToModify={contractorToModify}
        isContractor
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('contractors-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateContractorDialog(true)}
          >
            {t('contractors-list.add-contractor')}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>
    </>
  )
}
