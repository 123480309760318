import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  selector: {
    width: '100%',
  },
  wizardHeader: {
    backgroundColor: theme.palette.background.light,
  },
  noPadding: {
    padding: '0px',
  },
  stepPadding: {
    padding: '0px 24px 20px 24px',
  },
}))
