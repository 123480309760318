import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  linkButton: {
    textTransform: 'capitalize',
  },
  filtersContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  tableContainer: {},
  undoButton: {
    backgroundColor: 'white',
  },
  allItemsButton: {
    textTransform: 'none',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '& #text': {
      paddingLeft: '10px',
    },
    '& #icon': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.selected #icon': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&.selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '& #icon': {
        '& path': {
          fill: 'white',
        },
      },
    },
  },
  menuItemRow: {
    padding: '10px',
    '& #icon': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
}))
