import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button } from '@mui/material'

import { useParams } from 'react-router-dom'

import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import VehicleTypeDetailTabs from './tabs/index'
import VehicleTypeDetailChassisTable from './chassis-table'
import { ReactComponent as HTMLIcon } from '../../assets/Icons_Html.svg'
import { ReactComponent as XLSIcon } from '../../assets/Icons_Xls.svg'

export default function VehicleTypeDetail({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { id } = useParams()

  const [vehicleTypeState, setVehicleTypeState] = useState({
    data: null,
    loading: false,
    error: false,
  })

  const onExportHtml = () => {
    const exportUrl = dataProvider.generateExportUrl(
      `vehicle-type/detail/${id}`,
      {},
      'html'
    )
    window.open(exportUrl, '_blank')
  }

  const onExportCsv = () => {
    const exportUrl = dataProvider.generateExportUrl(
      `vehicle-type/detail/${id}`,
      {},
      'csv'
    )
    window.open(exportUrl, '_blank')
  }

  useEffect(() => {
    setVehicleTypeState((state) => ({
      ...state,
      loading: true,
      error: false,
    }))
    dataProvider
      .getOne('vehicle-type', { id })
      .then((response) => {
        setVehicleTypeState({
          data: response.data,
          loading: false,
          error: false,
        })
      })
      .catch(() => {
        setVehicleTypeState({
          data: null,
          loading: false,
          error: true,
        })
      })
  }, [id])

  useEffect(() => {
    if (vehicleTypeState.error) {
      showNotification('error', t('errors.api-error'))
    }
  }, [vehicleTypeState.error])

  if (vehicleTypeState.loading) {
    return <Loading />
  }

  if (vehicleTypeState.error || !vehicleTypeState.data) {
    return <></>
  }
  const { specificity, constructor, type, title, constructorId } =
    vehicleTypeState.data
  const hasConstructor = constructorId != null

  return (
    <Grid container direction="column">
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <PageTitle title={title} />
          </Grid>
          <Grid container item xs={6} spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                startIcon={<HTMLIcon color="primary" />}
                color="primary"
                variant="contained"
                fullWidth
                onClick={onExportHtml}
              >
                {t('common.export-pdf')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<XLSIcon color="primary" />}
                color="primary"
                variant="contained"
                fullWidth
                onClick={onExportCsv}
              >
                {t('common.export-csv')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.firstSection} direction="column">
        <Grid
          container
          direction="column"
          className={classes.firstSectionSecondSubSection}
        >
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={3}>
              <Grid item>
                <Typography variant="h6">
                  {t('vehicle-types-list.type')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {type}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={3}>
              <Grid item>
                <Typography variant="h6">
                  {t('vehicle-types-list.specificity')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {specificity}
                </Typography>
              </Grid>
            </Grid>

            <Grid container item spacing={2} xs={3}>
              {hasConstructor && (
                <>
                  <Grid item>
                    <Typography variant="h6">
                      {t('vehicle-types-list.constructor-label')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" className={classes.textBlack}>
                      {constructor.name}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VehicleTypeDetailTabs vehicleType={vehicleTypeState.data} />
      <Grid container className={classes.thirdSection} direction="column">
        <Grid item className={classes.thirdSectionFirstSubSection}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {t('vehicle-type-detail.chassis-section.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <VehicleTypeDetailChassisTable vehicleTypeId={id} history={history} />
        </Grid>
      </Grid>
    </Grid>
  )
}
