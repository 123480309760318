import emailResult from './email-result'

function getDeleteWaitingResultConstraints(ids, httpClient) {
  const body = JSON.stringify(ids || {})

  return httpClient({
    url: '/control/delete-waiting-constraints',
    method: 'POST',
    data: body,
  }).then((res) => res.data)
}

function deleteWaitingResult(ids, httpClient) {
  const body = JSON.stringify(ids || {})

  return httpClient({
    url: '/control/waiting',
    method: 'DELETE',
    data: body,
  }).then((res) => res.data)
}

function emailResultByList(controlId, emailLists, httpClient) {
  return httpClient({
    url: `/control/${controlId}/email-result-by-list`,
    data: JSON.stringify({
      emailLists,
    }),
    method: 'POST',
  })
}

export default {
  emailResult,
  getDeleteWaitingResultConstraints,
  deleteWaitingResult,
  emailResultByList,
}
