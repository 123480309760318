import React from 'react'
import { SvgIcon } from '@mui/material'

const OperatorsIcon = () => (
  <SvgIcon>
    <path
      fill="primary"
      fillRule="evenodd"
      d="M13.183 18.9c2.918-.496 5.22-2.799 5.717-5.717l-4.623.77c-.1.116-.208.224-.323.324l-.77 4.623zM14.83 11h4.1C18.444 7.608 15.527 5 12 5c-3.526 0-6.444 2.608-6.93 6h4.1c.413-1.165 1.524-2 2.83-2s2.418.835 2.83 2zm-5.106 2.954l-4.623-.77c.496 2.917 2.799 5.22 5.717 5.716l-.77-4.623c-.116-.1-.224-.208-.324-.323zM12 21c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9zm0-8c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
    />
  </SvgIcon>
)

export default OperatorsIcon
