import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@mui/material'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import BaseDialog from '../../../BaseDialog'
import validators from '../../../../utils/validators'
import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

export default function ModifyUserPasswordDialog({ open, onClose }) {
  const { t } = useTranslation()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [seeOldPassword, setSeeOldPassword] = useState('')
  const [seeNewPassword1, setSeeNewPassword1] = useState('')
  const [seeNewPassword2, setSeeNewPassword2] = useState('')

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const clearData = () => {
    setLoading(false)
    setOldPassword('')
    setNewPassword1('')
    setNewPassword2('')
    setError(undefined)
  }
  const onConfirm = () => {
    if (oldPassword.length === 0) {
      setError('oldPassword_required')
      return
    }

    if (newPassword1.length === 0) {
      setError('newPassword1_required')
      return
    }

    if (newPassword2.length === 0) {
      setError('newPassword2_required')
      return
    }

    if (newPassword1 !== newPassword2) {
      setError('newPassword_notMatch')
      return
    }

    if (!validators.isValidPassword(newPassword1)) {
      setError('newPassword_invalid')
      return
    }

    const data = {
      oldPassword,
      newPassword: newPassword2,
    }
    setLoading(true)
    dataProvider
      .put('users/me/change-password', { data })
      .then(() => {
        onClose()
        setLoading(false)
        clearData()
        showNotification('success', t('modify-user-dialog.success'))
      })
      .catch((err) => {
        setLoading(false)
        if (
          err.response.data.validationErrors &&
          err.response.data.validationErrors.length > 0
        ) {
          showNotification(
            'error',
            err.response.data.validationErrors[0].errorMessage
          )
          setError(`${err.response.data.validationErrors[0].field}_invalid`)
        } else {
          showNotification('error', t('errors.api-error'))
        }
      })
  }
  return (
    <BaseDialog
      open={open}
      onClose={() => {
        clearData()
        onClose()
      }}
      onConfirm={onConfirm}
      title={t('common.change-password')}
      loading={loading}
    >
      <TextField
        variant="standard"
        margin="dense"
        id="oldPassword"
        autoComplete="off"
        label="Mot de passe actuel"
        error={
          error === 'oldPassword_required' || error === 'oldPassword_invalid'
        }
        helperText={
          error === 'oldPassword_required' ? t('common.required') : null
        }
        type={seeOldPassword ? 'text' : 'password'}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setSeeOldPassword(!seeOldPassword)}
                onMouseDown={() => setSeeOldPassword(!seeOldPassword)}
                size="large"
              >
                {seeOldPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        margin="dense"
        id="newPassword1"
        autoComplete="new-password"
        label="Nouveau mot de passe"
        error={error === 'newPassword1_required'}
        helperText={t('common.required')}
        type={seeNewPassword1 ? 'text' : 'password'}
        value={newPassword1}
        onChange={(e) => setNewPassword1(e.target.value)}
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setSeeNewPassword1(!seeNewPassword1)}
                onMouseDown={() => setSeeNewPassword1(!seeNewPassword1)}
                size="large"
              >
                {seeNewPassword1 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="standard"
        margin="dense"
        id="newPassword2"
        autoComplete="new-password"
        value={newPassword2}
        type={seeNewPassword2 ? 'text' : 'password'}
        onChange={(e) => setNewPassword2(e.target.value)}
        error={
          error === 'newPassword2_required' ||
          error === 'newPassword_notMatch' ||
          error === 'newPassword_invalid'
        }
        helperText={
          // eslint-disable-next-line no-nested-ternary
          error === 'newPassword_invalid'
            ? t('common.password-invalid')
            : error === 'newPassword_notMatch'
            ? t('common.password-not-match')
            : t('common.required')
        }
        label="Confirmation nouveau mot de passe"
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setSeeNewPassword2(!seeNewPassword2)}
                onMouseDown={() => setSeeNewPassword2(!seeNewPassword2)}
                size="large"
              >
                {seeNewPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </BaseDialog>
  )
}
