import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { useParams } from 'react-router-dom'

import dateFnsFormat from 'date-fns/format'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import useOverrideStyles from './override-styles'
import Table from '../../components/Table'
import enums from '../../utils/enums'
import generateItemsColumns from './columns-items'
import generateDocumentsColumns from './columns-documents'
import generateOtherControlsColumns from './columns-other-controls'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import PhotoGaleryDialog from '../../components/PhotoGaleryDialog'
import ResendControlResultDialog from '../../components/ResendControlResultDialog'
import ResendControlResultByListDialog from '../../components/ResendControlResultByListDialog'

import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function ControlsDetail({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const overridenClasses = useOverrideStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { id } = useParams()
  const { user } = useUserState()

  const [control, setControl] = useState({})
  const [loading, setLoading] = useState(true)
  const [photos, setPhotos] = useState([])
  const [conformityFilter, setConformityFilter] = useState(
    enums.CONFORMITY.INVALID
  )
  const [openPhotoGalleryDialog, setOpenPhotoGalleryDialog] = useState(false)
  const [openResendControlResultDialog, setOpenResendControlResultDialog] =
    useState(false)
  const [
    openResendControlResultByListDialog,
    setOpenResendControlResultByListDialog,
  ] = useState(false)

  function computeControlledByLabel(conf, user) {
    if (conf === enums.CONFORMITY.AWAITING_CONTROL) {
      return t('common.require-control')
    }
    return user ? user.fullName : t('common.user-does-not-exist')
  }

  const [loadingItems, setLoadingItems] = useState(true)
  const [itemsTableState, setItemsTableState] = useState({
    page: 0,
    sort: null,
    filters: {
      conformity: conformityFilter,
    },
  })
  const [itemsTableData, setItemsTableData] = useState(null)

  const [loadingDocuments, setLoadingDocuments] = useState(true)
  const [documentsTableState, setDocumentsTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [documentsTableData, setDocumentsTableData] = useState(null)

  const [loadingOtherControls, setLoadingOtherControls] = useState(true)
  const [otherControlsTableState, setOtherControlsTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [otherControlsTableData, setOtherControlsTableData] = useState(null)

  const itemsResource = `control/${id}/items`
  const documentsResource = `control/${id}/documents`
  const otherControlsResource = `control/${id}/other-controls`

  useEffect(() => {
    dataProvider
      .getOne('control', { id })
      .then((response) => {
        setControl(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    dataProvider
      .getList(itemsResource, {
        range: { page: itemsTableState.page + 1 },
        filters: itemsTableState.filters,
        sort: itemsTableState.sort,
      })
      .then((response) => {
        setItemsTableData(response.data)
        setLoadingItems(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [itemsTableState, id])

  useEffect(() => {
    dataProvider
      .getList(documentsResource, {
        range: { page: documentsTableState.page + 1 },
        filters: documentsTableState.filters,
        sort: documentsTableState.sort,
      })
      .then((response) => {
        setDocumentsTableData(response.data)
        setLoadingDocuments(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [documentsTableState, id])

  useEffect(() => {
    dataProvider
      .getList(otherControlsResource, {
        range: { page: otherControlsTableState.page + 1 },
        filters: otherControlsTableState.filters,
        sort: otherControlsTableState.sort,
      })
      .then((response) => {
        setOtherControlsTableData(response.data)
        setLoadingOtherControls(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [otherControlsTableState, id])

  const showPvAdmission = () => {
    const url = dataProvider.generatePVAdmissionExportUrl(control.id)
    if (url) {
      window.open(url, '_blank')
    }
  }

  const resendResult = () => {
    setOpenResendControlResultDialog(true)
  }

  const resendResultByList = () => {
    setOpenResendControlResultByListDialog(true)
  }

  const renderConformity = (conformity, validationOverrideDetails) => {
    if (typeof validationOverrideDetails === 'object') {
      return t('controls-detail.overriden-validation-conformity', {
        userName: validationOverrideDetails.user.fullName,
        date: dateFnsFormat(
          Date.parse(validationOverrideDetails.overridenAt),
          'dd/MM/yyyy'
        ),
      })
    }
    switch (conformity) {
      case enums.CONFORMITY.INVALID:
        return t('conformity.invalid')
      case enums.CONFORMITY.VALID:
        return t('conformity.valid')
      case enums.CONFORMITY.AWAITING_CONTROL:
        return t('conformity.waiting-result')
      default:
        return t('errors.not-valid')
    }
  }

  const onShowPhotos = (photosToShow) => {
    setPhotos(photosToShow)
    setOpenPhotoGalleryDialog(true)
  }

  const itemsTableColumns = generateItemsColumns({
    t,
    classes,
    onShowPhotos,
    renderConformity,
  })

  const documentsTableColumns = generateDocumentsColumns({
    t,
    onDocumentsClicked: (idx) => {
      const item = documentsTableData.items[idx]
      if (item?.document?.url) {
        window.open(item.document.url, '_blank')
      }
    },
    renderConformity,
  })

  const otherControlsTableColumns = generateOtherControlsColumns({
    t,
    classes,
    onControlClick: (controlId) => {
      history.push(`/controls/detail/${controlId}`)
    },
    renderConformity,
    computeControlledByLabel,
  })

  if (loading || loadingItems || loadingDocuments || loadingOtherControls) {
    return <Loading />
  }
  const {
    submittedByUser,
    controlDate,
    conformity,
    controlNumber,
    validationOverrideDetails,
    validationOverrideDetailsId,
    chassis: { operateur, vehicleType, nParc, nChassis, location, depot },
  } = control
  const confirmityWasOverriden =
    typeof validationOverrideDetailsId !== 'undefined'
  return (
    <Grid container direction="column">
      <PhotoGaleryDialog
        open={openPhotoGalleryDialog}
        onClose={() => setOpenPhotoGalleryDialog(false)}
        images={photos}
      />
      <ResendControlResultDialog
        open={openResendControlResultDialog}
        onClose={() => setOpenResendControlResultDialog(false)}
        controlId={control.id}
        nChassis={nChassis}
      />
      <ResendControlResultByListDialog
        open={openResendControlResultByListDialog}
        onClose={() => setOpenResendControlResultByListDialog(false)}
        controlId={control.id}
        nChassis={nChassis}
      />
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid item sm={8}>
          <Grid
            container
            alignItems="flex-start"
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <PageTitle
                classes={overridenClasses}
                title={t('controls-detail.title', {
                  controlNumber,
                  ordinalName: controlNumber === 1 ? 'er' : 'ème',
                })}
              />
            </Grid>
            <Grid item>
              <Typography variant="h5">{nChassis}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {hasPermission(Permissions.PAGE_CONTROLS_RESEND_CONTROL_RESULT, user) &&
          (conformity === enums.CONFORMITY.VALID ||
            conformity === enums.CONFORMITY.INVALID) && (
            <Grid item container sm={4} justifyContent="flex-end">
              <Grid item>
                <Button
                  fullWidth={fullWidth}
                  color="primary"
                  variant="contained"
                  onClick={resendResult}
                >
                  {t('controls-detail.resend-result')}
                </Button>
              </Grid>
              <Grid item marginLeft={2} marginTop={1}>
                <Button
                  fullWidth={fullWidth}
                  color="primary"
                  variant="contained"
                  onClick={resendResultByList}
                >
                  {t('controls-detail.resend-result-by-list')}
                </Button>
              </Grid>
            </Grid>
          )}
      </Grid>
      <Grid container className={classes.firstSection} direction="column">
        <Grid
          container
          className={classes.firstSectionFirstSubSection}
          direction="column"
          spacing={2}
        >
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h6">{t('common.date')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {dateFnsFormat(Date.parse(controlDate), 'dd/MM/yyyy H:mm')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h6">{t('common.controlBy')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {computeControlledByLabel(conformity, submittedByUser)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h6">{t('common.conformity')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {renderConformity(conformity, validationOverrideDetails)}
              </Typography>
            </Grid>
            {hasPermission(
              Permissions.PAGE_CONTROLS_ACCESS_PV_ADMISSION,
              user
            ) &&
              (conformity === enums.CONFORMITY.VALID ||
                confirmityWasOverriden) && (
                <Grid item>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={showPvAdmission}
                  >
                    {t('common.pvAdmission')}
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.firstSectionSecondSubSection}
        >
          <Grid item className={classes.marginBottom}>
            <Typography variant="h6" className={classes.textBlack}>
              {t('common.vehicle')}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.vehicleType')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.type}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.nParc')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {nParc}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.depot')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {depot}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.specificity')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.specificity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.location')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {location}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.operateur')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {operateur}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.secondSection} direction="column">
        <Grid item className={classes.secondSectionFirstSubSection}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {t('common.items')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard" className={classes.selector}>
                <InputLabel id="conformity-label">
                  {t('common.conformity')}
                </InputLabel>
                <NativeSelect
                  labelid="conformity-label"
                  inputProps={{
                    name: t('common.conformity'),
                    id: 'conformity',
                  }}
                  onChange={(event) => {
                    const { value } = event.target
                    setConformityFilter(value)
                    if (enums.CONFORMITY.isValid(value)) {
                      setItemsTableState({
                        ...itemsTableState,
                        page: 0,
                        filters: { conformity: value },
                      })
                    } else {
                      setItemsTableState({
                        ...itemsTableState,
                        page: 0,
                        filters: null,
                      })
                    }
                  }}
                  value={conformityFilter}
                >
                  <option aria-label="None" value="" />
                  <option value={enums.CONFORMITY.VALID}>
                    {t('conformity.valid')}
                  </option>
                  <option value={enums.CONFORMITY.INVALID}>
                    {t('conformity.invalid')}
                  </option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.tableContainer}>
          <Table
            id={itemsResource}
            columns={itemsTableColumns}
            data={itemsTableData}
            page={itemsTableState.page}
            sort={itemsTableState.sort}
            onChangePage={(currentPage) => {
              setItemsTableState({ ...itemsTableState, page: currentPage })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setItemsTableState({ ...itemsTableState, sort: newSort })
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.secondSection} direction="column">
        <Grid item className={classes.tableContainer}>
          <Table
            id={documentsResource}
            columns={documentsTableColumns}
            data={documentsTableData}
            page={documentsTableState.page}
            sort={documentsTableState.sort}
            onChangePage={(currentPage) => {
              setDocumentsTableState({
                ...documentsTableState,
                page: currentPage,
              })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setDocumentsTableState({ ...documentsTableState, sort: newSort })
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.secondSection} direction="column">
        <Grid item className={classes.secondSectionFirstSubSection}>
          <Typography variant="h6" className={classes.textBlack}>
            {t('common.otherControls')}
          </Typography>
        </Grid>
        <Grid item className={classes.tableContainer}>
          <Table
            id={otherControlsResource}
            columns={otherControlsTableColumns}
            data={otherControlsTableData}
            page={otherControlsTableState.page}
            sort={otherControlsTableState.sort}
            onChangePage={(currentPage) => {
              setOtherControlsTableState({
                ...otherControlsTableState,
                page: currentPage,
              })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setOtherControlsTableState({
                ...otherControlsTableState,
                sort: newSort,
              })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
