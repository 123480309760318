import React from 'react'

const EmailsIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      fill={color}
    >
      <g id="surface1">
        <path d="M 7.148438 1.542969 C 6.71875 1.65625 6.335938 1.964844 6.140625 2.367188 C 5.980469 2.691406 5.976562 3.296875 6.132812 3.609375 C 6.289062 3.941406 6.484375 4.144531 6.804688 4.316406 C 7.078125 4.460938 7.132812 4.476562 7.5 4.472656 C 7.835938 4.472656 7.9375 4.453125 8.136719 4.351562 C 8.488281 4.164062 8.640625 4.023438 8.816406 3.695312 C 8.960938 3.421875 8.976562 3.367188 8.976562 3 C 8.976562 2.632812 8.960938 2.578125 8.816406 2.304688 C 8.550781 1.820312 8.140625 1.554688 7.597656 1.523438 C 7.429688 1.515625 7.226562 1.519531 7.148438 1.542969 Z M 7.148438 1.542969 " />
        <path d="M 10.195312 2.316406 C 9.882812 2.453125 9.703125 2.8125 9.769531 3.164062 C 9.8125 3.382812 10.046875 3.640625 10.257812 3.703125 C 10.375 3.742188 11.574219 3.75 13.976562 3.742188 C 17.476562 3.726562 17.527344 3.726562 17.652344 3.628906 C 17.902344 3.445312 17.976562 3.296875 17.976562 3 C 17.976562 2.703125 17.902344 2.554688 17.652344 2.371094 C 17.527344 2.273438 17.480469 2.273438 13.929688 2.265625 C 11.015625 2.253906 10.308594 2.265625 10.195312 2.316406 Z M 10.195312 2.316406 " />
        <path d="M 7.148438 5.292969 C 6.71875 5.40625 6.335938 5.714844 6.140625 6.117188 C 5.980469 6.441406 5.976562 7.046875 6.132812 7.359375 C 6.289062 7.691406 6.484375 7.894531 6.804688 8.066406 C 7.078125 8.210938 7.132812 8.226562 7.5 8.222656 C 7.835938 8.222656 7.9375 8.203125 8.136719 8.101562 C 8.488281 7.914062 8.640625 7.773438 8.816406 7.445312 C 8.960938 7.171875 8.976562 7.117188 8.976562 6.75 C 8.976562 6.382812 8.960938 6.328125 8.816406 6.054688 C 8.550781 5.570312 8.140625 5.304688 7.597656 5.273438 C 7.429688 5.265625 7.226562 5.269531 7.148438 5.292969 Z M 7.148438 5.292969 " />
        <path d="M 10.195312 6.066406 C 9.882812 6.203125 9.703125 6.5625 9.769531 6.914062 C 9.8125 7.132812 10.046875 7.390625 10.257812 7.453125 C 10.375 7.492188 11.574219 7.5 13.976562 7.492188 C 17.476562 7.476562 17.527344 7.476562 17.652344 7.378906 C 17.902344 7.195312 17.976562 7.046875 17.976562 6.75 C 17.976562 6.453125 17.902344 6.304688 17.652344 6.121094 C 17.527344 6.023438 17.480469 6.023438 13.929688 6.015625 C 11.015625 6.003906 10.308594 6.015625 10.195312 6.066406 Z M 10.195312 6.066406 " />
        <path d="M 3.136719 9.085938 C 2.339844 9.296875 1.695312 9.980469 1.546875 10.777344 C 1.480469 11.117188 1.480469 20.382812 1.546875 20.722656 C 1.65625 21.300781 2.066406 21.882812 2.597656 22.191406 C 3.15625 22.523438 2.582031 22.503906 12.164062 22.492188 L 20.789062 22.476562 L 21.0625 22.367188 C 21.652344 22.128906 22.195312 21.5625 22.390625 20.984375 C 22.472656 20.761719 22.476562 20.398438 22.492188 15.890625 C 22.503906 10.492188 22.515625 10.636719 22.191406 10.097656 C 21.882812 9.566406 21.300781 9.15625 20.722656 9.046875 C 20.558594 9.019531 17.476562 9 11.957031 9.003906 C 4.070312 9.003906 3.421875 9.007812 3.136719 9.085938 Z M 20.617188 10.589844 C 20.71875 10.640625 20.832031 10.753906 20.898438 10.867188 L 21 11.054688 L 21 20.445312 L 20.902344 20.625 C 20.84375 20.722656 20.722656 20.84375 20.625 20.902344 L 20.445312 21 L 3.554688 21 L 3.375 20.902344 C 3.277344 20.84375 3.15625 20.722656 3.097656 20.625 L 3 20.445312 L 3 15.75 C 3 11.058594 3 11.054688 3.097656 10.875 C 3.15625 10.78125 3.277344 10.65625 3.371094 10.601562 L 3.539062 10.5 L 11.992188 10.5 C 20.199219 10.5 20.445312 10.503906 20.617188 10.589844 Z M 20.617188 10.589844 " />
        <path d="M 4.195312 11.316406 C 3.734375 11.523438 3.609375 12.136719 3.953125 12.492188 C 4.035156 12.578125 5.820312 13.679688 7.921875 14.933594 C 11.726562 17.21875 11.742188 17.226562 12 17.226562 C 12.257812 17.226562 12.273438 17.21875 16.078125 14.933594 C 18.179688 13.679688 19.964844 12.578125 20.046875 12.492188 C 20.515625 12 20.085938 11.171875 19.40625 11.265625 C 19.28125 11.277344 18.167969 11.921875 15.613281 13.453125 L 12 15.625 L 8.382812 13.453125 C 4.484375 11.113281 4.550781 11.152344 4.195312 11.316406 Z M 4.195312 11.316406 " />
      </g>
    </svg>
  )
}

export default EmailsIcon
