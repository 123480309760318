function get(chassisId, httpClient) {
  return httpClient({
    url: `/chassis/${chassisId}/documents`,
    method: 'GET',
  })
}

function upload(response, file, worldHTTPClient) {
  const headersUpload = response.data.uploadHeaders || {}
  headersUpload['Content-Type'] = file.type
  headersUpload[
    'Content-Disposition'
  ] = `inline; filename*=UTF-8''${encodeURIComponent(file.name)}`
  headersUpload['Cache-Control'] = 'maxage=3600'

  return worldHTTPClient({
    url: response.data.uploadUrl,
    method: response.data.uploadHttpMethod,
    headers: headersUpload,
    data: file,
  })
}

function requestUpload(chassisId, httpClient) {
  return httpClient({
    url: `/chassis/${chassisId}/documents/upload`,
    method: 'POST',
  })
}

function post(chassisId, type, url, originalName, httpClient) {
  const body = JSON.stringify({ url, originalName })

  return httpClient({
    url: `/chassis/${chassisId}/documents/${type}`,
    method: 'POST',
    data: body,
  })
}

function deleteDocuments(chassisId, types, httpClient) {
  const body = JSON.stringify(types)

  return httpClient({
    url: `/chassis/${chassisId}/documents`,
    method: 'DELETE',
    data: body,
  })
}

function downloadDocuments(chassisId, ids, httpClient) {
  if (!ids || !ids.length) {
    return Promise.resolve()
  }
  const query = ids
    .filter((id) => id)
    .map((id, index) => `ids[${index}]=${id}`)
    .join('&')

  return httpClient({
    url: `/chassis/${chassisId}/documents/download?${query}`,
    method: 'GET',
  }).then((res) => res.data.url)
}

export default {
  get,
  requestUpload,
  upload,
  post,
  deleteDocuments,
  downloadDocuments,
}
