import React from 'react'

// components
import { Button, Grid, Typography } from '@mui/material'

export default function generate({
  t,
  classes,
  onModifyEmailList,
  onDeleteEmailList,
}) {
  return [
    {
      name: 'name',
      label: t('email-lists-list.table-name-header'),
    },
    {
      name: 'description',
      label: t('email-lists-list.table-description-header'),
    },
    {
      name: 'emails',
      label: t('email-lists-list.table-emails-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          console.log('val', val)
          console.log('tableMeta', tableMeta)
          const emails = val || []
          // Iterate over the first 5 operators and return them as a list of Grid items. If there are more than 5 operators, return a single Grid item with a message.
          let items = emails.slice(0, 5).map((item) => (
            <Grid item key={item.id}>
              <span>{item.email}</span>
            </Grid>
          ))
          if (emails.length > 5) {
            items.push(
              <Grid item key="more">
                <Typography variant="body" style={{ fontWeight: 600 }}>
                  {t('email-lists-list.table-more-emails', {
                    nEmails: emails.length - 5,
                  })}
                </Typography>
              </Grid>
            )
          }
          return items
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModifyEmailList(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDeleteEmailList(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
