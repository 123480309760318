import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  filtersContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  filterContainerTitleText: {
    color: theme.palette.captGrey.main,
  },
  textField: {
    width: '100%',
  },
  selector: {
    width: '100%',
  },
  tableContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  table: {
    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)',
  },
  greenText: {
    color: 'green',
  },
  orangeText: {
    color: 'orange',
  },
  linkButton: {
    textTransform: 'capitalize',
    justifyContent: 'left',
  },
  actionsContainer: {
    marginTop: '10px',
  },
}))
