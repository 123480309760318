import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

// components
import Layout from './layout'

// pages
import Error from './pages/error'
import Login from './pages/login'
import PasswordRecovery from './pages/password-recovery'

// context
import { useUserState } from './context/user/UserContext'
import { resetHTTPClient } from './context/DataApiContext'

export default function App() {
  // global
  const { isAuthenticated } = useUserState()

  useEffect(() => {
    if (!isAuthenticated) {
      resetHTTPClient()
    }
  }, [isAuthenticated])

  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/recover-password" component={PasswordRecovery} />
        <PrivateRoute path="/" component={Layout} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  )

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    )
  }
}
