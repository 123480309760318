import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import { Grid, Button, useMediaQuery, useTheme } from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'
// import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import useStyles from './styles'

// eslint-disable-next-line max-len
import CreateMemberDialog from '../../components/Members/components/CreateMemberDialog'
// eslint-disable-next-line max-len
import ModifyMemberDialog from '../../components/Members/components/ModifyMemberDialog'
import Table from '../../components/Table'
import generateColumns from './columns'

export default function MembersList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)
  const [openCreateMemberDialog, setOpenCreateMemberDialog] = useState(false)
  const [openModifyMemberDialog, setOpenModifyMemberDialog] = useState(false)
  const [memberToModify, setMemberToModify] = useState(false)

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'members'

  useEffect(() => {
    dataProvider.member
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        false
      )
      .then((response) => {
        setItems(response.data.items)
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModify = (rowIndex) => {
    setMemberToModify(tableData.items[rowIndex])
    setOpenModifyMemberDialog(true)
  }

  const onDelete = (rowIndex) => {
    const { id, name } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('members-list.confirm-member-deletion', {
        name,
      }),
      () => {
        dataProvider
          .delete(`members`, { id })
          .then(() => {
            refreshData()
            showNotification('success', t('members-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
      t('common.delete')
    )
  }

  const tableColumns = generateColumns({
    t,
    classes,
    items,
    onModify,
    onDelete,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateMemberDialog
        open={openCreateMemberDialog}
        onClose={() => setOpenCreateMemberDialog(false)}
        onCreated={() => {
          setOpenCreateMemberDialog(false)
          refreshData()
        }}
      />
      <ModifyMemberDialog
        open={openModifyMemberDialog}
        onClose={() => {
          setOpenModifyMemberDialog(false)
          setMemberToModify({})
        }}
        onModified={() => {
          refreshData(true)
          setOpenModifyMemberDialog(false)
        }}
        memberToModify={memberToModify}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('members-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateMemberDialog(true)}
          >
            {t('members-list.add-member')}
          </Button>
        </Grid>
      </Grid>
      <Table
        id={resource}
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
      />
    </>
  )
}
