import { Checkbox } from '@mui/material'

export default function generate({
  t,
  onMandatoryToggle,
  disableMandatoryToggle,
}) {
  return [
    {
      name: 'documentType.name',
      label: t('common.documents'),
      options: {
        sort: false,
      },
    },
    {
      name: 'requiredForConformance',
      label: t('vehicle-type-detail.mandatory'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          return (
            <Checkbox
              onClick={() => onMandatoryToggle(tableMeta.rowIndex)}
              checked={val}
              disabled={disableMandatoryToggle}
            />
          )
        },
      },
    },
  ]
}
