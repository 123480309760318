import React, { useState, useCallback } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Grid, IconButton, TextField } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import isValid from 'date-fns/isValid'

export default function OurDatePicker({ id, value, onChange, label }) {
  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    (value) => {
      if (isValid(value) || !value) {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container justifyContent="center" alignItems="flex-end">
        <DatePicker
          id={id}
          label={label}
          value={value}
          open={open}
          inputFormat="dd/MM/yyyy"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleChange}
          onAccept={onChange}
          disableMaskedInput
          disableOpenPicker={!value}
          renderInput={(params) => (
            <TextField
              fullWidth
              variant="standard"
              {...params}
              onClick={(evt) => {
                evt.stopPropagation()
                setOpen(true)
              }}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <>
                    {value && (
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={(evt) => {
                          evt.stopPropagation()
                          onChange(null)
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </LocalizationProvider>
  )
}
