import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import validators from '../../../../utils/validators'
import AutoCompleteInput from '../../../AutoCompleteInput'
import DatePicker from '../../../DatePicker'

export default function CreateChassisDialog({ open, onClose, onCreated }) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [nChassis, setNChassis] = useState('')
  const [depot, setDepot] = useState(null)
  const [operateur, setOperateur] = useState(null)
  const [vehicleType, setVehicleType] = useState(null)
  const [location, setLocation] = useState(null)
  const [nParc, setNParc] = useState('')
  const [controlDate, setControlDate] = useState(new Date())

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setNChassis('')
    setDepot(null)
    setOperateur(null)
    setVehicleType(null)
    setLocation(null)
    setNParc('')
    setControlDate(new Date())
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    setError(null)
    if (nParc.length === 0) {
      setError('nParc_required')
      return
    }

    if (!validators.isValidNParc(nParc)) {
      setError('nParc_invalid')
      return
    }
    if (nChassis.length === 0) {
      setError('nChassis_required')
      return
    }

    if (!validators.isValidNChassis(nChassis)) {
      setError('nChassis_invalid')
      return
    }

    if (location === null) {
      setError('location_required')
      return
    }

    if (operateur === null) {
      setError('operateur_required')
      return
    }

    if (depot === null) {
      setError('depot_required')
      return
    }

    if (vehicleType === null) {
      setError('vehicleType_required')
      return
    }

    const data = {
      vehicleTypeId: vehicleType.id,
      nChassis,
      nParc,
      location: location.id,
      operatorId: operateur.id,
      depotId: depot.id,
    }

    if (controlDate) {
      data.controlDate = controlDate.toISOString()
    }
    setLoading(true)
    dataProvider
      .create('chassis', { data })
      .then(() => {
        dataProvider.cache.reset()
        setLoading(false)
        showNotification('success', t('create-chassis-dialog.success'))
        onCreated()
        resetState()
      })
      .catch((err) => {
        setLoading(false)
        if (
          err.response.data.validationErrors &&
          err.response.data.validationErrors.length > 0
        ) {
          showNotification(
            'error',
            err.response.data.validationErrors[0].errorMessage
          )
          setError(`${err.response.data.validationErrors[0].field}_invalid`)
        } else {
          showNotification('error', t('errors.api-error'))
        }
      })
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-chassis-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'nParc_required' || error === 'nParc_invalid'}
            helperText={
              error === 'nParc_invalid'
                ? t('errors.not-valid')
                : t('common.required')
            }
            id="nParc"
            label={t('common.nParc')}
            fullWidth
            value={nParc}
            onChange={(event) => setNParc(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={
              error === 'nChassis_required' || error === 'nChassis_invalid'
            }
            helperText={
              error === 'nChassis_invalid'
                ? t('errors.not-valid')
                : t('common.required')
            }
            id="chassis"
            label={t('common.chassis')}
            fullWidth
            value={nChassis}
            onChange={(event) => setNChassis(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'vehicleType_required'}
            onChange={setVehicleType}
            label={t('common.vehicleType')}
            resource="vehicle-type/autocomplete"
            value={vehicleType}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'operateur_required'}
            onChange={(val) => {
              setOperateur(val)
              setDepot(null)
            }}
            label={t('common.operateur')}
            resource="operators/autocomplete"
            value={operateur}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'depot_required'}
            onChange={setDepot}
            label={t('common.depot')}
            resource="depots/autocomplete"
            query={{ operatorId: operateur ? operateur.id : undefined }}
            value={depot}
            disabled={!operateur}
            freeSolo
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'location_required'}
            onChange={setLocation}
            onInputChange={(data) => {
              setLocation({
                id: data,
                title: data,
              })
            }}
            label={t('common.location')}
            resource="chassis/location-autocomplete"
            value={location}
            freeSolo
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <DatePicker
            id="startDate"
            label={t('common.controlDate')}
            value={controlDate}
            onChange={setControlDate}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
