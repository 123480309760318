import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  actionsContainerCenter: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
  },
  actionsContainerEnd: {
    marginTop: theme.spacing(2),
  },
  titleContainer: {
    borderBottom: '2px solid #e3e3ec',
    paddingLeft: theme.spacing(1),
  },
}))
