import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    gap: '10px',
    minWidth: '475px',
  },
  text: {
    flexGrow: 1,
  },
  iconFillColor: {
    '& path': {
      fill: (props) => props.color,
    },
  },
  preventShrink: {
    flexShrink: 0,
  },
}))
