import React, { useState, useEffect, useCallback, useMemo } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  FormControl,
  TextField,
} from '@mui/material'
import NativeSelect from '@mui/material/NativeSelect'
import { useLocation } from 'react-router-dom'
import { useQueryState } from '../../utils/query-state'
import Loading from '../../components/Loading'
import { ReactComponent as HTMLIcon } from '../../assets/Icons_Html.svg'
import { ReactComponent as XLSIcon } from '../../assets/Icons_Xls.svg'
import Table from '../../components/Table'
import generateItemsColumns from './columns-items'
import generateDocumentsColumns from './columns-documents'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import enums from '../../utils/enums'
import PhotoGaleryDialog from '../../components/PhotoGaleryDialog'
import DatePicker from '../../components/DatePicker'

import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function ControlsDetailList({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const query = useQuery()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [startDate, setStartDate] = useQueryState({
    paramName: 'startDate',
    history,
    query,
    fromQuery: useCallback((value) => (value ? new Date(value) : null), []),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [endDate, setEndDate] = useQueryState({
    paramName: 'endDate',
    history,
    query,
    fromQuery: useCallback(
      (value) => (value ? new Date(value) : new Date()),
      []
    ),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [vehicleType, setVehicleType] = useQueryState({
    paramName: 'vehicleType',
    history,
    query,
  })
  const [chassis, setChassis] = useQueryState({
    paramName: 'chassis',
    history,
    query,
  })
  const [conformity, setConformity] = useQueryState({
    paramName: 'conformity',
    history,
    query,
    defaultValue: '',
  })
  const [location, setLocation] = useQueryState({
    history,
    query,
    paramName: 'location',
  })
  const [nParc, setNParc] = useQueryState({
    history,
    query,
    paramName: 'nParc',
  })
  const [nItem, setNItem] = useQueryState({
    history,
    query,
    paramName: 'nItem',
    defaultValue: '',
  })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      chassisId: chassis ? chassis.id : null,
      location: location ? location.id : null,
      conformity: enums.CONFORMITY.isValid(conformity) ? conformity : null,
      nParc: nParc ? nParc.title : null,
    }
    if (nItem && nItem.trim()) {
      filtersObj.nItem = nItem
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [
    startDate,
    endDate,
    vehicleType,
    chassis,
    location,
    conformity,
    nParc,
    nItem,
  ])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({
    paramName: 'sort',
    history,
    query,
  })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page',
    defaultValue: 0,
    history,
    query,
  })
  const [tableState, setTableState] = useState({
    page: tablePage,
    sort: tableSort,
    filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  const [loadingDocuments, setLoadingDocuments] = useState(true)
  const [tableDocumentsSort, setTableDocumentsSort] = useQueryState({
    paramName: 'sortDocs',
    history,
    query,
  })
  const [tableDocumentsPage, setTableDocumentsPage] = useQueryState({
    paramName: 'pageDocs',
    defaultValue: 0,
    history,
    query,
  })
  const [tableDocumentsState, setTableDocumentsState] = useState({
    page: tableDocumentsPage,
    sort: tableDocumentsSort,
    filters: computedFilters,
  })
  const [tableDocumentsData, setTableDocumentsData] = useState(null)

  const [openPhotoGalleryDialog, setOpenPhotoGalleryDialog] = useState(false)
  const [photos, setPhotos] = useState([])

  const itemsResource = 'control-item-conformity'
  const documentsResource = 'control-document-conformity'

  useEffect(() => {
    dataProvider
      .getList(itemsResource, {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      })
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  useEffect(() => {
    dataProvider
      .getList(documentsResource, {
        range: { page: tableDocumentsState.page + 1 },
        filters: tableDocumentsState.filters,
        sort: tableDocumentsState.sort,
      })
      .then((response) => {
        setTableDocumentsData(response.data)
        setLoadingDocuments(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setTableDocumentsPage(tableDocumentsState.page)
    setTableDocumentsSort(tableDocumentsState.sort)
    // eslint-disable-next-line
  }, [tableDocumentsState])

  const updateFilters = () => {
    setTableState({ ...tableState, page: 0, filters: computedFilters })
    setTableDocumentsState({
      ...tableDocumentsState,
      page: 0,
      filters: computedFilters,
    })
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setChassis(null)
    setVehicleType(null)
    setLocation(null)
    setConformity('')
    setNParc(null)
    setNItem('')
    setTableState({ ...tableState, page: 0, filters: null })
    setTableDocumentsState({ ...tableDocumentsState, page: 0, filters: null })
  }

  const onShowPhotos = (photosToShow) => {
    setPhotos(photosToShow)
    setOpenPhotoGalleryDialog(true)
  }

  const onExportHtml = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'control/detail',
      tableState,
      'html'
    )
    window.open(exportUrl, '_blank')
  }

  const onExportCsv = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'control/detail',
      tableState,
      'csv'
    )
    window.open(exportUrl, '_blank')
  }

  const tableColumns = generateItemsColumns({
    t,
    classes,
    onShowPhotos,
  })

  const documentsTableColumns = generateDocumentsColumns({
    t,
    onDocumentsClicked: (idx) => {
      const item = tableDocumentsData.items[idx]
      if (item?.document?.url) {
        window.open(item.document.url, '_blank')
      }
    },
  })

  if (loading || loadingDocuments) {
    return <Loading />
  }
  return (
    <Grid container direction="column">
      <PhotoGaleryDialog
        open={openPhotoGalleryDialog}
        onClose={() => setOpenPhotoGalleryDialog(false)}
        images={photos}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <PageTitle title={t('controls-detail-list.title')} />
        </Grid>
        <Grid container item xs={6} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              startIcon={<HTMLIcon color="primary" />}
              color="primary"
              variant="contained"
              fullWidth
              onClick={onExportHtml}
            >
              {t('common.export-pdf')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<XLSIcon color="primary" />}
              color="primary"
              variant="contained"
              fullWidth
              onClick={onExportCsv}
            >
              {t('common.export-csv')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.filtersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid container item xs={12} md={6} alignItems="flex-end">
            <Grid item xs={12} md={5}>
              <DatePicker
                id="startDate"
                value={startDate}
                onChange={setStartDate}
                label={t('common.startDate')}
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" align="center">
                Au
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePicker
                id="endDate"
                value={endDate}
                onChange={setEndDate}
                label={t('common.endDate')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setVehicleType}
              label={t('common.vehicleType')}
              resource="vehicle-type/autocomplete"
              value={vehicleType}
              query={{ includeDisabled: false }}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setChassis}
              label={t('common.chassis')}
              resource="chassis/chassis-number-autocomplete"
              value={chassis}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={4}
        >
          <Grid item xs={12} md={3}>
            <FormControl variant="standard" className={classes.selector}>
              <InputLabel
                id="conformity-label"
                shrink={enums.CONFORMITY.isValid(conformity)}
              >
                {t('common.conformity')}
              </InputLabel>
              <NativeSelect
                labelid="conformity-label"
                inputProps={{
                  name: t('common.conformity'),
                  id: 'conformity',
                }}
                onChange={(event) => setConformity(event.target.value)}
                value={conformity}
              >
                <option aria-label="None" value="" />
                <option value={enums.CONFORMITY.VALID}>
                  {t('conformity.valid')}
                </option>
                <option value={enums.CONFORMITY.INVALID}>
                  {t('conformity.invalid')}
                </option>
                <option value={enums.CONFORMITY.AWAITING_CONTROL}>
                  {t('conformity.waiting-result')}
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setNParc}
              label={t('common.nParc')}
              resource="chassis/parc-number-autocomplete"
              value={nParc}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setLocation}
              label={t('common.location')}
              resource="chassis/location-autocomplete"
              value={location}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              onChange={(event) => setNItem(event.target.value)}
              label={t('common.nItem')}
              value={nItem}
              fullWidth
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          justifyContent="flex-end"
          className={classes.actionsContainer}
        >
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="secondary"
              variant="contained"
              onClick={clearFilters}
              fullWidth
            >
              {t('common.clean')}
            </Button>
          </Grid>
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={updateFilters}
            >
              {t('common.search')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Table
          id={itemsResource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Table
          id={documentsResource}
          columns={documentsTableColumns}
          data={tableDocumentsData}
          page={tableDocumentsState.page}
          sort={tableDocumentsState.sort}
          onChangePage={(currentPage) => {
            setTableDocumentsState({
              ...tableDocumentsState,
              page: currentPage,
            })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableDocumentsState({ ...tableDocumentsState, sort: newSort })
          }}
        />
      </Grid>
    </Grid>
  )
}
