import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

export default function DeleteWaitingResultControlsDialog({
  open,
  onClose,
  onSuccess,
  chassisIds,
  controlIds,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [constraints, setConstraints] = useState({})
  const translations = constraints.translations || {}

  const resetState = () => {
    setConstraints({})
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    setLoading(true)
    dataProvider.control
      .deleteWaitingResult({ chassisIds, controlIds })
      .then(() => {
        dataProvider.cache.reset()
        setLoading(false)
        chassisIds.length > 0
          ? showNotification(
              'success',
              t('delete-waiting-result-controls-dialog.chassis-success')
            )
          : showNotification(
              'success',
              t('delete-waiting-result-controls-dialog.success')
            )
        onSuccess()
        resetState()
      })
      .catch(() => {
        setLoading(false)
        showNotification('error', t('errors.api-error'))
      })
  }

  useEffect(() => {
    const hasChassisIds = Array.isArray(chassisIds) && chassisIds.length
    const hasControlIds = Array.isArray(controlIds) && controlIds.length
    if (open && (hasChassisIds || hasControlIds)) {
      setLoading(true)
      dataProvider.control
        .getDeleteWaitingResultConstraints({ chassisIds, controlIds })
        .then((data) => setConstraints(data))
        .finally(() => setLoading(false))
    }
  }, [chassisIds, controlIds, open, dataProvider.control])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={
        Array.isArray(controlIds) && controlIds.length
          ? t('delete-waiting-result-controls-dialog.title')
          : t('delete-waiting-result-controls-dialog.chassis-title')
      }
      loading={loading}
      hideActions={
        constraints.constraints &&
        constraints.constraints.waitingResultControlsCount === 0
      }
    >
      <Grid container direction="column" spacing={4}>
        {translations.constraint && (
          <Grid item>
            <Typography>{translations.constraint}</Typography>
          </Grid>
        )}
        {translations.question && (
          <Grid item>
            <Typography>{translations.question}</Typography>
          </Grid>
        )}
        {!translations.question && !translations.constraint && <Grid item />}
      </Grid>
    </BaseDialog>
  )
}
