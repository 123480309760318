function startVehicleTypeItems(httpClient) {
  return httpClient({
    url: '/upload/bulk-vehicle-type-items',
    method: 'POST',
  })
}

function performUpload(uploadDetails, file, httpClient) {
  if (uploadDetails.method === 'multipart') {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    Object.entries(uploadDetails.fields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file)
    return httpClient({
      url: uploadDetails.url,
      method: uploadDetails.httpVerb,
      data: formData,
      headers,
    })
  }

  throw new Error('Upload method not yet support')
}

export default {
  startVehicleTypeItems,
  performUpload,
}
