import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// components
import Widget from '../../components/Widget'
import PageTitle from '../../components/PageTitle'
import { useDataApi } from '../../context/DataApiContext'
import Loading from '../../components/Loading'
import CreateVehicleTypeDialog from '../../components/VehicleType/components/CreateVehicleTypeDialog'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import CreateChassisDialog from '../../components/Chassis/components/CreateChassisDialog'
import CreateVehicleTypeBulkDialog from '../../components/VehicleType/components/CreateVehicleTypeBulkDialog'
import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function Dashboard() {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { user } = useUserState()

  const [failedLoad, setFailedLoad] = useState(false)
  const [counts, setCounts] = useState(null)
  const [openCreateVehicleTypeDialog, setOpenCreateVehicleTypeDialog] =
    useState(false)
  const [openCreateChassisDialog, setOpenCreateChassisDialog] = useState(false)
  const [openCreateVehicleTypeBulkDialog, setOpenCreateVehicleTypeBulkDialog] =
    useState(false)

  const [addActionsAnchorEl, setAddActionsAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  useEffect(() => {
    dataProvider
      .get('/dashboard', {})
      .then((response) => setCounts(response.data))
      .catch(() => setFailedLoad(true))
  }, [dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  const onAddActionsMenuClose = () => {
    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: null,
      items: [],
    })
  }

  const onAddActions = (currentTarget) => {
    const menuItems = []

    menuItems.push({
      title: t('vehicle-types-list.manual-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeDialog(true)
      },
    })
    menuItems.push({
      title: t('vehicle-types-list.bulk-add'),
      onClick: () => {
        onAddActionsMenuClose()
        setOpenCreateVehicleTypeBulkDialog(true)
      },
    })

    setAddActionsAnchorEl({
      ...addActionsAnchorEl,
      anchorEl: currentTarget,
      items: menuItems,
    })
  }

  // still loading
  if (!counts) {
    return <Loading />
  }
  // local
  return (
    <>
      <CreateVehicleTypeBulkDialog
        open={openCreateVehicleTypeBulkDialog}
        onClose={() => {
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
        onCreated={() => {
          setOpenCreateVehicleTypeBulkDialog(false)
        }}
      />
      <CreateChassisDialog
        open={openCreateChassisDialog}
        onClose={() => setOpenCreateChassisDialog(false)}
        onCreated={() => setOpenCreateChassisDialog(false)}
      />
      <CreateVehicleTypeDialog
        open={openCreateVehicleTypeDialog}
        onClose={() => setOpenCreateVehicleTypeDialog(false)}
        onCreated={() => setOpenCreateVehicleTypeDialog(false)}
      />
      <Menu
        keepMounted
        anchorEl={addActionsAnchorEl.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onAddActionsMenuClose}
        open={Boolean(addActionsAnchorEl.anchorEl)}
      >
        {addActionsAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('dashboard-view.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          {hasPermission(Permissions.PAGE_DASHBOARD_ADD_CHASSIS, user) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth={fullWidth}
              onClick={() => setOpenCreateChassisDialog(true)}
            >
              {t('dashboard-view.add-chassis')}
            </Button>
          )}
          {hasPermission(Permissions.PAGE_DASHBOARD_ADD_VEHICLE_TYPE, user) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              fullWidth={fullWidth}
              onClick={(event) => onAddActions(event.currentTarget)}
            >
              {t('dashboard-view.add-vehicle-type')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={4}
        justifyContent="flex-start"
        className={classes.cardsContainer}
      >
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title={t('dashboard-view.vehicles-to-check')}
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
          >
            <div className={classes.visitsNumberContainer}>
              <Typography variant="h3">{counts.controlCount}</Typography>
            </div>
          </Widget>
        </Grid>
        <Grid item lg={3} md={4} sm={6} xs={12}>
          <Widget
            title={t('dashboard-view.vehicles-to-recheck')}
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
          >
            <div className={classes.visitsNumberContainer}>
              <Typography variant="h3">{counts.controlAgainCount}</Typography>
            </div>
          </Widget>
        </Grid>
      </Grid>
    </>
  )
}
