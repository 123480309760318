import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import AutoCompleteInput from '../../AutoCompleteInput'

export default function ModifyDepotDialog({
  open,
  onClose,
  onModified,
  depot,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState(depot && depot.name)
  const [operators, setOperators] = useState(
    depot &&
      depot.operators.map((operator) => ({
        id: operator.id,
        title: operator.name,
      }))
  )

  const resource = 'depots/' + depot.id + '/edit'

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setName(depot.name)
    setOperators(
      depot.operators.map((operator) => ({
        id: operator.id,
        title: operator.name,
      }))
    )
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (operators.length === 0) {
      setError('operatorIds_required')
      return
    }

    const body = {
      data: {
        name,
        operatorIds: operators.map((operator) => operator.id),
      },
    }

    setLoading(true)
    dataProvider
      .put(resource, body)
      .then(() => {
        dataProvider.cache.reset()
        showNotification('success', t('modify-depot-dialog.success'))
        onModified()
      })
      .catch((err) => {
        if (err.response.data.error === 'DEPOT_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('modify-depot-dialog.already-exists-error')
          )
        } else if (err.response.data.error === 'DEPOT_NOT_FOUND') {
          showNotification('error', t('modify-depot-dialog.not-found-error'))
        } else if (err.response.data.error === 'DEPOT_NEEDS_OPERATOR') {
          showNotification(
            'error',
            t('modify-depot-dialog.needs-operator-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
      })
      .then(() => resetState())
  }

  useEffect(() => {
    if (depot) {
      setName(depot.name)
      setOperators(
        depot.operators.map((operator) => ({
          id: operator.id,
          title: operator.name,
        }))
      )
    }
  }, [depot])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-depot-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('modify-depot-dialog.depot-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setOperators}
            value={operators}
            label={t('modify-depot-dialog.operators')}
            resource="/operators/autocomplete"
            error={error === 'operatorIds_required'}
            helperText={t('common.required')}
            multiple
            required
            canDelete
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value) {
                setOperators(
                  operators.concat({
                    id: (operators.length + 1) * -1,
                    title: (e.target.value || '').trim(),
                  })
                )
              }
            }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
