import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import validators from '../../../../utils/validators'
import ImagePicker from '../../../ImagePicker'

export default function CreateControlItemDialog({
  open,
  onClose,
  onCreated,
  vehicleTypeId,
  belowItemWithId,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [nItem, setNItem] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setNItem('')
    setTitle('')
    setDescription('')
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (nItem.length === 0) {
      setError('nItem_required')
      return
    }

    if (!validators.isValidNItem(nItem)) {
      setError('nItem_invalid')
      return
    }

    if (title.length === 0) {
      setError('title_required')
      return
    }

    if (description.length === 0) {
      setError('description_required')
      return
    }

    const data = {
      vehicleTypeId,
      nItem,
      title,
      description,
    }
    if (belowItemWithId) {
      data.belowItemWithId = belowItemWithId
    }
    setLoading(true)
    if (images) {
      Promise.all(
        images.map((image) =>
          dataProvider
            .upload({ file: image })
            .then((response) => response.photoUrl)
        )
      )
        .then((urls) => {
          data.photos = urls
          return dataProvider
            .create('control-item', { data })
            .then(() => {
              onCreated()
              showNotification(
                'success',
                t('create-control-item-dialog.success')
              )
            })
            .catch(() => {
              showNotification('error', t('errors.api-error'))
            })
        })
        .catch(() => {
          showNotification('error', t('errors.api-error'))
        })
        .then(() => resetState())
    } else {
      dataProvider
        .create('control-item', { data })
        .then(() => {
          dataProvider.cache.reset()
          onCreated()
          showNotification('success', t('create-control-item-dialog.success'))
        })
        .catch(() => {
          showNotification('error', t('errors.api-error'))
        })
        .then(() => resetState())
    }
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-control-item-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'nItem_required' || error === 'nItem_invalid'}
            helperText={
              error === 'nItem_invalid'
                ? t('create-control-item-dialog.nItem-invalid-format')
                : t('common.required')
            }
            id="nItem"
            label={t('common.nItem')}
            fullWidth
            value={nItem}
            onChange={(event) => setNItem(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'title_required'}
            helperText={t('common.required')}
            id="title"
            label={t('common.title')}
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'description_required'}
            helperText={t('common.required')}
            id="description"
            label={t('common.description')}
            multiline
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item>
          <ImagePicker onChange={setImages} />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
