import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  IconButton,
  ListItem,
  List,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import BaseDialog from '../BaseDialog'
import validators from '../../utils/validators'

import useStyles from './styles'
import { ReactComponent as AddIcon } from '../../assets/Icons_Add.svg'
import { ReactComponent as CheckIcon } from '../../assets/Icons_Check.svg'

import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'

export default function ResendControlResultDialog({
  open,
  onClose,
  nChassis,
  controlId,
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [emails, setEmails] = useState(new Set())
  const [emailsSelectionState, setEmailsSelectionState] = useState(new Set())
  const [newEmail, setNewEmail] = useState('')
  const [newEmailError, setNewEmailError] = useState(null)

  const [failedLoad, setFailedLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingEmails, setLoadingEmails] = useState(false)

  const onAddEmail = () => {
    if (!validators.isValidEmail(newEmail)) {
      setNewEmailError('email_invalid')
      return
    }
    setNewEmailError(null)

    const newSet = new Set(emails)
    newSet.add(newEmail)
    setEmails(newSet)
    setNewEmail('')
  }

  const onResend = () => {
    setLoading(true)

    // Only send selected values
    const selectedEmails = Array.from(emails).filter((_, idx) =>
      emailsSelectionState.has(idx)
    )
    const sendToEmails = Array.from(
      { length: selectedEmails.length },
      (v, k) => k
    )

    dataProvider.control
      .emailResult(controlId, selectedEmails, sendToEmails)
      .then(() => {
        showNotification('success', t('resend-control-result-dialog.success'))
        onClose()
      })
      .catch(() => {
        showNotification('error', t('errors.api-error'))
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    if (!controlId || !open) {
      return
    }

    setLoadingEmails(true)
    dataProvider.utils
      .autocompleteEmails()
      .then((response) => {
        setEmails(new Set(response.data.map((item) => item.title)))
        setEmailsSelectionState(new Set())
        setLoadingEmails(false)
      })
      .catch(() => {
        setLoadingEmails(false)
        setFailedLoad(true)
      })
  }, [controlId, open, dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  const emailsList = () => (
    <div className={classes.emailsListContainer}>
      <List className={classes.emailsList}>
        {Array.from(emails).map((email, idx) => (
          <ListItem
            className={classes.emailsListItem}
            key={email}
            onClick={() => {
              const newSet = new Set(emailsSelectionState)
              if (newSet.has(idx)) {
                newSet.delete(idx)
              } else {
                newSet.add(idx)
              }
              setEmailsSelectionState(newSet)
            }}
          >
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid item>
                <Typography display="inline">{email}</Typography>
              </Grid>
              <Grid container item justifyContent="flex-end">
                <CheckIcon
                  visibility={
                    emailsSelectionState.has(idx) ? 'visible' : 'hidden'
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t('resend-control-result-dialog.title', { nChassis })}
      loading={loading}
      onConfirm={onResend}
      openButtonTitle={t('resend-control-result-dialog.confirm-title')}
      confirmDisabled={emailsSelectionState.size <= 0}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography id="subtitle" align="left" variant="h6">
            {t('resend-control-result-dialog.subtitle', { nChassis })}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item style={{ flex: 1 }}>
              <TextField
                variant="standard"
                id="newEmail"
                label={t('resend-control-result-dialog.email-placelhoder')}
                fullWidth
                value={newEmail}
                onChange={(event) => setNewEmail(event.target.value)}
                error={newEmailError === 'email_invalid'}
                helperText={
                  newEmailError === 'email_invalid'
                    ? t('errors.not-valid')
                    : undefined
                }
              />
            </Grid>
            <Grid item>
              <IconButton onClick={onAddEmail} size="large">
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            id="subtitle"
            align="left"
            variant="h6"
            className={classes.emailsListTitle}
          >
            {t('resend-control-result-dialog.emails-title')}
          </Typography>

          {loadingEmails ? (
            <Grid container direction="column" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            emailsList()
          )}
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
