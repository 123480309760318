import React from 'react'

// components
import { IconButton, Button, Typography } from '@mui/material'

import { ReactComponent as MoreIcon } from '../../assets/Icons_More_Horizontal.svg'
import { hasPermission } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function generate({
  t,
  classes,
  onMoreActions,
  onRequestNewPassword,
  user,
  tableData,
}) {
  return [
    {
      name: 'firstName',
      label: t('common.firstName'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isActive
            ? ''
            : classes.disabled
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].firstName}
            </Typography>
          )
        },
      },
    },
    {
      name: 'lastName',
      label: t('common.lastName'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isActive
            ? ''
            : classes.disabled
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].lastName}
            </Typography>
          )
        },
      },
    },
    {
      name: 'email',
      label: t('common.email'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isActive
            ? ''
            : classes.disabled
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].email}
            </Typography>
          )
        },
      },
    },
    {
      name: 'requestPassword',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (hasPermission(Permissions.PAGE_USERS_MANAGE_PASSWORDS, user)) {
            let buttonTitle = t('permissions.page-users.request-new-password')
            if (tableData.items[tableMeta.rowIndex].id === user.id) {
              buttonTitle = t('permissions.page-users.change-user-password')
            }
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onRequestNewPassword(tableMeta.rowIndex)}
                color="primary"
                disabled={!tableData.items[tableMeta.rowIndex].isActive}
              >
                {buttonTitle}
              </Button>
            )
          }
          return ''
        },
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <IconButton
            className={[classes.allItemsButton].join(' ')}
            onClick={(event) =>
              onMoreActions(event.currentTarget, tableMeta.rowIndex)
            }
            size="large"
          >
            <MoreIcon id="icon" />
          </IconButton>
        ),
      },
    },
  ]
}
