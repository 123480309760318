import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import useStyles from '../styles'
import TabItems from './tab-items'
import TabDocuments from './tab-documents'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const OrderedTabs = ['items', 'documents']

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function VehicleTypeDetailTabs({ vehicleType }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const query = useQuery()
  const history = useHistory()

  const [value, setValue] = useState(() => {
    if (query.has('tab')) {
      const tabQuery = query.get('tab')
      const tabIndex = OrderedTabs.findIndex((tab) => tab === tabQuery)
      if (tabIndex !== -1) {
        return tabIndex
      }
    }
    return 0
  })

  const handleChange = (event, newValue) => {
    history.replace({ search: ['?', 'tab=', OrderedTabs[newValue]].join('') })
    setValue(newValue)
  }

  return (
    <Box className={classes.thirdSection}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('vehicle-type-detail.tabs.items')} />
          <Tab label={t('vehicle-type-detail.tabs.documents')} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TabItems vehicleTypeId={vehicleType.id} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabDocuments vehicleType={vehicleType} />
      </TabPanel>
    </Box>
  )
}
