import React, { useState, useEffect, useCallback } from 'react'

// components
import { useTranslation } from 'react-i18next'
import { Grid, Button, MenuItem, useMediaQuery, useTheme } from '@mui/material'
import useStyles from './styles'
import CreateControlItemDialog from '../../../../components/ControlItem/components/CreateControlItemDialog'
import Table from '../../../../components/Table'
import generateColumns from './columns'
import ModifyControlItemDialog from '../../../../components/ControlItem/components/ModifyControlItemDialog'
import PhotoGaleryDialog from '../../../../components/PhotoGaleryDialog'

import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import { useDataApi } from '../../../../context/DataApiContext'
import { useConfirmationDialogProvider } from '../../../../context/ConfirmationDialogContext'

import { ReactComponent as UndoIcon } from '../../../../assets/Icons_undo.svg'
import { ReactComponent as RemoveCircleIcon } from '../../../../assets/Icons_Remove_Circle.svg'
import { ReactComponent as AddOutlineIcon } from '../../../../assets/Icons_Add_Outline.svg'
import { ReactComponent as TrashIcon } from '../../../../assets/icons-Delete.svg'
import Loading from '../../../../components/Loading'
import { Stack } from '@mui/system'

export default function ControlItems({ vehicleTypeId }) {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const classes = useStyles()
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [loadingData, setLoadingData] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
    rowsSelected: [],
  })
  const [tableData, setTableData] = useState(null)

  const [openCreateControlItemDialog, setOpenCreateControlItemDialog] =
    useState({
      dialogOpen: false,
      createBelowItemWithId: null,
    })
  const [openModifyControlItemDialog, setOpenModifyControlItemDialog] =
    useState(false)
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const [photoDialogUrls, setPhotoDialogUrls] = useState([])
  const [controlItemToModify, setControlItemToModify] = useState({})
  const [reorganizeItemFromId, setReorganizeItemFromId] = useState(null)
  const [reorganizeOperations, setReorganizeOperations] = useState([])
  const [showAllTableRows, setShowAllTableRows] = useState(false)

  const resource = `vehicle-type/${vehicleTypeId}/items`

  useEffect(() => {
    dataProvider
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        {
          all: showAllTableRows,
        }
      )
      .then((response) => {
        setTableData(response.data)
        setLoadingData(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const refreshData = useCallback(
    (keepPage = false) => {
      if (keepPage) {
        setTableState({
          page: tableState.page,
          sort: null,
          filters: null,
          rowsSelected: [],
        })
      } else {
        dataProvider.cache.reset()
        setTableState({
          page: 0,
          sort: null,
          filters: null,
          rowsSelected: [],
        })
      }
    },
    [dataProvider.cache, tableState.page]
  )

  const onShowPhotos = (rowIndex) => {
    const { photos } = tableData.items[rowIndex]
    setPhotoDialogUrls(photos)
    setOpenPhotoDialog(true)
  }

  const onModify = (rowIndex) => {
    setControlItemToModify(tableData.items[rowIndex])
    setOpenModifyControlItemDialog(true)
  }

  const performDelete = useCallback(
    (rowIndexes) => {
      if (!rowIndexes || rowIndexes.length === 0) {
        return
      }
      let dialogTitle = t('control-items.confirm-item-deletion.multiple')
      const idsToDelete = tableData.items
        .filter((item, index) => rowIndexes.indexOf(index) !== -1)
        .map((item) => item.id)

      if (idsToDelete.length === 1) {
        const { nItem } = tableData.items[rowIndexes[0]]
        dialogTitle = t('control-items.confirm-item-deletion.single', { nItem })
      }
      showConfirmationDialog(dialogTitle, () => {
        dataProvider
          .deleteBulk('/control-item/bulk', idsToDelete)
          .then(() => {
            // Clean up reorganizeOps which reference this deleted item
            const filteredReorganizeOperations = reorganizeOperations.filter(
              (op) => {
                const deletedToId = idsToDelete.indexOf(op.toId) !== -1
                const deletedFromId = idsToDelete.indexOf(op.fromId) !== -1
                return !deletedToId && !deletedFromId
              }
            )
            setReorganizeOperations(filteredReorganizeOperations)
            setReorganizeItemFromId(null)

            // Sync row selection by removing deleted elements
            const filteredSelection = tableState.rowsSelected.filter(
              (index) => rowIndexes.indexOf(index) === -1
            )
            setTableState((state) => ({
              ...state,
              rowsSelected: filteredSelection,
            }))

            showNotification('success', '')
            refreshData(true)
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      })
    },
    [
      dataProvider,
      refreshData,
      reorganizeOperations,
      tableState.rowsSelected,
      showConfirmationDialog,
      showNotification,
      t,
      tableData,
    ]
  )

  const onDelete = (rowIndex) => {
    performDelete([rowIndex])
  }

  const onReorganize = (rowIndex) => {
    const { id, itemOrder } = tableData.items[rowIndex]
    if (reorganizeItemFromId === null) {
      setReorganizeItemFromId({ id, itemOrder })
    } else if (reorganizeItemFromId.id === id) {
      setReorganizeItemFromId(null)
    } else {
      const reorganizeOperation = {
        fromId: reorganizeItemFromId.id,
        fromItemOrder: reorganizeItemFromId.itemOrder,
        toId: id,
        vehicleTypeId,
      }
      dataProvider
        .put('/control-item/reorder', {
          data: {
            fromId: reorganizeOperation.fromId,
            toId: reorganizeOperation.toId,
            vehicleTypeId: reorganizeOperation.vehicleTypeId,
          },
        })
        .then(() => {
          // Store op for Undo
          setReorganizeOperations([
            ...reorganizeOperations,
            reorganizeOperation,
          ])
          setReorganizeItemFromId(null)
          showNotification('success', t('common.item-reordered'))
          refreshData(true)
        })
        .catch(() => showNotification('error', t('errors.api-error')))
    }
  }

  const onUndoReorganizeOperation = () => {
    if (reorganizeOperations.length <= 0) {
      return
    }
    const operationIndex = reorganizeOperations.length - 1
    const operationToUndo = reorganizeOperations[operationIndex]
    dataProvider
      .put('/control-item/reorder/undo', {
        data: {
          id: operationToUndo.fromId,
          originalItemOrder: operationToUndo.fromItemOrder,
          vehicleTypeId: operationToUndo.vehicleTypeId,
        },
      })
      .then(() => {
        // Remove Undo operation
        const updatedOperations = [...reorganizeOperations]
        updatedOperations.splice(operationIndex, 1)
        setReorganizeOperations(updatedOperations)
        showNotification('success', t('common.item-reordered'))
        refreshData(true)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const onToggleShowAllItems = () => {
    setShowAllTableRows(!showAllTableRows)
    refreshData(false)
  }

  const performCreateItemBelowSelection = useCallback(
    (rowIndex) => {
      const { id } = tableData.items[rowIndex]
      setOpenCreateControlItemDialog({
        dialogOpen: true,
        createBelowItemWithId: id,
      })
    },
    [tableData]
  )

  const onCreateItemBelowSelection = () => {
    if (
      tableState &&
      tableState.rowsSelected &&
      tableState.rowsSelected.length === 1
    ) {
      performCreateItemBelowSelection(tableState.rowsSelected[0])
    }
  }

  const onDeleteSelection = () => {
    if (tableState && tableState.rowsSelected) {
      performDelete(tableState.rowsSelected)
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    reorganizeItemFromId,
    onDelete,
    onModify,
    onReorganize,
    onShowPhotos,
  })

  const createRightClickMenuItems = useCallback(
    (rowIndex) => {
      if (!tableState.rowsSelected) {
        return []
      }

      if (
        tableState.rowsSelected.length === 1 ||
        tableState.rowsSelected.length === 0
      ) {
        return [
          <MenuItem
            key={1}
            onClick={() => {
              performCreateItemBelowSelection(rowIndex)
            }}
          >
            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.menuItemRow}
            >
              <AddOutlineIcon id="icon" />
              <Grid item>{t('control-items.create-item-below-line')}</Grid>
            </Grid>
          </MenuItem>,
          <MenuItem key={2} onClick={() => performDelete([rowIndex])}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className={classes.menuItemRow}
            >
              <TrashIcon id="icon" />
              <Grid item>{t('control-items.delete-item.single')}</Grid>
            </Grid>
          </MenuItem>,
        ]
      }

      return [
        <MenuItem
          key={2}
          onClick={() => performDelete(tableState.rowsSelected)}
        >
          <Grid
            container
            alignItems="center"
            spacing={2}
            className={classes.menuItemRow}
          >
            <TrashIcon id="icon" />
            <Grid item>{t('control-items.delete-item.multiple')}</Grid>
          </Grid>
        </MenuItem>,
      ]
    },
    [
      performCreateItemBelowSelection,
      performDelete,
      t,
      classes.menuItemRow,
      tableState.rowsSelected,
    ]
  )

  if (loadingData) {
    return <Loading minHeight="15vh" />
  }

  return (
    <>
      <CreateControlItemDialog
        open={openCreateControlItemDialog.dialogOpen}
        onCreated={() => {
          setOpenCreateControlItemDialog({
            dialogOpen: false,
            createBelowItemWithId: null,
          })
          refreshData(true)
        }}
        onClose={() => {
          setOpenCreateControlItemDialog({
            dialogOpen: false,
            createBelowItemWithId: null,
          })
        }}
        vehicleTypeId={vehicleTypeId}
        belowItemWithId={openCreateControlItemDialog.createBelowItemWithId}
      />
      <ModifyControlItemDialog
        open={openModifyControlItemDialog}
        originalControlItem={controlItemToModify}
        onModified={() => {
          refreshData(true)
          setOpenModifyControlItemDialog(false)
        }}
        onClose={() => {
          setControlItemToModify({})
          setOpenModifyControlItemDialog(false)
        }}
      />
      <PhotoGaleryDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        images={photoDialogUrls}
      />
      <Stack
        direction={largeScreen ? 'row' : 'column'}
        spacing={2}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
        p={1}
      >
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={classes.undoButton}
          disabled={(reorganizeOperations || []).length === 0}
          onClick={() => onUndoReorganizeOperation()}
        >
          <UndoIcon />
        </Button>
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={[
            classes.allItemsButton,
            showAllTableRows ? 'selected' : undefined,
          ].join(' ')}
          onClick={() => onToggleShowAllItems()}
        >
          <RemoveCircleIcon id="icon" />
          <span id="text">{t('common.show-all-items')}</span>
        </Button>
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={[classes.allItemsButton].join(' ')}
          onClick={() => onCreateItemBelowSelection()}
          disabled={
            tableState &&
            tableState.rowsSelected &&
            tableState.rowsSelected.length !== 1
          }
        >
          <AddOutlineIcon id="icon" />
          <span id="text">{t('control-items.create-item-below-line')}</span>
        </Button>
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={[classes.allItemsButton].join(' ')}
          onClick={() => onDeleteSelection()}
          disabled={
            tableState &&
            tableState.rowsSelected &&
            tableState.rowsSelected.length <= 0
          }
        >
          <TrashIcon id="icon" />
          <span id="text">
            {tableState &&
            tableState.rowsSelected &&
            tableState.rowsSelected.length > 1
              ? t('control-items.delete-item.multiple')
              : t('control-items.delete-item.single')}
          </span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth={fullWidth}
          onClick={() =>
            setOpenCreateControlItemDialog({
              dialogOpen: true,
              createBelowItemWithId: null,
            })
          }
        >
          {t('control-items.add-control-item')}
        </Button>
      </Stack>
      {vehicleTypeId && (
        <Grid item className={classes.tableContainer}>
          <Table
            id={resource}
            columns={tableColumns}
            data={tableData}
            page={tableState.page}
            sort={tableState.sort}
            selectableRows="multiple"
            pagination={!showAllTableRows}
            onChangePage={(currentPage) => {
              setTableState(() => ({
                ...tableState,
                page: currentPage,
                rowsSelected: [],
              }))
            }}
            onColumnSortChange={(changedColumn, direction) => {
              let newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              if (direction === 'none') {
                newSort = null
              }
              setTableState(() => ({
                ...tableState,
                sort: newSort,
                rowsSelected: [],
              }))
            }}
            rowsSelected={tableState.rowsSelected}
            onRowSelectionChange={(rowsSelected, allRows) => {
              setTableState((state) => ({
                ...state,
                rowsSelected: allRows.map((row) => row.dataIndex),
              }))
            }}
            menuItems={(rowIndex) => createRightClickMenuItems(rowIndex)}
          />
        </Grid>
      )}
    </>
  )
}
