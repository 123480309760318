import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function ModifyConstructorDialog({
  open,
  onClose,
  onModified,
  constructorToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [vehicleTypes, setVehicleTypes] = useState([])

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    if (constructorToModify && constructorToModify.vehicleTypes) {
      setName(constructorToModify.name)
      setVehicleTypes(constructorToModify.vehicleTypes)
    }
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (vehicleTypes === undefined) {
      setError('vehicleTypeIds_required')
      return
    }

    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (vehicleTypes.length === 0) {
      setError('vehicleTypeIds_required')
      return
    }

    setLoading(true)
    const constructorId = constructorToModify.id
    dataProvider.constructor
      .put(
        constructorId,
        name,
        vehicleTypes.map((type) => type.id)
      )
      .then(() => {
        dataProvider.cache.reset()
        onModified()
        showNotification('success', t('modify-constructor-dialog.success'))
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'CONSTRUCTOR_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('modify-constructor-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    if (constructorToModify && constructorToModify.vehicleTypes) {
      setName(constructorToModify.name)
      setVehicleTypes(constructorToModify.vehicleTypes)
    }
  }, [constructorToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-constructor-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('modify-constructor-dialog.constructor-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setVehicleTypes}
            value={vehicleTypes}
            label={t('modify-constructor-dialog.vehicle-type')}
            resource="/constructors/autocomplete-vehicle-types"
            error={error === 'vehicleTypeIds_required'}
            multiple
            required
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
