import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function CreateVehicleTypeDialog({ open, onClose, onCreated }) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [type, setType] = useState('')
  const [specificity, setSpecificity] = useState('')
  const [constructeur, setConstructor] = useState(null)
  const [member, setMember] = useState(null)
  const [copyItemsFromId, setCopyItemsFromId] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setType('')
    setSpecificity('')
    setConstructor(null)
    setMember(null)
    setCopyItemsFromId(null)
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (type.length === 0) {
      setError('type_required')
      return
    }

    if (specificity.length === 0) {
      setError('specificity_required')
      return
    }

    let data = !constructeur
      ? {
          type,
          specificity,
        }
      : {
          type,
          specificity,
          constructorId: constructeur.id,
        }
    if (member) {
      data.memberId = member.id
    }
    if (copyItemsFromId) {
      data = { copyItemsFromId: copyItemsFromId.id, ...data }
    }
    setLoading(true)
    dataProvider
      .create('vehicle-type', { data })
      .then(() => {
        dataProvider.cache.reset()
        dataProvider.member.resetCache()
        showNotification('success', t('create-vehicle-type-dialog.success'))
        onCreated()
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'VEHICLE_TYPE_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('create-vehicle-type-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-vehicle-type-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'type_required'}
            helperText={t('common.required')}
            id="type"
            label={t('create-vehicle-type-dialog.type')}
            fullWidth
            value={type}
            onChange={(event) => setType(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'specificity_required'}
            helperText={t('common.required')}
            id="specificity"
            label={t('create-vehicle-type-dialog.specificity')}
            fullWidth
            value={specificity}
            onChange={(event) => setSpecificity(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setConstructor}
            value={constructeur}
            label={t('create-vehicle-type-dialog.constructor-label')}
            resource="/constructors/autocomplete"
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setMember}
            value={member}
            label={t('create-vehicle-type-dialog.member')}
            resource="vehicle-type/autocomplete-members"
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setCopyItemsFromId}
            value={copyItemsFromId}
            label={t('create-vehicle-type-dialog.copy-items')}
            resource="vehicle-type/autocomplete"
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
