import React from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'

import { ReactComponent as CloseIcon } from '../../assets/Icons_Close.svg'

export default function BaseDialog({
  open,
  onClose,
  onConfirm,
  title,
  children,
  hideCloseButton,
  loading,
  openButtonTitle,
  closeButtonTitle,
  hideActions,
  confirmDisabled = false,
  classes: overrideClasses,
  width = 'sm',
  disableEnforceFocus = false,
}) {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const openButtonTitleTranslation = openButtonTitle || t('common.confirm')
  const closeButtonTitleTranslation = closeButtonTitle || t('common.cancel')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      classes={overrideClasses}
      maxWidth={width}
      fullWidth={!!width}
      disableEnforceFocus={disableEnforceFocus}
    >
      {!hideCloseButton && (
        <DialogTitle className={classes.titleContainer}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item>
              <IconButton onClick={onClose} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      <DialogContent classes={overrideClasses}>
        {loading ? (
          <Grid container justifyContent="space-around">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {children}
            {!hideActions && (
              <DialogActions
                className={
                  hideCloseButton
                    ? classes.actionsContainerCenter
                    : classes.actionsContainerEnd
                }
              >
                <Button onClick={onClose} color="secondary" variant="contained">
                  {closeButtonTitleTranslation}
                </Button>
                <Button
                  onClick={onConfirm}
                  color="primary"
                  variant="contained"
                  disabled={confirmDisabled}
                >
                  {openButtonTitleTranslation}
                </Button>
              </DialogActions>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
