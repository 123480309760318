import React from 'react'
import { consumeEvent } from '../../utils/wrapers'
// components
import { Button } from '@mui/material'

export default function generate({
  t,
  classes,
  onDeactivate,
  onActivate,
  onModify,
  onDelete,
}) {
  return [
    {
      name: 'type',
      label: t('vehicle-types-list.type'),
    },
    {
      name: 'specificity',
      label: t('vehicle-types-list.specificity'),
      options: {
        sort: false,
      },
    },
    {
      name: 'constructor',
      label: t('vehicle-types-list.constructor-label'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val && val.id && val.name) {
            return val.name
          }
          return ''
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={consumeEvent(() => onModify(tableMeta.rowIndex))}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'active',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={
              val
                ? consumeEvent(() => onDeactivate(tableMeta.rowIndex))
                : consumeEvent(() => onActivate(tableMeta.rowIndex))
            }
            color="primary"
          >
            {val ? t('common.deactivate') : t('common.activate')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={consumeEvent(() => onDelete(tableMeta.rowIndex))}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
