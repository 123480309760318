import React from 'react'
import { SvgIcon } from '@mui/material'

const ConstructorsIcon = () => (
  <SvgIcon>
    <path
      fill="primary"
      fillRule="evenodd"
      d="M16.041 4.938H7.912l-4.064 7.04 4.064 7.04h8.13l4.064-7.04-4.065-7.04zM12 16c2.209 0 4-1.79 4-4 0-2.209-1.791-4-4-4-2.21 0-4 1.791-4 4 0 2.21 1.79 4 4 4z"
    />
  </SvgIcon>
)

export default ConstructorsIcon
