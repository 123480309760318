import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, TableRow, TableCell, useTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'

import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'
import { permissionTree, translate } from '../../entities/permissions'

import useStyles from './styles'
import generateColumns from './columns'

// Component imports
import PageTitle from '../../components/PageTitle'
import Loading from '../../components/Loading'

export default function Permissions() {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [loading, setLoading] = useState(true)
  const [permissionsData, setPermissionsData] = useState([])
  const [rolesData, setRolesData] = useState([])
  const [tableState, setTableState] = useState(false)

  const resource = 'permission'

  const onSelect = (rowIndex, columnIndex, isSelected) => {
    const role = rolesData[columnIndex - 1].key
    const permission = permissionsData[rowIndex].key
    if (!isSelected) {
      setLoading(true)
      dataProvider.permissions
        .post(role, permission)
        .then(() => {
          setTableState(!tableState)
        })
        .catch(() => showNotification('error', t('errors.api-error')))
    } else {
      setLoading(true)
      dataProvider.permissions
        .deletePermission(role, permission)
        .then(() => {
          setTableState(!tableState)
        })
        .catch(() => showNotification('error', t('errors.api-error')))
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    permissionsData,
    roles: rolesData,
    onSelect,
  })

  useEffect(() => {
    dataProvider.permissions
      .get()
      .then((response) => {
        const receivedRoles = response.data.roles || []
        const receivedPermissions = response.data.permissions || {}
        const keys = Object.keys(permissionTree)
        const tempPermissions = []
        keys.forEach((key) => {
          tempPermissions.push({
            key,
            title: translate(key, t),
            isGroup: true,
            // eslint-disable-next-line no-return-assign
            // eslint-disable-next-line max-len
            selectedRoles:
              key in receivedPermissions
                ? Object.keys(receivedPermissions[key]).filter(
                    (role) => receivedPermissions[key][role] === true
                  )
                : [],
          })
          permissionTree[key].forEach((value) => {
            tempPermissions.push({
              key: value,
              title: translate(value, t),
              isGroup: false,
              // eslint-disable-next-line max-len
              selectedRoles:
                value in receivedPermissions
                  ? Object.keys(receivedPermissions[value]).filter(
                      (role) => receivedPermissions[value][role] === true
                    )
                  : [],
            })
          })
        })
        setRolesData(
          Object.keys(receivedRoles).map((roleId) => ({
            key: roleId,
            label: receivedRoles[roleId],
          }))
        )
        setPermissionsData(tempPermissions)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <Grid container direction="column">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('permissions.title')} />
        </Grid>
        <MUIDataTable
          key={resource}
          data={permissionsData}
          columns={tableColumns}
          options={{
            enableNestedDataAccess: '.',
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            download: false,
            selectableRows: 'none',
            pagination: false,
            rowsPerPage: permissionsData.count,
            rowsPerPageOptions: [],
            count: permissionsData.count,
            serverSide: false,
            responsive: 'vertical',
            customRowRender: (data, dataIndex, rowIndex) => {
              const { isGroup } = permissionsData[rowIndex]
              return (
                <TableRow
                  key={permissionsData[rowIndex].key}
                  style={{
                    backgroundColor: isGroup
                      ? theme.palette.captGrey.veryLight
                      : undefined,
                  }}
                >
                  <TableCell key="title">{data[0]}</TableCell>
                  {rolesData.map((role, idx) => (
                    <TableCell key={role.key}> {data[idx + 1]} </TableCell>
                  ))}
                </TableRow>
              )
            },
          }}
          className={classes.table}
        />
      </Grid>
    </>
  )
}
