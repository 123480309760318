import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
} from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'

import useStyles from './styles'

// eslint-disable-next-line max-len
import CreateEmailListDialog from '../../components/Emails/CreateEmailListDialog'
// eslint-disable-next-line max-len
import CreateEmailDialog from '../../components/Emails/CreateEmailDialog'
// eslint-disable-next-line max-len
import ModifyEmailDialog from '../../components/Emails/ModifyEmailDialog'
// eslint-disable-next-line max-len
import ModifyEmailListDialog from '../../components/Emails/ModifyEmailListDialog'

import Table from '../../components/Table'
import generateColumns from './columns'
import generateEmailsColumns from './emails-columns'

export default function EmailListsList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [items, setItems] = useState([])
  const [emailItems, setEmailItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)
  const [emailsTableState, setEmailsTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [emailsTableData, setEmailsTableData] = useState(null)
  const [openCreateEmailListDialog, setOpenCreateEmailListDialog] =
    useState(false)
  const [openCreateEmailDialog, setOpenCreateEmailDialog] = useState(false)
  const [openModifyEmailDialog, setOpenModifyEmailDialog] = useState(false)
  const [openModifyEmailListDialog, setOpenModifyEmailListDialog] =
    useState(false)
  const [emailToModify, setEmailToModify] = useState(false)
  const [emailListToModify, setEmailListToModify] = useState(false)

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
      setEmailsTableState({
        page: emailsTableState.page,
        sort: null,
        filters: null,
      })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
      setEmailsTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'emails/lists'
  const emailResource = 'emails'

  useEffect(() => {
    dataProvider
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        false
      )
      .then((response) => {
        setItems(response.data.items)
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  useEffect(() => {
    dataProvider
      .getList(
        emailResource,
        {
          range: { page: emailsTableState.page + 1 },
          filters: emailsTableState.filters,
          sort: emailsTableState.sort,
        },
        false
      )
      .then((response) => {
        setEmailItems(response.data.items)
        setEmailsTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [emailsTableState])

  const onModifyEmail = (rowIndex) => {
    setEmailToModify(emailsTableData.items[rowIndex])
    setOpenModifyEmailDialog(true)
  }

  const onModifyEmailList = (rowIndex) => {
    setEmailListToModify(tableData.items[rowIndex])
    setOpenModifyEmailListDialog(true)
  }

  const onDeleteEmail = (rowIndex) => {
    const { id, email } = emailsTableData.items[rowIndex]
    showConfirmationDialog(
      t('email-lists-list.confirm-email-deletion', {
        email,
      }),
      () => {
        dataProvider
          .delete(`emails`, { id })
          .then(() => {
            refreshData()
            showNotification(
              'success',
              t('email-lists-list.email-delete-success')
            )
          })
          .catch((err) => {
            if (err.response.data.error === 'EMAIL_NOT_FOUND') {
              showNotification(
                'error',
                t('email-lists-list.email-delete-not-found-error')
              )
            } else {
              showNotification('error', t('errors.api-error'))
            }
          })
      },
      t('common.delete')
    )
  }

  const onDeleteEmailList = (rowIndex) => {
    const { id, name } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('email-lists-list.confirm-email-list-deletion', {
        name,
      }),
      () => {
        dataProvider
          .delete(`emails/lists`, { id })
          .then(() => {
            refreshData()
            showNotification('success', t('email-lists-list.delete-success'))
          })
          .catch((err) => {
            if (err.response.data.error === 'EMAIL_LIST_NOT_FOUND') {
              showNotification(
                'error',
                t('email-lists-list.delete-not-found-error')
              )
            } else {
              showNotification('error', t('errors.api-error'))
            }
          })
      },
      t('common.delete')
    )
  }

  const tableColumns = generateColumns({
    t,
    classes,
    items,
    onModifyEmailList,
    onDeleteEmailList,
  })

  const emailsTableColumns = generateEmailsColumns({
    t,
    classes,
    emailItems,
    onModifyEmail,
    onDeleteEmail,
  })
  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateEmailListDialog
        open={openCreateEmailListDialog}
        onClose={() => setOpenCreateEmailListDialog(false)}
        onCreated={() => {
          setOpenCreateEmailListDialog(false)
          refreshData()
        }}
      />
      <CreateEmailDialog
        open={openCreateEmailDialog}
        onClose={() => setOpenCreateEmailDialog(false)}
        onCreated={() => {
          setOpenCreateEmailDialog(false)
          refreshData()
        }}
      />
      <ModifyEmailDialog
        open={openModifyEmailDialog}
        onClose={() => setOpenModifyEmailDialog(false)}
        onModified={() => {
          setOpenModifyEmailDialog(false)
          refreshData()
        }}
        emailToEdit={emailToModify}
      />
      <ModifyEmailListDialog
        open={openModifyEmailListDialog}
        onClose={() => setOpenModifyEmailListDialog(false)}
        onModified={() => {
          setOpenModifyEmailListDialog(false)
          refreshData()
        }}
        emailListToEdit={emailListToModify}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('email-lists-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateEmailListDialog(true)}
          >
            {t('email-lists-list.add-email-list')}
          </Button>
        </Grid>
      </Grid>
      <Table
        id={resource}
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
      />
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        marginTop={6}
        marginBottom={2}
      >
        <Grid item xs={12} sm={4}>
          <Typography variant="h5">
            {t('email-lists-list.emails-table-title')}
          </Typography>
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateEmailDialog(true)}
          >
            {t('email-lists-list.add-email')}
          </Button>
        </Grid>
      </Grid>

      <Table
        id={emailResource}
        columns={emailsTableColumns}
        data={emailsTableData}
        page={emailsTableState.page}
        sort={emailsTableState.sort}
        onChangePage={(currentPage) => {
          setEmailsTableState({ ...emailsTableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setEmailsTableState({ ...emailsTableState, sort: newSort })
        }}
      />
    </>
  )
}
