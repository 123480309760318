import LRU from 'lru-cache'
import Utils from '../utils/common'

const { prepareUrlForQuery } = Utils

const cacheOptions = {
  max: 100,
  maxAge: 1000 * 60, // 1 minute in miliseconds
}

const cache = new LRU(cacheOptions)

function getList(resource, params, httpClient, useCache = true) {
  const url = prepareUrlForQuery(
    resource,
    params.range,
    params.filters,
    params.sort
  )
  const cachedData = cache.get(url)
  if (cachedData && useCache) {
    return Promise.resolve(cachedData)
  }
  return httpClient({ url, method: 'GET' }).then((response) => {
    cache.set(url, response)
    return response
  })
}

function post(name, depots, memberId, httpClient) {
  cache.reset()
  const body = JSON.stringify({ name, depots, memberId })

  return httpClient({
    url: '/operators',
    method: 'POST',
    data: body,
  })
}

function put(operatorId, name, depots, memberId, httpClient) {
  cache.reset()
  const body = JSON.stringify({ name, depots, memberId })

  return httpClient({
    url: `/operators/${operatorId}`,
    method: 'PUT',
    data: body,
  })
}

export default {
  getList,
  post,
  put,
}
