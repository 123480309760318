import React from 'react'

// components
import { Typography, Link } from '@mui/material'

export default function generate({ t, renderConformity, onDocumentsClicked }) {
  return [
    {
      name: 'documentTypeName',
      label: t('documents.document-type'),
      options: {
        sort: false,
      },
    },
    {
      name: 'document.originalName',
      label: t('common.title'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val) {
            return (
              <Typography>
                <Link
                  id="subtitle"
                  align="left"
                  variant="body2"
                  onClick={() => onDocumentsClicked(tableMeta.rowIndex)}
                >
                  {val}
                </Link>
              </Typography>
            )
          }
          return t('documents.missing')
        },
      },
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: false,
        customBodyRender: (val) => renderConformity(val),
      },
    },
    {
      name: 'description',
      label: t('common.descriptionmn-non-conformity'),
      options: {
        sort: false,
      },
    },
  ]
}
