import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  textBlack: {
    color: 'black',
  },
  textGray: {
    color: '#707182',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  firstSection: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    marginBottom: theme.spacing(2),
  },
  firstSectionFirstSubSection: {
    borderBottom: '3px solid #f4f6fe',
    padding: theme.spacing(4, 4),
  },
  firstSectionSecondSubSection: {
    padding: theme.spacing(2, 2),
  },
  secondSection: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    marginBottom: theme.spacing(2),
  },
  secondSectionFirstSubSection: {
    borderBottom: '3px solid #f4f6fe',
  },
  thirdSection: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    marginBottom: theme.spacing(2),
    overflow: 'hidden', // Crop content to corner-radius (https://stackoverflow.com/questions/8582176/should-border-radius-clip-the-content)
  },
  thirdSectionFirstSubSection: {
    borderBottom: '3px solid #f4f6fe',
    padding: theme.spacing(2, 2),
  },
  addControlSection: {
    marginBottom: theme.spacing(2),
  },
  linkButton: {
    textTransform: 'capitalize',
  },
  selector: {
    width: '100%',
    paddingRight: theme.spacing(2),
  },
  greenText: {
    color: 'green',
  },
  orangeText: {
    color: 'orange',
  },
  disabledIcon: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  textButton: {
    color: '#326D8C',
    fontWeight: '600',
  },
}))
