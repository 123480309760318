function associateDocumentTypes(vehicleTypeId, body, httpClient) {
  return httpClient({
    url: `/vehicle-type/${vehicleTypeId}/document-type`,
    method: 'PUT',
    data: JSON.stringify(body),
  })
}

export default {
  associateDocumentTypes,
}
