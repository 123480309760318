import React, { useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import tinycolor from 'tinycolor2'
import 'canvas-datagrid'
import { useTheme } from '@mui/material'
import validators from '../../../../../../utils/validators'

function hasError(value, row, column) {
  if (
    (typeof value === 'undefined' && column === 0 && row > -1) ||
    (value === '' && column === 0 && row > -1)
  ) {
    return true
  } else if (!validators.isValidNItem(value) && column === 0 && row > -1) {
    return true
  }
  if (
    (typeof value === 'undefined' && column === 1 && row > -1) ||
    (value === '' && column === 1 && row > -1)
  ) {
    return true
  }
  if (
    (typeof value === 'undefined' && column === 2 && row > -1) ||
    (value === '' && column === 2 && row > -1)
  ) {
    return true
  }
  return false
}

const CanvasDatagrid = forwardRef((props, parentRef) => {
  const gridRef = useRef(null)

  const theme = useTheme()
  const { t } = useTranslation()

  useEffect(() => {
    gridRef.current.data = props.data
  }, [props.data])

  useEffect(() => {
    gridRef.current.removeEventListener('endedit')
    gridRef.current.addEventListener('endedit', (e) => {
      const newValueHasError = hasError(
        e.value,
        e.cell.rowIndex,
        e.cell.columnIndex
      )
      const oldValueHasError = hasError(
        e.cell.value,
        e.cell.rowIndex,
        e.cell.columnIndex
      )
      if (newValueHasError && !oldValueHasError) {
        props.onEditFailure(e.cell.rowIndex, e.cell.columnIndex)
      } else if (!newValueHasError && oldValueHasError) {
        props.onEditSuccess(e.cell.rowIndex, e.cell.columnIndex)
      }
    })
    gridRef.current.removeEventListener('contextmenu')
    gridRef.current.addEventListener('contextmenu', (e) => {
      e.items.splice(0, e.items.length)
      const hasAddOption =
        e.items.filter(
          (item) =>
            item.title ===
            t(
              'create-vehicle-type-dialog.bulk-upload-wizard.preview-menu-add-row'
            )
        ).length > 0
      if (hasAddOption === false) {
        e.items.push({
          title: t(
            'create-vehicle-type-dialog.bulk-upload-wizard.preview-menu-add-row'
          ),
          click() {
            gridRef.current.addRow({})
            props.onAddRow(props.data.length - 1)
          },
        })
      }
      const hasDeleteOption =
        e.items.filter(
          (item) =>
            item.title ===
            t(
              'create-vehicle-type-dialog.bulk-upload-wizard.preview-menu-delete-row'
            )
        ).length > 0
      if (hasDeleteOption === false) {
        e.items.push({
          title: t(
            'create-vehicle-type-dialog.bulk-upload-wizard.preview-menu-delete-row'
          ),
          click() {
            gridRef.current.deleteRow(e.cell.rowIndex)
            props.onDeleteRow(e.cell.rowIndex)
          },
        })
      }
    })
    gridRef.current.removeEventListener('rendercell')
    gridRef.current.addEventListener('rendercell', (e) => {
      const cellHasError = hasError(
        e.value,
        e.cell.rowIndex,
        e.cell.columnIndex
      )
      if (cellHasError) {
        e.ctx.fillStyle = tinycolor(theme.palette.danger.main)
          .lighten(30)
          .toHexString()
      } else if (
        !cellHasError &&
        e.cell.rowIndex != -1 &&
        e.cell.columnIndex != -1
      ) {
        e.ctx.fillStyle = theme.palette.secondary.contrastText
      }
    })
  }, [])

  // On unmount
  useEffect(() => {
    const ref = gridRef.current
    return () => {
      if (ref) {
        ref.removeEventListener('endedit')
        ref.removeEventListener('rendercell')
        ref.removeEventListener('contextmenu')
        if (ref.input) {
          // Remove cell edit element
          ref.input.remove()
        }
        ref.dispose()
      }
    }
  }, [])

  return (
    <canvas-datagrid
      style={{
        width: '100%',
        height: '100%',
        editCellBackgroundColor: theme.palette.secondary.contrastText,
        gridBackgroundColor: theme.palette.secondary.contrastText,
        rowHeaderCellBackgroundColor: theme.palette.secondary.contrastText,
        columnHeaderCellBackgroundColor: theme.palette.secondary.contrastText,
        cellBorderColor: theme.palette.captGrey.light,
        columnHeaderCellBorderColor: theme.palette.captGrey.light,
        columnHeaderCellCapBorderColor: theme.palette.captGrey.light,
        columnHeaderCellCapBackgroundColor:
          theme.palette.secondary.contrastText,
        columnHeaderCellHoverBackgroundColor: theme.palette.background.light,
        rowHeaderCellHoverBackgroundColor: theme.palette.background.light,
        rowHeaderCellBorderColor: theme.palette.captGrey.light,
        rowHeaderCellSelectedColor: theme.palette.secondary.main,
        activeRowHeaderCellBackgroundColor: theme.palette.primary.background,
        activeColumnHeaderCellBackgroundColor: theme.palette.primary.background,
        activeHeaderCellBackgroundColor: theme.palette.primary.background,
        columnHeaderOrderByArrowColor: theme.palette.primary.main,
        activeCellOverlayBorderColor: theme.palette.primary.main,
        activeCellBorderColor: theme.palette.primary.main,
        activeCellSelectedBackgroundColor: theme.palette.background.light,
        cornerCellBackgroundColor: theme.palette.background.light,
        contextMenuBackground: theme.palette.background.light,
        contextMenuHoverColor: theme.palette.primary.main,
        contextMenuHoverBackground: theme.palette.primary.background,
        contextMenuArrowColor: theme.palette.primary.main,
        cellWhiteSpace: 'normal',
      }}
      ref={(ref) => {
        gridRef.current = ref
        if (parentRef) {
          parentRef.current = ref
        }
      }}
      editable
      multiLine
      autoResizeRows
      allowRowResizeFromCell
      allowColumnResizeFromCell
    />
  )
})

CanvasDatagrid.displayName = 'CanvasDatagrid'
export default CanvasDatagrid
