import React from 'react'
import { IconButton, Typography } from '@mui/material'
import tinycolor from 'tinycolor2'
import useStyles from './styles'
import { ReactComponent as CloseIcon } from '../../assets/Icons_Close.svg'

export default function NoticeBanner({
  message,
  IconElement,
  color,
  backgroundColor = null,
  onButtonClick,
}) {
  const classes = useStyles({ color })

  const computedBackgroundColor =
    backgroundColor || tinycolor(color).lighten(30).toHexString()

  return (
    <div
      className={classes.banner}
      style={{ backgroundColor: computedBackgroundColor }}
    >
      {IconElement ? (
        <IconElement
          className={[classes.iconFillColor, classes.preventShrink].join(' ')}
        />
      ) : (
        <></>
      )}
      <Typography variant="body2" className={classes.text}>
        {message}
      </Typography>
      {onButtonClick ? (
        <IconButton
          onClick={onButtonClick}
          size="small"
          className={classes.preventShrink}
        >
          <CloseIcon className={classes.iconFillColor} />
        </IconButton>
      ) : (
        <></>
      )}
    </div>
  )
}
