import React from 'react'

import { DropzoneArea } from 'material-ui-dropzone'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

export default function FilePicker({
  onChange,
  onDelete,
  initialFiles,
  acceptedFiles,
  filesLimit,
  maxFileSize,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <DropzoneArea
      onChange={onChange}
      acceptedFiles={acceptedFiles}
      onDelete={onDelete}
      filesLimit={filesLimit}
      dropzoneText={t('create-control-item-dialog.dropzoneText')}
      previewText=""
      maxFileSize={maxFileSize}
      initialFiles={initialFiles}
      alertSnackbarProps={{
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        autoHideDuration: 6000,
      }}
      showPreviewsInDropzone
      previewGridClasses={{
        container: classes.previewGridContainer,
        image: classes.previewGridImage,
      }}
      previewGridProps={{
        container: {
          alignContent: 'center',
        },
        item: {
          xs: 12,
        },
      }}
    />
  )
}
