import React, { useState } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  IconButton,
  Checkbox,
  Typography,
  List,
  ListItem,
  Link,
  Tooltip,
} from '@mui/material'
import useStyles from './styles'
import BaseDialog from '../BaseDialog'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import { ReactComponent as DeleteIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons-Download.svg'
import { useDataApi } from '../../context/DataApiContext'
import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function DocumentsDialog({
  open,
  onClose,
  chassis,
  documents,
  reloadDocuments,
  documentsSelectionState,
  setDocumentsSelectionState,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user } = useUserState()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [uploadSelectedFile, setUploadSelectedFile] = useState(null)

  const [loading, setLoading] = useState(false)

  const availableDocuments = documents.items.filter((doc) => doc.available)
  const selectionValues = Array.from(documentsSelectionState.values())
  const anySelected = selectionValues.some((state) => state)
  const allSelected =
    selectionValues.filter((state) => state).length ===
      availableDocuments.length && availableDocuments.length > 0

  const resetState = () => {
    setUploadSelectedFile(null)

    setLoading(false)
    reloadDocuments()
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onOpenDocument = (document) => {
    window.open(document.url, '_blank')
  }

  const uploadDocument = (document, type) => {
    let url
    setLoading(true)
    dataProvider.chassis.documents
      .requestUpload(chassis.id)
      .then((response) => {
        url = response.data.url
        return dataProvider.chassis.documents.upload(response, document)
      })
      .then(() =>
        dataProvider.chassis.documents.post(
          chassis.id,
          type,
          url,
          document.name
        )
      )
      .then(() => {
        setLoading(false)
        reloadDocuments()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const onUploadDocument = (type) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'application/pdf'

    input.onchange = (e) => {
      // Retrieve file reference
      const file = e.target.files[0]

      // Verify document name
      if (file.name.includes(chassis.nParc)) {
        uploadDocument(file, type)
      } else {
        setUploadSelectedFile({ file, type })
      }
    }

    input.click()
  }

  const setAllDocumentsSelected = () => {
    const isSelected = allSelected
    const tmpDocumets = new Map([])
    availableDocuments.forEach((element) => {
      tmpDocumets.set(element.type, !isSelected)
    })
    setDocumentsSelectionState(tmpDocumets)
  }

  const deleteDocuments = () => {
    setLoading(true)
    const types = []
    documentsSelectionState.forEach((value, type) => {
      if (value) {
        types.push(type)
      }
    })
    dataProvider.chassis.documents
      .deleteDocuments(chassis.id, types)
      .then(() => {
        setLoading(false)
        reloadDocuments()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const downloadDocuments = () => {
    setLoading(true)
    const ids = documents.items
      .map((document) => {
        const selected = documentsSelectionState.get(document.type)
        if (selected) {
          return document.id
        }
        return null
      })
      .filter((id) => id)

    dataProvider.chassis.documents
      .downloadDocuments(chassis.id, ids)
      .then((zipUrl) => {
        setLoading(false)
        setDocumentsSelectionState(new Map([]))
        window.open(zipUrl, '_blank')
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const baseLoading = loading || !documents || documents.loading
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      title={t('documents-dialog.title', {
        nChassis: (chassis || {}).nChassis,
        nParc: (chassis || {}).nParc,
      })}
      loading={baseLoading}
      hideActions
      classes={{ root: !baseLoading ? classes.noPadding : undefined }}
    >
      <BaseDialog
        open={!!uploadSelectedFile}
        onClose={() => {
          setUploadSelectedFile(null)
        }}
        onConfirm={() => {
          uploadDocument(uploadSelectedFile.file, uploadSelectedFile.type)
          setUploadSelectedFile(null)
        }}
        hideCloseButton
        openButtonTitle={t('common.continue')}
      >
        <Typography align="center">
          {t('documents-dialog.upload-document-confirmation-message')}
        </Typography>
      </BaseDialog>

      <Grid container direction="column" wrap="wrap">
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            className={classes.documentsListTitle}
          >
            <Grid item>
              <Checkbox
                onClick={setAllDocumentsSelected}
                checked={allSelected}
                disabled={availableDocuments.length <= 0}
              />
            </Grid>
            <Grid item>
              <Typography id="subtitle" align="left" variant="h6" noWrap>
                {t('documents-dialog.subtitle')}
              </Typography>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-end">
              {hasPermission(
                Permissions.PAGE_CHASSIS_DELETE_DOCUMENTS,
                user
              ) && (
                <Grid item>
                  <Tooltip title={t('documents-dialog.delete-documents-hint')}>
                    {/* Show tooltip when button is disabled */}
                    <div>
                      <IconButton
                        id="delete"
                        onClick={deleteDocuments}
                        className={classes.disabledIcon}
                        disabled={!anySelected}
                        size="large"
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Tooltip title={t('documents-dialog.download-documents-hint')}>
                  {/* Show tooltip when button is disabled */}
                  <div>
                    <IconButton
                      id="download"
                      onClick={downloadDocuments}
                      className={classes.disabledIcon}
                      disabled={!anySelected}
                      size="large"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <List className={classes.documentsList}>
            {documents.items.map((value) => (
              <ListItem
                key={[value.id, value.type].join('-')}
                className={classes.documentsListItem}
              >
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    style={{
                      visibility: value.available ? 'visible' : 'hidden',
                    }}
                  >
                    <Checkbox
                      onClick={() => {
                        const tmpDocuments = new Map(documentsSelectionState)
                        tmpDocuments.set(
                          value.type,
                          !documentsSelectionState.get(value.type)
                        )
                        setDocumentsSelectionState(tmpDocuments)
                      }}
                      checked={documentsSelectionState.get(value.type) === true}
                    />
                  </Grid>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography id="subtitle" align="left" variant="body1">
                        {value.typeName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        <Link
                          id="subtitle"
                          align="left"
                          variant="body2"
                          onClick={() => {
                            if (value.originalName) {
                              onOpenDocument(value)
                            } else if (
                              hasPermission(
                                Permissions.PAGE_CHASSIS_ADD_DOCUMENTS,
                                user
                              )
                            ) {
                              onUploadDocument(value.type)
                            }
                          }}
                        >
                          {(() => {
                            const addText = hasPermission(
                              Permissions.PAGE_CHASSIS_ADD_DOCUMENTS,
                              user
                            )
                              ? t('documents-dialog.add-document')
                              : null
                            return value.originalName
                              ? value.originalName
                              : addText
                          })()}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
