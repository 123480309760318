import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function ModifyMemberDialog({
  open,
  onClose,
  onModified,
  memberToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [chassis, setChassis] = useState([])

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    if (memberToModify && memberToModify.chassis) {
      setName(memberToModify.name)
      setChassis(
        memberToModify.chassis.map((item) => ({
          id: item.id,
          title: item.nChassis,
        }))
      )
    }
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (chassis.count === 0) {
      setError('chassisIds_required')
      return
    }

    setLoading(true)
    const memberId = memberToModify.id
    dataProvider.member
      .put(
        memberId,
        name,
        chassis.map((item) => item.id)
      )
      .then(() => {
        dataProvider.cache.reset()
        onModified()
        showNotification('success', t('modify-member-dialog.success'))
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'MEMBER_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('modify-member-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    if (memberToModify && memberToModify.chassis) {
      setName(memberToModify.name)
      setChassis(
        memberToModify.chassis.map((item) => ({
          id: item.id,
          title: item.nChassis,
        }))
      )
    }
  }, [memberToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-member-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-member-dialog.member-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setChassis}
            value={chassis}
            label={t('create-member-dialog.chassis')}
            resource="/members/autocomplete-chassis"
            error={error === 'chassisIds_required'}
            multiple
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
