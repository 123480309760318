function autocompleteEmails(httpClient) {
  return httpClient({
    url: '/email-autocomplete',
    method: 'GET',
  })
}

function autocompleteEmailLists(query, httpClient) {
  return httpClient({
    url: `/emails/lists/autocomplete?q=${query}`,
    method: 'GET',
  })
}

export default {
  autocompleteEmails,
  autocompleteEmailLists,
}
