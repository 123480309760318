import React from 'react'

// components
import { Button, Typography, Grid } from '@mui/material'
import dateFnsFormat from 'date-fns/format'
import enums from '../../utils/enums'
import { hasPermission } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function generate({
  t,
  classes,
  tableData,
  onControlClick,
  onExportPvAdmission,
  user,
}) {
  return [
    {
      name: 'controlNumber',
      label: t('common.number'),
    },
    {
      name: 'controlDate',
      label: t('controls-list.controlDate'),
      options: {
        customBodyRender: (val, tableMeta) => {
          const formatedDate = dateFnsFormat(Date.parse(val), 'dd/MM/yyyy')
          switch (tableData.items[tableMeta.rowIndex].conformity) {
            case enums.CONFORMITY.INVALID:
              return <Typography color="error">{formatedDate}</Typography>
            case enums.CONFORMITY.VALID:
              return (
                <Typography className={classes.greenText}>
                  {formatedDate}
                </Typography>
              )
            case enums.CONFORMITY.WAITING_CONTROL:
              return (
                <Typography className={classes.orangeText}>
                  {formatedDate}
                </Typography>
              )
            default:
              return <Typography>{formatedDate}</Typography>
          }
        },
      },
    },
    {
      name: 'chassis.nChassis',
      label: t('common.chassis'),
    },
    {
      name: 'chassis.vehicleType.title',
      label: t('common.vehicleType'),
    },
    {
      name: 'conformity',
      label: t('common.conformity'),
      options: {
        sort: true,
        customBodyRender: (val) => {
          switch (val) {
            case enums.CONFORMITY.INVALID:
              return t('conformity.invalid')
            case enums.CONFORMITY.VALID:
              return t('conformity.valid')
            case enums.CONFORMITY.AWAITING_CONTROL:
              return t('conformity.waiting-result')
            default:
              return t('errors.not-valid')
          }
        },
      },
    },
    {
      name: 'chassis.location',
      label: t('common.location'),
    },
    {
      name: 'chassis.nParc',
      label: t('common.nParc'),
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Grid container direction="column">
            <Grid item>
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() =>
                  onControlClick(tableData.items[tableMeta.rowIndex].id)
                }
                color="primary"
              >
                {t('common.see')}
              </Button>
            </Grid>
            {hasPermission(
              Permissions.PAGE_CONTROLS_ACCESS_PV_ADMISSION,
              user
            ) &&
              tableData.items[tableMeta.rowIndex].conformity ===
                enums.CONFORMITY.VALID && (
                <Grid item>
                  <Button
                    className={classes.linkButton}
                    variant="text"
                    onClick={() => onExportPvAdmission(tableMeta.rowIndex)}
                    color="primary"
                  >
                    {t('common.pvAdmission')}
                  </Button>
                </Grid>
              )}
          </Grid>
        ),
      },
    },
  ]
}
