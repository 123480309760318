import React from 'react'

// components
import { Box, Checkbox } from '@mui/material'

export default function generate({
  t,
  classes,
  permissionsData,
  roles = [],
  onSelect,
}) {
  const columns = [
    {
      name: 'title',
      label: t('common.functionalities'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <>
            {permissionsData[tableMeta.rowIndex].isGroup ? (
              <Box fontSize="14px" fontWeight="fontWeightBold">
                {permissionsData[tableMeta.rowIndex].title}
              </Box>
            ) : (
              <Box fontSize="14px" fontWeight="fontWeightRegular">
                {permissionsData[tableMeta.rowIndex].title}
              </Box>
            )}
          </>
        ),
      },
    },
  ]
  roles.forEach((role) => {
    columns.push({
      name: 'role',
      label: role.label,
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Checkbox
            className={classes.Checkbox}
            variant="text"
            checked={
              permissionsData[tableMeta.rowIndex].selectedRoles.includes(
                role.key
              ) === true
            }
            // eslint-disable-next-line max-len
            onClick={() =>
              onSelect(
                tableMeta.rowIndex,
                tableMeta.columnIndex,
                permissionsData[tableMeta.rowIndex].selectedRoles.includes(
                  role.key
                ) === true
              )
            }
            color="primary"
          />
        ),
      },
    })
  })
  return columns
}
