import React from 'react'

import { Menu } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { styled } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const StyledMUIDataTable = styled(MUIDataTable, {
  shouldForwardProp: (prop) => prop !== 'clickableRow',
})(({ clickableRow }) => ({
  ...(clickableRow && {
    '& .MuiTableRow-hover': {
      cursor: 'pointer',
    },
  }),
}))

const Table = ({
  id,
  columns,
  data,
  page,
  sort,
  onRowClick,
  onChangePage,
  onColumnSortChange,
  pagination,
  selectableRows,
  selectToolbarPlacement,
  rowsSelected,
  onRowSelectionChange,
  menuItems,
  onCellClick,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const sortOrder = sort
    ? { name: sort.field, direction: sort.direction.toLowerCase() }
    : {}

  const [contextMenu, setContextMenu] = React.useState(null)

  const handleContextMenu = (event, rowIndex) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            rowIndex,
          }
        : null
    )
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  const createMenuItemComponents = (rowIndex) =>
    menuItems(rowIndex).map((component) => {
      const originalOnClick = component.props.onClick
      return React.cloneElement(component, {
        onClick: () => {
          handleClose()
          if (originalOnClick) {
            originalOnClick()
          }
        },
      })
    })

  return (
    data && (
      <>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          disableAutoFocusItem
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {contextMenu && createMenuItemComponents(contextMenu.rowIndex)}
        </Menu>
        <StyledMUIDataTable
          clickableRow={!!onRowClick}
          key={id}
          data={data.items}
          columns={columns}
          options={{
            enableNestedDataAccess: '.',
            page,
            filter: false,
            print: false,
            search: false,
            viewColumns: false,
            download: false,
            selectableRows: selectableRows || 'none',
            selectToolbarPlacement: selectToolbarPlacement || 'none',
            selectableRowsHeader: data && data.itemCount > 0,
            rowsSelected,
            onRowSelectionChange,
            rowsPerPage: data.range.limit,
            rowsPerPageOptions: [],
            count: data.itemCount,
            serverSide: true,
            sortOrder,
            onChangePage,
            onColumnSortChange,
            pagination,
            onRowClick,
            onCellClick,
            setRowProps: menuItems
              ? (row, dataIndex) => ({
                  onContextMenu: (event) => handleContextMenu(event, dataIndex),
                })
              : undefined,
            textLabels: {
              body: {
                noMatch: t('tables.body.noMatch'),
                toolTip: t('tables.body.toolTip'),
              },
              pagination: {
                next: t('tables.pagination.next'),
                previous: t('tables.pagination.previous'),
                rowsPerPage: t('tables.pagination.rowsPerPage'),
                displayRows: t('tables.pagination.displayRows'),
              },
              toolbar: {
                search: t('tables.toolbar.search'),
                downloadCsv: t('tables.toolbar.downloadCsv'),
                print: t('tables.toolbar.print'),
                viewColumns: t('tables.toolbar.viewColumns'),
                filterTable: t('tables.toolbar.filterTable'),
              },
              filter: {
                all: t('tables.filter.all'),
                title: t('tables.filter.title'),
                reset: t('tables.filter.reset'),
              },
              viewColumns: {
                title: t('tables.viewColumns.title'),
                titleAria: t('tables.viewColumns.titleAria'),
              },
              selectedRows: {
                text: t('tables.selectedRows.text'),
                delete: t('tables.selectedRows.delete'),
                deleteAria: t('tables.selectedRows.deleteAria'),
              },
            },
          }}
          className={classes.table}
        />
      </>
    )
  )
}

export default Table
