import React from 'react'

// components
import { Button } from '@mui/material'

export default function generate({
  t,
  classes,
  onModify,
  onDelete,
  hasDeletePermission,
}) {
  return [
    {
      name: 'firstName',
      label: t('contractors-list.first-name-table-header'),
    },
    {
      name: 'lastName',
      label: t('contractors-list.last-name-table-header'),
    },
    {
      name: 'email',
      label: t('contractors-list.email-table-header'),
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) =>
          hasDeletePermission && (
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onDelete(tableMeta.rowIndex)}
              color="primary"
            >
              {t('common.delete')}
            </Button>
          ),
      },
    },
  ]
}
