import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
} from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import {
  useUserState,
  useUserDispatch,
  refreshUser,
  hasPermission,
} from '../../context/user/UserContext'
import useStyles from './styles'

import Table from '../../components/Table'
import ModifyUserDialog from '../../components/Users/components/ModifyUserDialog'
import CreateUserDialog from '../../components/Users/components/CreateUserDialog'
import ModifyUserPasswordDialog from '../../components/Users/components/ModifyUserPasswordDialog'
import generateColumns from './columns'

import { ReactComponent as ModifyIcon } from '../../assets/Icons_Edit.svg'
import { ReactComponent as ActivateIcon } from '../../assets/Icons_Check_Grey.svg'
import { ReactComponent as DeactivateIcon } from '../../assets/Icons_Close_Grey.svg'
import { ReactComponent as DeleteIcon } from '../../assets/Icons_Delete_Grey.svg'

import { Permissions } from '../../entities/permissions'

export default function UsersList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)

  const [openModifyUserDialog, setOpenModifyUserDialog] = useState(false)
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false)
  const [userToModify, setUserToModify] = useState(false)
  const [openModifyUserPasswordDialog, setOpenModifyUserPasswordDialog] =
    useState(false)

  const userDispatch = useUserDispatch()
  const { user } = useUserState()

  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'users'

  useEffect(() => {
    dataProvider
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        null,
        false
      )
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onMoreActionsMenuClose = () => {
    setMoreActionsAnchorEl({
      ...moreActionsAnchorEl,
      anchorEl: null,
      items: [],
    })
  }

  const onRequestNewPassword = (rowIndex) => {
    const { id, firstName } = tableData.items[rowIndex]
    if (id === user.id) {
      setOpenModifyUserPasswordDialog(true)
    } else {
      showConfirmationDialog(
        t('users-list.confirm-new-password-request', { firstName }),
        () => {
          dataProvider
            .post('users/resend-credentials', { data: { id } })
            .then(() => {
              refreshData()
              showNotification(
                'success',
                t('users-list.new-password-request-success', { firstName })
              )
            })
            .catch(() => showNotification('error', t('errors.api-error')))
        }
      )
    }
  }

  const onModify = () => {
    onMoreActionsMenuClose()
    setOpenModifyUserDialog(true)
  }

  const onDeactivate = (rowIndex) => {
    onMoreActionsMenuClose()
    const { id, firstName } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('users-list.confirm-user-deactivation', { firstName }),
      () => {
        dataProvider
          .post('users/disable', { data: { id } })
          .then(() => {
            refreshData()
            showNotification('success', t('users-list.deactivation-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      }
    )
  }

  const onActivate = (rowIndex) => {
    onMoreActionsMenuClose()
    const { id, firstName } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('users-list.confirm-user-activation', { firstName }),
      () => {
        dataProvider
          .post('users/enable', { data: { id } })
          .then(() => {
            refreshData()
            showNotification('success', t('users-list.activation-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      }
    )
  }

  const onDelete = (rowIndex) => {
    onMoreActionsMenuClose()
    const { id, firstName } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('users-list.confirm-user-deletion', { firstName }),
      () => {
        dataProvider
          .delete('users', { id })
          .then(() => {
            refreshData()
            showNotification('success', t('users-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      }
    )
  }

  const onMoreActions = (currentTarget, rowIndex) => {
    const rowUser = tableData.items[rowIndex]
    setUserToModify(rowUser)
    const canChangeState = hasPermission(
      Permissions.PAGE_USERS_MANAGE_USER_STATE,
      user
    )
    const canDelete = hasPermission(Permissions.PAGE_USERS_DELETE_USER, user)
    const menuItems = []

    menuItems.push({
      title: t('users-list.modify-user'),
      icon: 'ModifyIcon',
      onClick: () => {
        onModify()
      },
    })

    if (canChangeState) {
      if (rowUser.isActive) {
        menuItems.push({
          title: t('users-list.deactivate-user'),
          icon: 'DeactivateIcon',
          onClick: () => {
            onDeactivate(rowIndex)
          },
        })
      } else {
        menuItems.push({
          title: t('users-list.activate-user'),
          icon: 'ActivateIcon',
          onClick: () => {
            onActivate(rowIndex)
          },
        })
      }
    }

    if (canDelete) {
      menuItems.push({
        title: t('users-list.delete-user'),
        icon: 'DeleteIcon',
        onClick: () => {
          onDelete(rowIndex)
        },
      })
    }

    setMoreActionsAnchorEl({
      ...moreActionsAnchorEl,
      anchorEl: currentTarget,
      items: menuItems,
    })
  }

  const tableColumns = generateColumns({
    t,
    classes,
    onMoreActions,
    onRequestNewPassword,
    user,
    tableData,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateUserDialog
        title={t('create-user-dialog.title')}
        open={openCreateUserDialog}
        onClose={() => setOpenCreateUserDialog(false)}
        onCreated={() => {
          setOpenCreateUserDialog(false)
          refreshData()
        }}
      />
      <ModifyUserDialog
        open={openModifyUserDialog}
        onClose={() => setOpenModifyUserDialog(false)}
        onModified={() => {
          setOpenModifyUserDialog(false)
          if (user.id === userToModify.id) {
            refreshUser(userDispatch)
          } else {
            refreshData(true)
          }
        }}
        title={t('users-list.modify-user')}
        userToModify={userToModify}
      />
      <ModifyUserPasswordDialog
        open={openModifyUserPasswordDialog}
        onClose={() => setOpenModifyUserPasswordDialog(false)}
      />
      <Menu
        keepMounted
        anchorEl={moreActionsAnchorEl.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onMoreActionsMenuClose}
        open={Boolean(moreActionsAnchorEl.anchorEl)}
      >
        {moreActionsAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {' '}
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              className={classes.menuItemRow}
            >
              <Grid item>
                {item.icon === 'ModifyIcon' && <ModifyIcon id="icon" />}
                {item.icon === 'DeactivateIcon' && (
                  <DeactivateIcon id="icon" className={classes.icon} />
                )}
                {item.icon === 'ActivateIcon' && <ActivateIcon id="icon" />}
                {item.icon === 'DeleteIcon' && <DeleteIcon id="icon" />}
                <span id="text">{item.title}</span>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('users-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateUserDialog(true)}
          >
            {t('users-list.add-user')}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>
    </>
  )
}
