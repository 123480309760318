import React, { useState, useEffect, useMemo } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  useTheme,
  useMediaQuery,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { useLocation } from 'react-router-dom'
import NativeSelect from '@mui/material/NativeSelect'
import { useQueryState } from '../../utils/query-state'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import CreateControlDialog from '../../components/Control/components/CreateControlDialog'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import CreateChassisDialog from '../../components/Chassis/components/CreateChassisDialog'
import ModifyChassisDialog from '../../components/Chassis/components/ModifyChassisDialog'
import OverrideValidationChassisDialog from '../../components/Chassis/components/OverrideValidationChassisDialog'
import DeleteChassisDialog from '../../components/Chassis/components/DeleteChassisDialog'
import DocumentsDialog from '../../components/Documents'
import enums from '../../utils/enums'
import generateColumns from './columns'

import { ReactComponent as HTMLIcon } from '../../assets/Icons_Html.svg'
import { ReactComponent as XLSIcon } from '../../assets/Icons_Xls.svg'
import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'
import { ReactComponent as DeleteIcon } from '../../assets/icons-Delete.svg'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function ChassisList({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))

  const query = useQuery()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { user } = useUserState()
  const hasPermissionToOverrideValidation = useMemo(
    () => hasPermission(Permissions.PAGE_CHASSIS_OVERRIDE_VALIDATION, user),
    [user]
  )
  const hasPermissionToDeleteChassis = useMemo(
    () => hasPermission(Permissions.PAGE_CHASSIS_DELETE_CHASSIS, user),
    [user]
  )

  const [openCreateControlDialog, setOpenCreateControlDialog] = useState(false)
  const [openCreateChassisDialog, setOpenCreateChassisDialog] = useState(false)
  const [openModifyChassisDialog, setOpenModifyChassisDialog] = useState(false)
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false)
  const [overrideValidationChassisDialog, setOverrideValidationChassisDialog] =
    useState({
      open: false,
      chassisIds: [],
    })
  const [
    deleteWaitingResultControlsDialog,
    setDeleteWaitingResultControlsDialog,
  ] = useState({
    open: false,
    chassisIds: [],
  })

  const [depot, setDepot] = useQueryState({
    paramName: 'depot',
    history,
    query,
  })
  const [operateur, setOperator] = useQueryState({
    paramName: 'operator',
    history,
    query,
  })
  const [vehicleType, setVehicleType] = useQueryState({
    paramName: 'vehicleType',
    history,
    query,
  })
  const [nChassis, setNChassis] = useQueryState({
    paramName: 'nChassis',
    history,
    query,
  })
  const [location, setLocation] = useQueryState({
    paramName: 'location',
    history,
    query,
  })
  const [conformity, setConformity] = useQueryState({
    paramName: 'conformity',
    defaultValue: '',
    history,
    query,
  })
  const [nParc, setNParc] = useQueryState({
    paramName: 'nParc',
    history,
    query,
  })
  const [submittedByUser, setSubmittedByUser] = useQueryState({
    paramName: 'submittedByUser',
    history,
    query,
  })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      depot: depot ? depot.id : null,
      operateur: operateur ? operateur.id : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      id: nChassis ? nChassis.id : null,
      conformity: enums.CONFORMITY.isValid(conformity) ? conformity : null,
      location: location ? location.id : null,
      nParc: nParc ? nParc.title : null,
      submittedByUserId: submittedByUser ? submittedByUser.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key]
    )
    return filtersObj
  }, [
    depot,
    operateur,
    vehicleType,
    nChassis,
    location,
    conformity,
    nParc,
    submittedByUser,
  ])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({
    paramName: 'sort',
    history,
    query,
  })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page',
    defaultValue: 0,
    history,
    query,
  })
  const [tableState, setTableState] = useState({
    page: tablePage,
    sort: tableSort,
    filters: computedFilters,
    rowsSelected: [],
  })
  const [tableData, setTableData] = useState(null)

  const [selectedControl, setSelectedControl] = useState(null)
  const [controlDateAnchorEl, setControlDateAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  const [selectedChassis, setSelectedChassis] = useState(null)
  const [selectedChassisDocuments, setSelectedChassisDocuments] = useState({
    items: [],
    loading: true,
  })
  // eslint-disable-next-line max-len
  const [
    selectedChassisDocumentsSelectionState,
    setSelectedChassisDocumentsSelectionState,
  ] = useState(new Map())
  const [transientDocumentCount, setTransientDocumentCount] = useState({})
  const [chassisToModify, setChassisToModify] = useState(null)

  const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState({
    anchorEl: null,
    items: [],
  })

  const updateFilters = () => {
    setTableState({
      ...tableState,
      page: 0,
      filters: computedFilters,
      rowsSelected: [],
    })
  }

  const clearFilters = () => {
    setDepot(null)
    setOperator(null)
    setVehicleType(null)
    setNChassis(null)
    setLocation(null)
    setConformity('')
    setNParc(null)
    setSubmittedByUser(null)
    setTableState({
      ...tableState,
      page: 0,
      filters: null,
      rowsSelected: [],
    })
  }

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({
        page: tableState.page,
        sort: null,
        filters: null,
        rowsSelected: [],
      })
    } else {
      setTableState({
        page: 0,
        sort: null,
        filters: null,
        rowsSelected: [],
      })
    }
  }

  useEffect(() => {
    dataProvider
      .getList('chassis', {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      })
      .then((response) => {
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  useEffect(() => {
    if (!selectedChassis || loading) {
      return
    }

    setSelectedChassisDocuments({ ...selectedChassisDocuments, loading: true })
    setSelectedChassisDocumentsSelectionState(new Map())
    dataProvider.chassis.documents
      .get(selectedChassis.id)
      .then((response) => {
        setSelectedChassisDocuments({ items: response.data, loading: false })
        const availableDocumentsCount = response.data.filter(
          (doc) => doc.available
        ).length
        transientDocumentCount[selectedChassis.id] = availableDocumentsCount
        setTransientDocumentCount({ ...transientDocumentCount })
        dataProvider.cache.reset()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [selectedChassis])

  const onExportHtml = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'chassis',
      tableState,
      'html'
    )
    window.open(exportUrl, '_blank')
  }

  const onExportCsv = () => {
    const exportUrl = dataProvider.generateExportUrl(
      'chassis',
      tableState,
      'csv'
    )
    window.open(exportUrl, '_blank')
  }

  const onSeeControlDetails = (controlId) => {
    setControlDateAnchorEl({
      ...controlDateAnchorEl,
      anchorEl: null,
    })
    history.push(`/controls/detail/${controlId}`)
  }

  const onSeeChassisDetails = (rowIndex) => {
    const chassisId = tableData.items[rowIndex].id
    history.push(`/chassis/detail/${chassisId}`)
  }

  const onChangeControlDate = (rowIdx, control) => {
    setControlDateAnchorEl({
      ...controlDateAnchorEl,
      anchorEl: null,
    })
    setSelectedControl({
      controlId: control.id,
      controlDate: control.controlDate,
      nParc: tableData.items[rowIdx].nParc,
    })
  }

  const onControlDateClicked = (anchorEl, rowIndex, control) => {
    const canSeeDetails = hasPermission(
      Permissions.PAGE_CHASSIS_SEE_CHASSIS_CONTROL_DETAILS,
      user
    )
    const canChangeControlDate = hasPermission(
      Permissions.PAGE_CHASSIS_MODIFY_CHASSIS_CONTROL_DATE,
      user
    )
    const menuItems = []

    if (
      canChangeControlDate &&
      control.conformity === enums.CONFORMITY.AWAITING_CONTROL
    ) {
      menuItems.push({
        title: 'Change date',
        onClick: () => {
          onChangeControlDate(rowIndex, control)
        },
      })
    }
    if (canSeeDetails) {
      menuItems.push({
        title: 'See details',
        onClick: () => {
          onSeeControlDetails(control.id)
        },
      })
    }

    if (menuItems.length === 1) {
      menuItems[0].onClick()
    } else if (menuItems.length > 1) {
      setControlDateAnchorEl({
        ...controlDateAnchorEl,
        anchorEl,
        items: menuItems,
      })
    }
  }

  const onDeleteButtonClick = (event) => {
    event.stopPropagation()

    const chassisIds = tableState.rowsSelected.map(
      (idx) => tableData.items[idx].id
    )
    setDeleteWaitingResultControlsDialog({
      chassisIds,
      open: true,
    })
  }

  const performValidationOverride = () => {
    const chassisIds = tableState.rowsSelected.map(
      (idx) => tableData.items[idx].id
    )

    setOverrideValidationChassisDialog(() => ({
      chassisIds,
      open: true,
    }))
  }

  const onMoreActionsMenuClose = () => {
    setMoreActionsAnchorEl({
      ...moreActionsAnchorEl,
      anchorEl: null,
      items: [],
    })
  }

  const onDocumentsClicked = (rowChassis) => {
    onMoreActionsMenuClose()
    setSelectedChassisDocuments({ items: [], loading: false })
    setSelectedChassisDocumentsSelectionState(new Map())
    setSelectedChassis(rowChassis)
    setOpenDocumentsDialog(true)
  }

  const onMoreActions = (currentTarget, rowIndex) => {
    const rowChassis = tableData.items[rowIndex]
    const menuItems = []

    if (hasPermission(Permissions.PAGE_CHASSIS_ADD_DOCUMENTS, user)) {
      menuItems.push({
        title: t('chassis-list.show-documents'),
        onClick: () => {
          onDocumentsClicked(rowChassis)
        },
      })
    }
    menuItems.push({
      title: t('chassis-list.add-control'),
      onClick: () => {
        onMoreActionsMenuClose()
        setSelectedChassis(rowChassis)
        setOpenCreateControlDialog(true)
      },
    })

    setMoreActionsAnchorEl({
      ...moreActionsAnchorEl,
      anchorEl: currentTarget,
      items: menuItems,
    })
  }

  const tableColumns = generateColumns({
    t,
    classes,
    onControlDateClicked,
    onMoreActions,
    computeDocumentCount: (idx) => {
      const chassis = tableData.items[idx]
      const transientCount = transientDocumentCount[chassis.id]
      if (typeof transientCount !== 'undefined') {
        return transientCount
      }
      if (chassis.documents && chassis.documents.length > 0) {
        return chassis.documents.length
      }
      return false
    },
    user,
    chassis: tableData,
    onModifyChassis: (idx) => {
      setChassisToModify(tableData.items[idx])
      setOpenModifyChassisDialog(true)
    },
  })

  const onControlDateMenuClose = () => {
    setControlDateAnchorEl({
      anchorEl: null,
      items: [],
    })
  }

  if (loading) {
    return <Loading />
  }
  return (
    <Grid container direction="column">
      <Menu
        id="control-date-menu"
        anchorEl={controlDateAnchorEl.anchorEl}
        keepMounted
        open={Boolean(controlDateAnchorEl.anchorEl)}
        onClose={onControlDateMenuClose}
      >
        {controlDateAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        keepMounted
        anchorEl={moreActionsAnchorEl.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onMoreActionsMenuClose}
        open={Boolean(moreActionsAnchorEl.anchorEl)}
      >
        {moreActionsAnchorEl.items.map((item) => (
          <MenuItem key={item.title} onClick={item.onClick}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <CreateChassisDialog
        open={openCreateChassisDialog}
        onClose={() => setOpenCreateChassisDialog(false)}
        onCreated={() => {
          refreshData()
          setOpenCreateChassisDialog(false)
        }}
      />
      <ModifyChassisDialog
        open={openModifyChassisDialog}
        onClose={() => {
          setSelectedControl(null)
          setOpenModifyChassisDialog(false)
        }}
        onCreated={() => {
          refreshData()
          setOpenModifyChassisDialog(false)
        }}
        chassisToModify={chassisToModify}
      />
      <OverrideValidationChassisDialog
        open={overrideValidationChassisDialog.open}
        onClose={() => {
          setOverrideValidationChassisDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        onSuccess={() => {
          refreshData(true)
          setOverrideValidationChassisDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        chassisIds={overrideValidationChassisDialog.chassisIds}
      />
      <DeleteChassisDialog
        open={deleteWaitingResultControlsDialog.open}
        onClose={() => {
          setDeleteWaitingResultControlsDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        onSuccess={() => {
          refreshData(true)
          setDeleteWaitingResultControlsDialog((state) => ({
            ...state,
            open: false,
          }))
        }}
        chassisIds={deleteWaitingResultControlsDialog.chassisIds}
      />
      <CreateControlDialog
        open={openCreateControlDialog}
        onClose={() => {
          setSelectedChassis(null)
          setOpenCreateControlDialog(false)
        }}
        onCreated={() => {
          refreshData()
          setSelectedChassis(null)
          setOpenCreateControlDialog(false)
        }}
        selectedChassis={selectedChassis}
      />
      <CreateControlDialog
        open={!!selectedControl}
        onClose={() => setSelectedControl(null)}
        onCreated={() => {
          refreshData()
          setSelectedControl(null)
        }}
        selectedControl={selectedControl}
      />
      <DocumentsDialog
        open={openDocumentsDialog}
        chassis={selectedChassis}
        onClose={() => {
          setSelectedChassis(null)
          setOpenDocumentsDialog(false)
        }}
        onCreated={() => {
          refreshData()
          setSelectedChassis(null)
          setOpenDocumentsDialog(false)
        }}
        documents={selectedChassisDocuments}
        documentsSelectionState={selectedChassisDocumentsSelectionState}
        setDocumentsSelectionState={setSelectedChassisDocumentsSelectionState}
        reloadDocuments={() => {
          setSelectedChassis({ ...selectedChassis })
        }}
      />
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <PageTitle title={t('chassis-list.title')} />
          </Grid>
          <Grid container item xs={6} spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                startIcon={<HTMLIcon color="primary" />}
                color="primary"
                variant="contained"
                fullWidth
                onClick={onExportHtml}
              >
                {t('common.export-pdf')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<XLSIcon color="primary" />}
                color="primary"
                variant="contained"
                fullWidth
                onClick={onExportCsv}
              >
                {t('common.export-csv')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.filtersContainer}>
        <Grid container spacing={4} direction="column">
          <Grid item>
            <Typography
              variant="h6"
              className={classes.filterContainerTitleText}
            >
              {t('common.filterZone')}
            </Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent="flex-start"
              spacing={1}
              wrap="wrap"
              direction="row"
            >
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setDepot}
                  label={t('common.depot')}
                  resource="chassis/depot-autocomplete"
                  value={depot}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setOperator}
                  label={t('common.operateur')}
                  resource="chassis/operateur-autocomplete"
                  value={operateur}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setVehicleType}
                  label={t('common.vehicleType')}
                  resource="vehicle-type/autocomplete"
                  value={vehicleType}
                  query={{ includeDisabled: true }}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl variant="standard" className={classes.selector}>
                  <InputLabel
                    id="conformity-label"
                    shrink={enums.CONFORMITY.isValid(conformity)}
                  >
                    {t('common.conformity')}
                  </InputLabel>
                  <NativeSelect
                    labelid="conformity-label"
                    inputProps={{
                      name: t('common.conformity'),
                      id: 'conformity',
                    }}
                    onChange={(event) => {
                      setConformity(event.target.value)
                    }}
                    value={conformity}
                  >
                    <option aria-label="None" value="" />
                    <option value={enums.CONFORMITY.VALID}>
                      {t('conformity.valid')}
                    </option>
                    <option value={enums.CONFORMITY.INVALID}>
                      {t('conformity.invalid')}
                    </option>
                    <option value={enums.CONFORMITY.AWAITING_CONTROL}>
                      {t('conformity.waiting-result')}
                    </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setNChassis}
                  label={t('common.chassis')}
                  resource="chassis/chassis-number-autocomplete"
                  value={nChassis}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setLocation}
                  label={t('common.location')}
                  resource="chassis/location-autocomplete"
                  value={location}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setNParc}
                  label={t('common.nParc')}
                  resource="chassis/parc-number-autocomplete"
                  value={nParc}
                  canDelete
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AutoCompleteInput
                  onChange={setSubmittedByUser}
                  label={t('common.submitted-by-user')}
                  resource="chassis/user-autocomplete"
                  value={submittedByUser}
                  canDelete
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              className={classes.actionsContainer}
            >
              <Grid item xs={12} md={2} xl={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={clearFilters}
                >
                  {t('common.clean')}
                </Button>
              </Grid>
              <Grid item xs={12} md={2} xl={1}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={updateFilters}
                >
                  {t('common.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Grid item>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {hasPermissionToDeleteChassis && (
                <Grid item>
                  <IconButton
                    className={[
                      classes.disabledButton,
                      classes.menuItemRow,
                    ].join(' ')}
                    onClick={onDeleteButtonClick}
                    disabled={
                      tableState &&
                      tableState.rowsSelected &&
                      tableState.rowsSelected.length === 0
                    }
                    size="large"
                  >
                    <DeleteIcon id="icon" />
                  </IconButton>
                </Grid>
              )}
              {hasPermissionToOverrideValidation && (
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth={fullWidth}
                    className={[classes.allItemsButton].join(' ')}
                    onClick={() => performValidationOverride()}
                    disabled={
                      tableState &&
                      tableState.rowsSelected &&
                      tableState.rowsSelected.length === 0
                    }
                  >
                    <span id="text">
                      {t('chassis-list.validation-override')}
                    </span>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {hasPermission(Permissions.PAGE_CHASSIS_ADD_CHASSIS, user) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    fullWidth={fullWidth}
                    onClick={() => setOpenCreateChassisDialog(true)}
                  >
                    {t('chassis-list.add-chassis')}
                  </Button>
                </Grid>
              )}
              {hasPermission(Permissions.PAGE_CHASSIS_ADD_CONTROL, user) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    fullWidth={fullWidth}
                    onClick={() => setOpenCreateControlDialog(true)}
                  >
                    {t('chassis-list.add-control')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer} xs={12}>
        <Table
          id="chassis-list"
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onRowClick={(_, cellMeta) => {
            onSeeChassisDetails(cellMeta.rowIndex)
          }}
          selectableRows={
            (hasPermissionToDeleteChassis && 'multiple') ||
            (hasPermissionToOverrideValidation && 'multiple')
          }
          onChangePage={(currentPage) => {
            setTableState({
              ...tableState,
              page: currentPage,
              rowsSelected: [],
            })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort, rowsSelected: [] })
          }}
          rowsSelected={
            (hasPermissionToDeleteChassis ||
              hasPermissionToOverrideValidation) &&
            tableState.rowsSelected
          }
          onRowSelectionChange={
            (hasPermissionToDeleteChassis ||
              hasPermissionToOverrideValidation) &&
            ((rowsSelected, allRows) => {
              setTableState((state) => ({
                ...state,
                rowsSelected: allRows.map((row) => row.dataIndex),
              }))
            })
          }
        />
      </Grid>
    </Grid>
  )
}
