import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import { Grid, Button, useMediaQuery, useTheme } from '@mui/material'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'
// import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import useStyles from './styles'

// eslint-disable-next-line max-len
import CreateOperatorDialog from '../../components/Operators/components/CreateOperatorDialog'
// eslint-disable-next-line max-len
import ModifyOperatorDialog from '../../components/Operators/components/ModifyOperatorDialog'
import Table from '../../components/Table'
import generateColumns from './columns'

export default function OperatorsList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [tableData, setTableData] = useState(null)
  const [openCreateOperatorDialog, setOpenCreateOperatorDialog] =
    useState(false)
  const [openModifyOperatorDialog, setOpenModifyOperatorDialog] =
    useState(false)
  const [operatorToModify, setOperatorToModify] = useState(false)

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ page: tableState.page, sort: null, filters: null })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'operators'

  useEffect(() => {
    dataProvider.operator
      .getList(
        resource,
        {
          range: { page: tableState.page + 1 },
          filters: tableState.filters,
          sort: tableState.sort,
        },
        false
      )
      .then((response) => {
        setItems(response.data.items)
        setTableData(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModify = (rowIndex) => {
    setOperatorToModify(tableData.items[rowIndex])
    setOpenModifyOperatorDialog(true)
  }

  const onDelete = (rowIndex) => {
    const { id, name } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('operators-list.confirm-operator-deletion', {
        name,
      }),
      () => {
        dataProvider
          .delete(`operators`, { id })
          .then(() => {
            refreshData()
            showNotification('success', t('operators-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
      t('common.delete')
    )
  }

  const tableColumns = generateColumns({
    t,
    classes,
    items,
    onModify,
    onDelete,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateOperatorDialog
        open={openCreateOperatorDialog}
        onClose={() => setOpenCreateOperatorDialog(false)}
        onCreated={() => {
          refreshData()
          setOpenCreateOperatorDialog(false)
        }}
      />
      <ModifyOperatorDialog
        open={openModifyOperatorDialog}
        onClose={() => {
          setOpenModifyOperatorDialog(false)
          setOperatorToModify({})
        }}
        operatorToModify={operatorToModify}
        onModified={() => {
          refreshData(true)
          setOpenModifyOperatorDialog(false)
        }}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('operators-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateOperatorDialog(true)}
          >
            {t('operators-list.add-operator')}
          </Button>
        </Grid>
      </Grid>
      <Table
        id={resource}
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
      />
    </>
  )
}
