import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
  Checkbox,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
  Link,
  List,
  ListItem,
  Box,
} from '@mui/material'

import { useParams } from 'react-router-dom'

import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import Table from '../../components/Table'
import enums from '../../utils/enums'
import generateControlHistoryColumns from './columns-control-history'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import { ReactComponent as DeleteIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as DownloadIcon } from '../../assets/icons-Download.svg'

import BaseDialog from '../../components/BaseDialog'
import ResendControlResultDialog from '../../components/ResendControlResultDialog'
import ModifyChassisDialog from '../../components/Chassis/components/ModifyChassisDialog'
import CreateControlDialog from '../../components/Control/components/CreateControlDialog'

import { hasPermission, useUserState } from '../../context/user/UserContext'
import { Permissions } from '../../entities/permissions'

export default function ChasisDetail({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { id } = useParams()
  const { user } = useUserState()

  const [chassis, setChassis] = useState({})
  const [loading, setLoading] = useState(true)
  const [openResendControlResultDialog, setOpenResendControlResultDialog] =
    useState(false)
  const [openModifyChassisDialog, setOpenModifyChassisDialog] = useState(false)

  const controlHistoryResource = 'control'
  const documentsResource = `chassis/${id}/documents`

  // Documents data
  const [loadingDocuments, setLoadingDocuments] = useState(true)
  const [documentsTableState, setDocumentsTableState] = useState({
    page: 0,
    sort: null,
    filters: null,
  })
  const [documentsTableData, setDocumentsTableData] = useState(null)
  const [documentsSelectionState, setDocumentsSelectionState] = useState(
    new Map()
  )
  const availableDocuments =
    documentsTableData && documentsTableData.filter((doc) => doc.available)
  const selectionValues = Array.from(documentsSelectionState.values())
  const anySelected = selectionValues.some((state) => state)
  const allSelected =
    availableDocuments &&
    selectionValues.filter((state) => state).length ===
      availableDocuments.length &&
    availableDocuments.length > 0
  const [uploadSelectedFile, setUploadSelectedFile] = useState(null)

  // Control history data
  const [openCreateControlDialog, setOpenCreateControlDialog] = useState(false)
  const [loadingControlHistory, setLoadingControlHistory] = useState(true)
  const [controlHistoryTableState, setControlHistoryTableState] = useState({
    page: 0,
    sort: null,
    filters: {
      chassisId: id,
    },
  })
  const [controlHistoryTableData, setControlHistoryTableData] = useState(null)

  useEffect(() => {
    dataProvider
      .getOne('chassis', { id })
      .then((response) => {
        setChassis(response.data)
        setLoading(false)
        dataProvider.cache.reset()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    dataProvider
      .getList(controlHistoryResource, {
        range: { page: controlHistoryTableState.page + 1 },
        filters: controlHistoryTableState.filters,
        sort: controlHistoryTableState.sort,
      })
      .then((response) => {
        setControlHistoryTableData(response.data)
        setLoadingControlHistory(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
    setLoadingControlHistory(false)
  }, [id, controlHistoryTableState, dataProvider, showNotification, t])

  useEffect(() => {
    dataProvider
      .getList(documentsResource, {
        range: { page: documentsTableState.page + 1 },
        filters: documentsTableState.filters,
        sort: documentsTableState.sort,
      })
      .then((response) => {
        setDocumentsTableData(response.data)
        setLoadingDocuments(false)
        dataProvider.cache.reset()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [documentsTableState])

  const refreshData = () => {
    dataProvider
      .getOne('chassis', { id })
      .then((response) => {
        setChassis(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }
  const reloadDocuments = () => {
    setDocumentsSelectionState(new Map())
    setDocumentsTableState({ ...documentsTableState })
  }

  const onOpenDocument = (document) => {
    window.open(document.url, '_blank')
  }

  const uploadDocument = (document, type) => {
    let url
    setLoadingDocuments(true)
    dataProvider.chassis.documents
      .requestUpload(chassis.id)
      .then((response) => {
        url = response.data.url
        return dataProvider.chassis.documents.upload(response, document)
      })
      .then(() =>
        dataProvider.chassis.documents.post(
          chassis.id,
          type,
          url,
          document.name
        )
      )
      .then(() => {
        reloadDocuments()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const onUploadDocument = (type) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'application/pdf'

    input.onchange = (e) => {
      // Retrieve file reference
      const file = e.target.files[0]

      // Verify document name
      if (file.name.includes(chassis.nParc)) {
        uploadDocument(file, type)
      } else {
        setUploadSelectedFile({ file, type })
      }
    }

    input.click()
  }

  const setAllDocumentsSelected = () => {
    const isSelected = allSelected
    const tmpDocumets = new Map([])
    availableDocuments.forEach((element) => {
      tmpDocumets.set(element.type, !isSelected)
    })
    setDocumentsSelectionState(tmpDocumets)
  }

  const deleteDocuments = () => {
    setLoadingDocuments(true)
    const types = []
    documentsSelectionState.forEach((value, type) => {
      if (value) {
        types.push(type)
      }
    })
    dataProvider.chassis.documents
      .deleteDocuments(chassis.id, types)
      .then(() => {
        reloadDocuments()
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  const downloadDocuments = () => {
    setLoading(true)
    const ids = documentsTableData
      .map((document) => {
        const selected = documentsSelectionState.get(document.type)
        if (selected) {
          return document.id
        }
        return null
      })
      .filter((id) => id)

    dataProvider.chassis.documents
      .downloadDocuments(chassis.id, ids)
      .then((zipUrl) => {
        setLoading(false)
        setDocumentsSelectionState(new Map([]))
        window.open(zipUrl, '_blank')
      })
      .catch(() => showNotification('error', t('errors.api-error')))
  }

  function computeControlledByLabel(conf, submitUser) {
    if (conf === enums.CONFORMITY.AWAITING_CONTROL) {
      return t('common.require-control')
    }
    return submitUser ? submitUser.fullName : t('common.user-does-not-exist')
  }

  const renderConformity = (conformity) => {
    switch (conformity) {
      case enums.CONFORMITY.INVALID:
        return t('conformity.invalid')
      case enums.CONFORMITY.VALID:
        return t('conformity.valid')
      case enums.CONFORMITY.AWAITING_CONTROL:
        return t('conformity.waiting-result')
      default:
        return t('errors.not-valid')
    }
  }

  function configureChassisDocuments() {
    history.push(
      `/vehicle-types/detail/${chassis.vehicleType.id}?tab=documents`
    )
  }

  const controlHistoryTableColumns = generateControlHistoryColumns({
    t,
    classes,
    onControlClick: (controlId) => {
      history.push(`/controls/detail/${controlId}`)
    },
    renderConformity,
    computeControlledByLabel,
  })

  const {
    operateur,
    vehicleType,
    nParc,
    nChassis,
    location,
    depot,
    conformity,
  } = chassis

  if (loading || loadingControlHistory || loadingDocuments) {
    return <Loading />
  }

  return (
    <Grid container direction="column">
      <BaseDialog
        open={!!uploadSelectedFile}
        onClose={() => {
          setUploadSelectedFile(null)
        }}
        onConfirm={() => {
          uploadDocument(uploadSelectedFile.file, uploadSelectedFile.type)
          setUploadSelectedFile(null)
        }}
        hideCloseButton
        openButtonTitle={t('common.continue')}
      >
        <Typography align="center">
          {t('documents-dialog.upload-document-confirmation-message')}
        </Typography>
      </BaseDialog>
      <ResendControlResultDialog
        open={openResendControlResultDialog}
        onClose={() => setOpenResendControlResultDialog(false)}
        controlId={chassis.id}
        nChassis={nChassis}
      />
      <ModifyChassisDialog
        open={openModifyChassisDialog}
        onClose={() => {
          setChassis({ ...chassis })
          setOpenModifyChassisDialog(false)
        }}
        onCreated={() => {
          refreshData()
          setOpenModifyChassisDialog(false)
        }}
        chassisToModify={chassis}
      />
      <CreateControlDialog
        open={openCreateControlDialog}
        onClose={() => {
          setOpenCreateControlDialog(false)
        }}
        onCreated={() => {
          setControlHistoryTableState({ ...controlHistoryTableState })
          setOpenCreateControlDialog(false)
        }}
        selectedChassis={chassis}
      />
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <PageTitle title={t('chassis-detail.title', { nChassis })} />
          </Grid>
          <Grid item container sm={8} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth={fullWidth}
              onClick={() => setOpenModifyChassisDialog(true)}
            >
              {t('chassis-detail.modify-chassis')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.firstSection} direction="column">
        <Grid
          container
          direction="column"
          className={classes.firstSectionSecondSubSection}
        >
          <Grid item className={classes.marginBottom}>
            <Typography variant="h6" className={classes.textBlack}>
              {t('common.vehicle')}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.vehicleType')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.type}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.nParc')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {nParc}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.depot')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {depot}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.specificity')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.specificity}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.location')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {location}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.operateur')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {operateur}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">{t('common.conformity')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {renderConformity(conformity)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.secondSection} direction="column">
        <Grid container direction="column" wrap="wrap">
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
              className={classes.secondSectionFirstSubSection}
            >
              <Grid item>
                <Checkbox
                  onClick={setAllDocumentsSelected}
                  checked={allSelected}
                  disabled={availableDocuments.length <= 0}
                />
              </Grid>
              <Grid item>
                <Typography
                  align="left"
                  variant="h6"
                  noWrap
                  className={classes.textBlack}
                >
                  {t('chassis-detail.documents-section.title')}
                </Typography>
              </Grid>
              <Grid item container direction="row" justifyContent="flex-end">
                {hasPermission(
                  Permissions.PAGE_CHASSIS_DELETE_DOCUMENTS,
                  user
                ) && (
                  <Grid item>
                    <Tooltip
                      title={t('documents-dialog.delete-documents-hint')}
                    >
                      {/* Show tooltip when button is disabled */}
                      <div>
                        <IconButton
                          id="delete"
                          onClick={deleteDocuments}
                          className={classes.disabledIcon}
                          disabled={!anySelected}
                          size="large"
                        >
                          <DeleteIcon color="primary" />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <Tooltip
                    title={t('documents-dialog.download-documents-hint')}
                  >
                    {/* Show tooltip when button is disabled */}
                    <div>
                      <IconButton
                        id="download"
                        onClick={downloadDocuments}
                        className={classes.disabledIcon}
                        disabled={!anySelected}
                        size="large"
                      >
                        <DownloadIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {documentsTableData.length === 0 && (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography>
                    {t('chassis-detail.documents-section.empty-message')}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    className={classes.textButton}
                    onClick={configureChassisDocuments}
                  >
                    {t('chassis-detail.documents-section.empty-link')}
                  </Button>
                </Grid>
              </Grid>
            )}
            {documentsTableData.length > 0 && (
              <List className={classes.documentsList}>
                {documentsTableData.map((value) => (
                  <ListItem
                    key={[value.id, value.type].join('-')}
                    className={classes.documentsListItem}
                  >
                    <Box ml={-2}>
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        alignItems="flex-start"
                      >
                        <Grid
                          item
                          style={{
                            visibility: value.available ? 'visible' : 'hidden',
                          }}
                        >
                          <Checkbox
                            onClick={() => {
                              const tmpDocuments = new Map(
                                documentsSelectionState
                              )
                              tmpDocuments.set(
                                value.type,
                                !documentsSelectionState.get(value.type)
                              )
                              setDocumentsSelectionState(tmpDocuments)
                            }}
                            checked={
                              documentsSelectionState.get(value.type) === true
                            }
                          />
                        </Grid>
                        <Grid item container direction="column">
                          <Grid item>
                            <Typography
                              id="subtitle"
                              align="left"
                              variant="body1"
                            >
                              {value.typeName}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ wordBreak: 'break-all' }}>
                              <Link
                                id="subtitle"
                                align="left"
                                variant="body2"
                                onClick={() => {
                                  if (value.originalName) {
                                    onOpenDocument(value)
                                  } else if (
                                    hasPermission(
                                      Permissions.PAGE_CHASSIS_ADD_DOCUMENTS,
                                      user
                                    )
                                  ) {
                                    onUploadDocument(value.type)
                                  }
                                }}
                              >
                                {(() => {
                                  const addText = hasPermission(
                                    Permissions.PAGE_CHASSIS_ADD_DOCUMENTS,
                                    user
                                  )
                                    ? t('documents-dialog.add-document')
                                    : null
                                  return value.originalName
                                    ? value.originalName
                                    : addText
                                })()}
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        className={classes.addControlSection}
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth={fullWidth}
          onClick={() => setOpenCreateControlDialog(true)}
        >
          {t('chassis-detail.create-control')}
        </Button>
      </Grid>
      <Grid container className={classes.thirdSection} direction="column">
        <Grid item className={classes.thirdSectionFirstSubSection}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {t('chassis-detail.control-history-section.title')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.tableContainer}>
          <Table
            id={controlHistoryResource}
            columns={controlHistoryTableColumns}
            data={controlHistoryTableData}
            page={controlHistoryTableState.page}
            sort={controlHistoryTableState.sort}
            onChangePage={(currentPage) => {
              setControlHistoryTableState({
                ...controlHistoryTableState,
                page: currentPage,
              })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setControlHistoryTableState({
                ...controlHistoryTableState,
                sort: newSort,
              })
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
