import React from 'react'

// components
import { Button, Grid, Typography } from '@mui/material'

export default function generate({ t, classes, onModify, onDelete }) {
  return [
    {
      name: 'name',
      label: t('depots-list.table-depot-header'),
    },
    {
      name: 'operators',
      label: t('depots-list.table-operator-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          console.log('val', val)
          console.log('tableMeta', tableMeta)
          const operators = val || []
          return operators.map((item) => (
            <Grid item key={item.id}>
              <span>{item.name}</span>
            </Grid>
          ))
        },
      },
    },
    {
      name: 'chassis',
      label: t('depots-list.table-chassis-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          console.log('val', val)
          console.log('tableMeta', tableMeta)
          const operators = val || []
          // Iterate over the first 5 operators and return them as a list of Grid items. If there are more than 5 operators, return a single Grid item with a message.
          let items = operators.slice(0, 5).map((item) => (
            <Grid item key={item.id}>
              <span>{item.nChassis}</span>
            </Grid>
          ))
          if (operators.length > 5) {
            items.push(
              <Grid item key="more">
                <Typography variant="body" style={{ fontWeight: 600 }}>
                  {t('depots-list.table-more-chassis', {
                    nChassis: operators.length - 5,
                  })}
                </Typography>
              </Grid>
            )
          }
          return items
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDelete(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
