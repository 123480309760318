import React from 'react'
import { Snackbar } from '@mui/material'

import MuiAlert from '@mui/material/Alert'

const NotificationsStateContext = React.createContext()
const NotificationsDispatchContext = React.createContext()

function notificationReducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        isNotificationOpened: true,
        severity: action.payload.severity,
        message: action.payload.message,
      }
    case 'CLOSE':
      return { ...state, isNotificationOpened: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function NotificationsProvider({ children }) {
  const [state, dispatch] = React.useReducer(notificationReducer, {
    isNotificationOpened: false,
  })
  const showNotification = (severity, message) => {
    dispatch({ type: 'OPEN', payload: { severity, message } })
  }
  return (
    <NotificationsStateContext.Provider value={{ state, showNotification }}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        <Snackbar
          open={state.isNotificationOpened}
          autoHideDuration={6000}
          onClose={() => dispatch({ type: 'CLOSE', payload: {} })}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => dispatch({ type: 'CLOSE', payload: {} })}
            severity={state.severity}
          >
            {state.message}
          </MuiAlert>
        </Snackbar>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  )
}

function useNotificationsProvider() {
  const context = React.useContext(NotificationsStateContext)
  if (context === undefined) {
    throw new Error(
      'useNotificationsState must be used within a NotificationsProvider'
    )
  }
  return context
}

function useNotificationsDispatch() {
  const context = React.useContext(NotificationsDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useNotificationsDispatch must be used within a NotificationsProvider'
    )
  }
  return context
}

export {
  NotificationsProvider,
  useNotificationsProvider,
  useNotificationsDispatch,
}
