import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function ModifyOperatorDialog({
  open,
  onClose,
  onModified,
  operatorToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [depots, setDepots] = useState([])
  const [member, setMember] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')

    if (operatorToModify) {
      setName(operatorToModify.name)
      if (operatorToModify.depots) {
        setDepots(
          operatorToModify.depots.map((depot) => ({
            id: depot.id,
            title: depot.name,
          }))
        )
      } else {
        setDepots([])
      }
      setMember(
        operatorToModify.member
          ? {
              id: operatorToModify.member.id,
              title: operatorToModify.member.name,
            }
          : null
      )
    } else {
      setName('')
      setDepots([])
      setMember(null)
    }
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (depots.length === 0) {
      setError('depotIds_required')
      return
    }

    setLoading(true)
    const operatorId = operatorToModify.id
    const memberId = member ? member.id : null
    dataProvider.operator
      .put(
        operatorId,
        name,
        depots.map((depot) => depot.id),
        memberId
      )
      .then(() => {
        dataProvider.cache.reset()
        onModified()
        showNotification('success', t('modify-operator-dialog.success'))
        resetState()
      })
      .catch((err) => {
        if (err.response.data.error === 'OPERATOR_ALREADY_EXISTS') {
          showNotification(
            'error',
            t('modify-operator-dialog.already-exists-error')
          )
        } else {
          showNotification('error', t('errors.api-error'))
        }
        setLoading(false)
      })
  }

  useEffect(() => {
    if (operatorToModify) {
      setName(operatorToModify.name)
      if (operatorToModify.depots) {
        setDepots(
          operatorToModify.depots.map((depot) => ({
            id: depot.id,
            title: depot.name,
          }))
        )
      }
      setMember(
        operatorToModify.member
          ? {
              id: operatorToModify.member.id,
              title: operatorToModify.member.name,
            }
          : null
      )
    }
  }, [operatorToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-operator-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-operator-dialog.operator-name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setMember}
            value={member}
            label={t('create-operator-dialog.member')}
            resource="/operators/autocomplete-members"
            error={error === 'member_required'}
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setDepots}
            value={depots}
            label={t('create-operator-dialog.depots')}
            resource="/operators/autocomplete-depots"
            error={error === 'depotIds_required'}
            helperText={t('common.required')}
            multiple
            required
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
