import React from 'react'

// components
import { Button, Grid } from '@mui/material'

export default function generate({ t, classes, items, onModify, onDelete }) {
  return [
    {
      name: 'name',
      label: t('constructors-list.constructor-table-header'),
    },
    {
      name: 'vehicleTypes',
      label: t('constructors-list.vehicle-type-table-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (items[tableMeta.rowIndex] !== undefined) {
            // eslint-disable-next-line max-len
            return items[tableMeta.rowIndex].vehicleTypes
              .map((type) => type.title)
              .map((value, i) => (
                <Grid item key={items[tableMeta.rowIndex].vehicleTypes[i].id}>
                  <span>{value}</span>
                </Grid>
              ))
          }

          return ''
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDelete(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
