import React from 'react'

// components
import { Button } from '@mui/material'

export default function generate({ t, classes, onModifyEmail, onDeleteEmail }) {
  return [
    {
      name: 'email',
      label: t('email-lists-list.emails-table-email-header'),
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModifyEmail(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDeleteEmail(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
