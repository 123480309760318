import React, { useState, useEffect, useCallback, useMemo } from 'react'

// components
import { useTranslation } from 'react-i18next'
import { Grid, Button, useMediaQuery, useTheme } from '@mui/material'
import useStyles from './styles'
import VehicleTypeAssociationDialog from '../../../../components/DocumentType/components/VehicleTypeAssociationDialog'
import Table from '../../../../components/Table'
import generateColumns from './columns'

import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import { useDataApi } from '../../../../context/DataApiContext'

import { ReactComponent as AddOutlineIcon } from '../../../../assets/Icons_Add_Outline.svg'
import { ReactComponent as TrashIcon } from '../../../../assets/icons-Delete.svg'
import Loading from '../../../../components/Loading'
import { Stack } from '@mui/system'

export default function Documents({ vehicleType }) {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('sm'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const classes = useStyles()
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [tableState, setTableState] = useState({
    page: 0,
    sort: null,
    rowsSelected: [],
    tableData: null,
    loading: true,
    error: false,
    sendingChanges: false,
    errorSendingChanges: false,
    reload: 0,
  })

  const [openCreateDialog, setOpenCreateDialog] = useState(false)

  const resource = 'document-type'

  const loadData = useCallback((vehicleTypeId, page, sort) => {
    setTableState((state) => ({
      ...state,
      loading: true,
      error: false,
    }))
    dataProvider
      .getList(
        resource,
        {
          range: { page: page + 1 },
          filters: { vehicleTypeId },
          sort: sort,
        },
        {}
      )
      .then((response) => {
        setTableState((state) => ({
          ...state,
          tableData: response.data,
          loading: false,
          error: false,
        }))
      })
      .catch(() => {
        setTableState((state) => ({
          ...state,
          tableData: null,
          loading: false,
          error: true,
        }))
      })
  })

  useEffect(() => {
    if (vehicleType) {
      loadData(vehicleType.id, tableState.page, tableState.sort)
    }
  }, [tableState.page, tableState.sort, tableState.reload, vehicleType])

  useEffect(() => {
    if (!tableState.error || !tableState.errorSendingChanges) {
      return
    }

    showNotification('error', t('errors.api-error'))
  }, [tableState.error, tableState.errorSendingChanges])

  const documentTypes = useMemo(() => {
    if (!tableState.tableData || !Array.isArray(tableState.tableData.items)) {
      return []
    }

    return tableState.tableData.items.map((item) => ({
      id: item.documentType.id,
      title: item.documentType.name,
      requiredForConformance: !!item.requiredForConformance,
    }))
  }, [tableState.tableData])

  const refreshData = useCallback(() => {
    dataProvider.cache.reset()
    setTableState((state) => ({
      ...state,
      page: 0,
      sort: null,
      rowsSelected: [],
      reload: state.reload + 1,
    }))
  }, [dataProvider.cache])

  const performDelete = (selection) => {
    const selectionSet = new Set(selection)
    const filteredDocumentTypes = documentTypes.filter(
      (_, index) => !selectionSet.has(index)
    )
    sendChanges(filteredDocumentTypes)
  }

  const onDeleteSelection = () => {
    if (tableState && tableState.rowsSelected) {
      performDelete(tableState.rowsSelected)
    }
  }

  const onMandatoryToggle = (rowIndex) => {
    if (rowIndex >= 0 && rowIndex < documentTypes.length) {
      const updatedDocumentTypes = documentTypes.map((x) => ({ ...x }))
      updatedDocumentTypes[rowIndex].requiredForConformance =
        !updatedDocumentTypes[rowIndex].requiredForConformance
      sendChanges(updatedDocumentTypes)
    }
  }

  const tableColumns = generateColumns({
    t,
    onMandatoryToggle,
    disableMandatoryToggle: tableState.sendingChanges || tableState.loading,
  })

  const sendChanges = useCallback(
    (documentTypes) => {
      setTableState((state) => ({
        ...state,
        sendingChanges: true,
        errorSendingChanges: false,
      }))
      const vehicleTypeId = vehicleType.id
      const body = documentTypes.reduce(
        (acc, docType) => {
          if (docType.id < 0) {
            acc.new.push({
              title: docType.title,
              requiredForConformance: docType.requiredForConformance,
            })
          } else {
            acc.selection.push({
              id: docType.id,
              requiredForConformance: docType.requiredForConformance,
            })
          }
          return acc
        },
        { new: [], selection: [] }
      )
      dataProvider.vehicleType
        .associateDocumentTypes(vehicleTypeId, body)
        .then(() => {
          dataProvider.cache.reset()
          refreshData()
          showNotification(
            'success',
            t('vehicle-type-associate-document-type-dialog.success')
          )
          setTableState((state) => ({
            ...state,
            sendingChanges: false,
            errorSendingChanges: false,
          }))
        })
        .catch(() => {
          setTableState((state) => ({
            ...state,
            sendingChanges: false,
            errorSendingChanges: true,
          }))
        })
    },
    [vehicleType]
  )

  if ((tableState.loading && !tableState.tableData) || !resource) {
    return <Loading minHeight="15vh" />
  }

  return (
    <>
      <VehicleTypeAssociationDialog
        open={openCreateDialog}
        onClose={() => {
          setOpenCreateDialog(false)
        }}
        onConfirm={(documentTypes) => {
          setOpenCreateDialog(false)
          sendChanges(documentTypes)
        }}
        originalDocumentTypes={documentTypes}
      />
      <Stack
        direction={largeScreen ? 'row' : 'column'}
        spacing={2}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
        p={1}
      >
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={[classes.allItemsButton].join(' ')}
          onClick={() => setOpenCreateDialog(true)}
        >
          <AddOutlineIcon id="icon" />
          <span id="text">{t('common.add')}</span>
        </Button>
        <Button
          variant="contained"
          fullWidth={fullWidth}
          className={[classes.allItemsButton].join(' ')}
          onClick={() => onDeleteSelection()}
          disabled={
            tableState &&
            tableState.rowsSelected &&
            tableState.rowsSelected.length <= 0
          }
        >
          <TrashIcon id="icon" />
          <span id="text">{t('vehicle-type-detail.remove')}</span>
        </Button>
      </Stack>
      <Grid item className={classes.tableContainer}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableState.tableData}
          page={tableState.page}
          sort={tableState.sort}
          selectableRows="multiple"
          onChangePage={(currentPage) => {
            setTableState(() => ({
              ...tableState,
              page: currentPage,
              rowsSelected: [],
            }))
          }}
          onColumnSortChange={(changedColumn, direction) => {
            let newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            if (direction === 'none') {
              newSort = null
            }
            setTableState(() => ({
              ...tableState,
              sort: newSort,
              rowsSelected: [],
            }))
          }}
          rowsSelected={tableState.rowsSelected}
          onRowSelectionChange={(rowsSelected, allRows) => {
            setTableState((state) => ({
              ...state,
              rowsSelected: allRows.map((row) => row.dataIndex),
            }))
          }}
        />
      </Grid>
    </>
  )
}
