import React, { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'
import useStyles from './styles'

const State = {
  selected: 'selected',
  disabled: 'disabled',
  done: 'done',
}
const ElementType = {
  circle: 'circle',
  line: 'line',
}

export default function WizardHeader({
  numberOfSteps,
  selectedStep = 0,
  onStepClick = (step) => {
    console.log(step)
  },
}) {
  const classes = useStyles()

  const clampedSelectedStep = Math.max(Math.min(numberOfSteps, selectedStep), 0)

  const elements = useMemo(() => {
    const steps = Array.from(Array(numberOfSteps), (_, step) => {
      let state = State.disabled
      if (step === clampedSelectedStep) {
        state = State.selected
      } else if (step < clampedSelectedStep) {
        state = State.done
      }
      return {
        type: ElementType.circle,
        step,
        label: String(step + 1),
        state,
      }
    })
    return steps.reduce((stepsAndLines, step, index) => {
      const updatedStepsAndLines = [...stepsAndLines, step]
      if (index < steps.length - 1) {
        updatedStepsAndLines.push({
          type: ElementType.line,
          step: step.step,
          label: [step.label, 'line'].join('-'),
          state: step.state,
        })
      }
      return updatedStepsAndLines
    }, [])
  }, [numberOfSteps, clampedSelectedStep])

  const onStepClickHandler = (evt, step) => {
    if (step < clampedSelectedStep) {
      evt.stopPropagation()
      onStepClick(step)
    }
  }
  return (
    <Stack
      justifyContent="center"
      spacing={1}
      direction="row"
      alignItems="center"
      className={classes.root}
    >
      {elements.map((element) => (
        <div key={element.label}>
          {element.type === ElementType.circle ? (
            <CircularStep
              label={element.label}
              state={element.state}
              onClick={(evt) => {
                onStepClickHandler(evt, element.step)
              }}
            />
          ) : (
            <LineBetweenStep state={element.state} />
          )}
        </div>
      ))}
    </Stack>
  )
}

function CircularStep({ label, onClick, state = State.selected }) {
  const classes = useStyles()

  return (
    <div className={[classes.circularStep, state].join(' ')} onClick={onClick}>
      <Typography>{label}</Typography>
    </div>
  )
}

function LineBetweenStep({ state }) {
  const classes = useStyles()

  return (
    <div className={[classes.lineBetweenStep, state].join(' ')}>
      <div className={[classes.lineBetweenStepInner, state].join(' ')} />
    </div>
  )
}
