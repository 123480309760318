import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: 'calc(100vw - 200px)',
    minHeight: '100vh',
    overflow: 'auto',
  },
  contentShift: {
    width: 'calc(100vw - 60px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
}))
