class SerialQueue {
  constructor() {
    this.basePromise = Promise.resolve()
  }

  enqueue(jobFn) {
    const jobPromise = this.basePromise.then(() => {
      return jobFn()
    })
    this.basePromise = jobPromise.catch((...args) => {
      console.log('Job failed!', args)
    })
    return jobPromise
  }

  enqueueAsync(jobFn) {
    this.enqueue(jobFn).catch(() => {})
  }
}

export default SerialQueue
