import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  emailsListTitle: {
    paddingBottom: 6,
    marginBottom: 30,
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.captGrey.light,
  },
  emailsList: {
    maxHeight: 230,
    overflowY: 'scroll',
    padding: 0,
  },
  emailsListContainer: {
    overflow: 'hidden',
    border: '1px solid',
    borderColor: theme.palette.captGrey.light,
    borderRadius: 5,
  },
  emailsListItem: {
    padding: 17,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.captGrey.light,
    '&:last-child': {
      borderBottomWidth: 0,
    },
    cursor: 'pointer',
    userSelect: 'none',
  },
}))
