import React from 'react'
import { SvgIcon } from '@mui/material'

const ChassisIcon = () => (
  <SvgIcon>
    <path
      fillRule="evenodd"
      d="M8 3H4v6h4V7h2.268c.175.304.428.557.732.732v8.536c-.304.175-.557.428-.732.732H8v-2H4v6h4v-2h2.268c.345.598.992 1 1.732 1s1.387-.402 1.732-1H16v2h4v-6h-4v2h-2.268c-.175-.304-.428-.557-.732-.732V7.732c.304-.175.557-.428.732-.732H16v2h4V3h-4v2h-2.268c-.345-.598-.992-1-1.732-1s-1.387.402-1.732 1H8V3z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default ChassisIcon
