import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

export default function DeleteChassisDialog({
  open,
  onClose,
  onSuccess,
  chassisIds,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      onClose()
    }
  }

  const onConfirm = () => {
    setLoading(true)
    dataProvider.chassis
      .deleteBulk({ chassisIds })
      .then(() => {
        dataProvider.cache.reset()
        setLoading(false)
        showNotification('success', t('delete-chassis-dialog.success'))
        onSuccess()
      })
      .catch(() => {
        setLoading(false)
        showNotification('error', t('errors.api-error'))
      })
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('delete-chassis-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography>
            {chassisIds.length > 1
              ? t('delete-chassis-dialog.message-multiple', {
                  count: chassisIds.length,
                })
              : t('delete-chassis-dialog.message-single')}
          </Typography>
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
