import axios from 'axios'
import wrapPromise from '../utils/wrapers'
import config from '../config'
import AuthRetrier from './authRetrier'
import Storages from '../context/user/storages'

const axiosInstance = axios.create({
  baseURL: `${config.apiBaseUrl}/su/auth`,
  headers: {
    'Content-Type': 'application/json',
  },
})

const fetchUserRequest = () => {
  // Get token from local storage
  const tokens = Storages.tokens.inflate()
  if (tokens) {
    const { token } = tokens
    const axiosInstanceMe = axios.create({
      baseURL: `${config.apiBaseUrl}/su/auth`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    AuthRetrier(axiosInstanceMe)
    return axiosInstanceMe.get('/me')
  }
  return Promise.resolve()
}

// This wrap allows to use React Suspense
const fetchUser = wrapPromise(fetchUserRequest())

const loginUser = (email, password) => {
  const payload = {
    email,
    password,
  }
  return axiosInstance.post('/login', payload)
}

const resetPassword = (email) => {
  const payload = {
    email,
  }
  return axiosInstance.post('/forgot-password', payload)
}

export default {
  loginUser,
  fetchUser,
  fetchUserRequest,
  resetPassword,
}
