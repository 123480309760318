import React from 'react'

const DepotsIcon = ({ color = 'currentColor' }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24.000000pt"
    height="24.000000pt"
    viewBox="0 0 1024.000000 1024.000000"
    fill={color}
  >
    <g transform="translate(-124.000000,1024.000000) scale(0.100000,-0.100000)">
      <path
        d="M5030 8926 c-78 -17 -122 -42 -320 -180 -96 -67 -240 -167 -320 -221
-80 -55 -185 -128 -235 -164 -49 -36 -247 -170 -440 -299 -428 -287 -498 -335
-745 -506 -107 -75 -244 -170 -305 -211 -60 -42 -164 -114 -230 -160 -66 -46
-235 -161 -375 -256 -379 -256 -586 -405 -609 -438 -34 -48 -53 -111 -54 -187
-1 -59 3 -79 28 -128 50 -100 114 -136 239 -136 86 0 104 9 307 163 90 68 233
173 318 233 84 60 170 121 190 135 33 25 190 132 585 400 78 52 195 133 261
179 66 46 210 143 320 217 110 73 225 151 256 172 32 22 176 119 320 217 145
98 282 192 304 210 114 90 577 394 602 394 13 0 51 -17 86 -39 136 -83 715
-481 962 -661 28 -20 122 -84 210 -144 88 -59 264 -180 390 -268 127 -89 392
-273 590 -408 533 -365 883 -612 1014 -718 105 -84 180 -105 273 -78 70 20
117 61 159 138 29 54 34 72 33 128 -1 87 -32 164 -86 217 -38 36 -362 262
-508 353 -30 19 -93 62 -140 96 -47 33 -149 105 -228 160 -79 54 -241 167
-360 251 -119 83 -307 214 -417 289 -110 76 -222 154 -250 173 -63 45 -384
264 -580 396 -370 250 -699 475 -807 551 -137 97 -175 118 -246 133 -66 14
-121 13 -192 -3z"
      />
      <path
        d="M4715 7050 c-728 -39 -1117 -202 -1253 -525 -60 -141 -56 -62 -60
-1345 -3 -1317 -6 -1249 69 -1400 22 -44 62 -103 89 -133 l49 -53 3 -220 3
-219 28 -48 c15 -26 47 -60 69 -75 40 -26 46 -27 207 -30 91 -2 179 -1 197 2
42 8 113 71 135 120 15 33 19 66 19 169 l0 127 845 0 845 0 0 -117 c0 -138 10
-181 52 -229 59 -67 86 -74 278 -74 138 0 177 3 203 16 45 23 76 53 100 99 20
37 22 57 25 259 l3 219 54 60 c29 32 70 92 90 132 68 139 66 85 62 1398 -2
1101 -4 1186 -21 1242 -111 376 -443 555 -1136 615 -160 14 -762 20 -955 10z
m1675 -1380 l0 -530 -1275 0 -1275 0 0 530 0 530 1275 0 1275 0 0 -530z
m-2108 -1213 c193 -89 243 -336 100 -498 -78 -89 -220 -123 -334 -79 -202 76
-267 339 -123 499 97 107 231 137 357 78z m1920 0 c193 -89 243 -336 100 -498
-78 -89 -220 -123 -334 -79 -202 76 -267 339 -123 499 97 107 231 137 357 78z"
      />
      <path
        d="M7549 6473 c0 -4 0 -811 0 -1793 1 -982 -3 -1837 -7 -1901 -7 -102
-12 -125 -40 -182 -40 -80 -118 -154 -212 -200 l-65 -32 -2095 -3 -2096 -2
-79 39 c-131 63 -194 126 -239 234 l-21 52 -5 1882 c-4 1327 -8 1885 -16 1893
-8 8 -43 -15 -125 -80 -63 -50 -169 -127 -236 -173 l-123 -82 0 -1775 c0
-1955 -3 -1839 64 -1984 111 -241 301 -406 574 -498 l67 -23 2190 -3 c2435 -3
2236 -8 2408 64 160 67 264 149 372 293 22 30 53 69 68 87 15 18 46 81 69 141
l41 108 1 1800 1 1800 -70 47 c-142 94 -341 232 -382 265 -24 19 -43 31 -44
26z"
      />
      <path
        d="M3390 2933 c-234 -3 -245 -4 -267 -25 -44 -42 -22 -106 45 -130 54
-19 3770 -19 3852 0 92 21 126 78 81 133 l-19 24 -1079 -5 c-1090 -5 -1676 -4
-2123 2 -135 2 -355 3 -490 1z"
      />
    </g>
  </svg>
)

export default DepotsIcon
