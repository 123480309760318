import React, { useState } from 'react'
import { TextField, Grid } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import { Roles } from '../../../../entities/permissions'

// styles
// import useStyles from './styles'
import BaseDialog from '../../../BaseDialog'
import validators from '../../../../utils/validators'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function CreateUserDialog({
  title,
  open,
  onClose,
  onCreated,
  isContractor,
}) {
  // const classes = useStyles()

  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState(null)
  const [constructeur, setConstructor] = useState(null)
  const [operators, setOperators] = useState([])
  const [member, setMember] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError(null)
    setFirstName('')
    setLastName('')
    setEmail('')
    setRole(null)
    setConstructor(null)
    setOperators([])
    setMember(null)
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (firstName.length === 0) {
      setError('firstName_required')
      return
    }

    if (lastName.length === 0) {
      setError('lastName_required')
      return
    }

    if (email.length === 0) {
      setError('email_required')
      return
    }

    if (!validators.isValidEmail(email)) {
      setError('email_invalid')
      return
    }

    if (!isContractor) {
      if (!role) {
        setError('role_required')
        return
      }
      if (role.id === Roles.CONSTRUCTOR && !constructeur) {
        setError('constructor_required')
        return
      }
      if (role.id === Roles.OPERATOR) {
        if (!operators || !Array.isArray(operators)) {
          setError('operator_required')
          return
        }
        if (operators.length === 0) {
          setError('operator_required')
          return
        }
      }
      if (role.id === Roles.MEMBER && !member) {
        setError('member_required')
        return
      }
    }

    setLoading(true)

    if (isContractor) {
      dataProvider.contractor
        .post(firstName, lastName, email)
        .then(() => {
          dataProvider.cache.reset()
          showNotification('success', t('create-user-dialog.success'))
          onCreated()
        })
        .catch(() => {
          showNotification('error', t('errors.api-error'))
        })
        .then(() => resetState())
    } else {
      const data = {
        firstName,
        lastName,
        email,
        roleId: role.id,
        constructorId:
          role.id === Roles.CONSTRUCTOR ? constructeur.id : undefined,
        operators:
          role.id === Roles.OPERATOR ? operators.map((op) => op.id) : undefined,
        memberId: role.id === Roles.MEMBER ? member.id : undefined,
      }
      dataProvider
        .create('users', { data })
        .then(() => {
          dataProvider.cache.reset()
          showNotification('success', t('create-user-dialog.success'))
          onCreated()
          resetState()
        })
        .catch((err) => {
          setLoading(false)
          if (
            err.response.data.validationErrors &&
            err.response.data.validationErrors.length > 0
          ) {
            showNotification(
              'error',
              err.response.data.validationErrors[0].errorMessage
            )
            setError(`${err.response.data.validationErrors[0].field}_invalid`)
          } else {
            showNotification('error', t('errors.api-error'))
          }
        })
    }
  }

  function renderConstructorFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setConstructor}
          value={constructeur}
          label={t('create-user-dialog.constructor-label')}
          resource="/constructors/autocomplete"
          error={error === 'constructor_required'}
          required
          canDelete
        />
      </Grid>
    )
  }

  function renderOperatorFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setOperators}
          value={operators}
          label={t('create-user-dialog.operator')}
          resource="/operators/autocomplete"
          error={error === 'operator_required'}
          required
          multiple
          canDelete
        />
      </Grid>
    )
  }

  function renderMemberFields() {
    return (
      <Grid item>
        <AutoCompleteInput
          onChange={setMember}
          value={member}
          label={t('create-user-dialog.member')}
          resource="/members/autocomplete"
          error={error === 'member_required'}
          required
          canDelete
        />
      </Grid>
    )
  }

  function renderSelectedRoleFields() {
    if (!role) {
      return <></>
    }

    if (role.id === Roles.CONSTRUCTOR) {
      return renderConstructorFields()
    }
    if (role.id === Roles.OPERATOR) {
      return renderOperatorFields()
    }
    if (role.id === Roles.MEMBER) {
      return renderMemberFields()
    }

    return <></>
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            id="firstName"
            label={t('common.firstName')}
            error={error === 'firstName_required'}
            helperText={t('common.required')}
            type="text"
            value={firstName}
            fullWidth
            required
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="lastName"
            label={t('common.lastName')}
            error={error === 'lastName_required'}
            helperText={t('common.required')}
            value={lastName}
            type="text"
            fullWidth
            required
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="standard"
            id="email"
            label={t('common.email')}
            error={error === 'email_required' || error === 'email_invalid'}
            helperText={
              error === 'email_invalid'
                ? t('errors.not-valid')
                : t('common.required')
            }
            value={email}
            type="text"
            fullWidth
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        {!isContractor && (
          <Grid item>
            <AutoCompleteInput
              onChange={setRole}
              value={role}
              label={t('create-user-dialog.role')}
              resource="/users/roles-autocomplete"
              error={error === 'role_required'}
              required
              canDelete
            />
          </Grid>
        )}
        {renderSelectedRoleFields()}
      </Grid>
    </BaseDialog>
  )
}
