import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Grid } from '@mui/material'

import BaseDialog from '../../../BaseDialog'
import DatePicker from '../../../DatePicker'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import validators from '../../../../utils/validators'

export default function CreateControlDialog({
  open,
  onClose,
  onCreated,
  selectedControl,
  selectedChassis,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [nParc, setNParc] = useState('')
  const [controlDate, setControlDate] = useState(new Date())

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    if (selectedControl) {
      setNParc(selectedControl.nParc)
      setControlDate(Date.parse(selectedControl.controlDate))
    } else if (selectedChassis) {
      setNParc(selectedChassis.nParc)
      setControlDate(new Date())
    } else {
      setNParc('')
      setControlDate(new Date())
    }
  }

  const onCloseDialog = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    const shouldValidateNParc = !selectedControl
    if (shouldValidateNParc) {
      if (nParc.length === 0) {
        setError('nParc_required')
        return
      }

      if (!validators.isValidNParc(nParc)) {
        setError('nParc_invalid')
        return
      }
    }

    if (controlDate === null) {
      setError('date_required')
      return
    }

    const controlDateISOString = controlDate.toISOString()
    const data = {
      controlDate: controlDateISOString,
    }
    if (!selectedControl) {
      data.nParc = nParc
    }
    setLoading(true)
    const remoteOp = selectedControl
      ? dataProvider.update('control', { data, id: selectedControl.controlId })
      : dataProvider.create('control', { data })

    remoteOp
      .then(() => {
        dataProvider.cache.reset()
        const titleKey = selectedControl
          ? 'edit-control-dialog.success'
          : 'create-control-dialog.success'
        showNotification('success', t(titleKey))
        onCreated()
        resetState()
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (
          err.response.data.validationErrors &&
          err.response.data.validationErrors.length > 0
        ) {
          showNotification(
            'error',
            err.response.data.validationErrors[0].errorMessage
          )
          setError(`${err.response.data.validationErrors[0].field}_invalid`)
        } else {
          showNotification('error', t('errors.api-error'))
        }
      })
  }

  useEffect(() => {
    if (selectedControl) {
      setNParc(selectedControl.nParc)
      setControlDate(Date.parse(selectedControl.controlDate))
    } else if (selectedChassis) {
      setNParc(selectedChassis.nParc)
    }
  }, [selectedControl, selectedChassis])

  let nParcHelperTest = null
  if (error === 'nParc_invalid') {
    nParcHelperTest = t('create-control-dialog.nParc-invalid-format')
  } else if (!selectedControl) {
    nParcHelperTest = t('common.required')
  }
  const titleKey = selectedControl
    ? 'edit-control-dialog.title'
    : 'create-control-dialog.title'
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t(titleKey)}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            variant="standard"
            required
            error={error === 'nParc_required' || error === 'nParc_invalid'}
            helperText={nParcHelperTest}
            id="nParc"
            label={t('common.nParc')}
            fullWidth
            value={nParc}
            onChange={(event) => setNParc(event.target.value)}
            disabled={!!selectedControl}
          />
        </Grid>
        <Grid item>
          <DatePicker
            id="startDate"
            value={controlDate}
            onChange={setControlDate}
            label={t('common.controlDate')}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
