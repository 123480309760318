const wrapPromise = (promise) => {
  // Set inital status
  let status = 'pending'
  // Store result
  let result
  // Wait for promise
  const suspender = promise.then(
    (res) => {
      status = 'success'
      result = res
    },
    (err) => {
      status = 'error'
      result = err
    }
  )
  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      } else {
        // Success
        return result
      }
    },
  }
}

export default wrapPromise

const consumeEvent = (callbackFn) => {
  return (event) => {
    event.preventDefault()
    event.stopPropagation()
    callbackFn(event)
  }
}

export { consumeEvent }
