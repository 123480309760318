import React from 'react'

// components
import { Button, Grid } from '@mui/material'

export default function generate({ t, classes, items, onModify, onDelete }) {
  return [
    {
      name: 'name',
      label: t('operators-list.name-table-header'),
    },
    {
      name: 'depots',
      label: t('operators-list.depots-table-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (items[tableMeta.rowIndex] !== undefined) {
            return items[tableMeta.rowIndex].depots.map((depot) => (
              <Grid item key={depot.id}>
                <span>{depot.name}</span>
              </Grid>
            ))
          }

          return ''
        },
      },
    },
    {
      name: 'member.name',
      label: t('operators-list.members-table-header'),
      options: {
        sort: false,
        // customBodyRender: (val, tableMeta) => {
        //   if (items[tableMeta.rowIndex] !== undefined) {
        //     return (items[tableMeta.rowIndex].members.map((member) => (
        //       <Grid item key={member.id}>
        //         <span>{member.name}</span>
        //       </Grid>
        //     ))
        //     )
        //   }

        //   return ''
        // },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDelete(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.delete')}
          </Button>
        ),
      },
    },
  ]
}
