import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  disabled: {
    color: '#B9B9B9',
  },
  icon: {
    fill: '#B9B9B9',
  },
  linkButton: {
    textTransform: 'capitalize',
  },
  menuItemRow: {
    height: '100%',
    alignItems: 'center',
    '& #text': {
      paddingLeft: '10px',
    },
    '& #icon': {
      paddingTop: '3px',
    },
  },
}))
