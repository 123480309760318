import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  ListItem,
  List,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import BaseDialog from '../BaseDialog'

import useStyles from './styles'
import { ReactComponent as CheckIcon } from '../../assets/Icons_Check.svg'

import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'

export default function ResendControlResultDialog({
  open,
  onClose,
  nChassis,
  controlId,
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const [emailLists, setEmailLists] = useState(new Set())
  const [emailListsSelectionState, setEmailListsSelectionState] = useState(
    new Set()
  )
  const [searchEmailList, setSearchEmailList] = useState('')
  const [debouncedValue, setDebouncedValue] = useState(searchEmailList)

  const [failedLoad, setFailedLoad] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingEmailLists, setLoadingEmailLists] = useState(false)

  const onResend = () => {
    setLoading(true)

    // Only send selected values
    const selectedEmailLists = Array.from(emailLists)
      .filter((_, idx) => emailListsSelectionState.has(idx))
      .map((list) => list.id)

    dataProvider.control
      .emailResultByList(controlId, selectedEmailLists)
      .then(() => {
        showNotification(
          'success',
          t('resend-control-result-by-list-dialog.success')
        )
        onClose()
      })
      .catch(() => {
        showNotification('error', t('errors.api-error'))
      })
      .then(() => setLoading(false))
  }

  useEffect(() => {
    if (!controlId || !open) {
      return
    }

    setLoadingEmailLists(true)
    dataProvider.utils
      .autocompleteEmailLists(debouncedValue)
      .then((response) => {
        setEmailLists(new Set(response.data))
        setEmailListsSelectionState(new Set())
        setLoadingEmailLists(false)
      })
      .catch(() => {
        setLoadingEmailLists(false)
        setFailedLoad(true)
      })
  }, [controlId, open, dataProvider, debouncedValue])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  // useEffect to handle debounce
  useEffect(() => {
    // Set a timeout to update debouncedValue after 500ms of delay
    const handler = setTimeout(() => {
      setDebouncedValue(searchEmailList)
    }, 1000)

    // Clean up the timeout if value changes before the delay completes
    return () => {
      clearTimeout(handler)
    }
  }, [searchEmailList])

  const emailsList = () => (
    <div className={classes.emailsListContainer}>
      <List className={classes.emailsList}>
        {Array.from(emailLists).map((list, idx) => (
          <ListItem
            className={classes.emailsListItem}
            key={list.title}
            onClick={() => {
              const newSet = new Set(emailListsSelectionState)
              if (newSet.has(idx)) {
                newSet.delete(idx)
              } else {
                newSet.add(idx)
              }
              setEmailListsSelectionState(newSet)
            }}
          >
            <Grid container direction="row" alignContent="center" wrap="nowrap">
              <Grid item>
                <Typography display="inline" style={{ whiteSpace: 'nowrap' }}>
                  {list.title}
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end">
                <CheckIcon
                  visibility={
                    emailListsSelectionState.has(idx) ? 'visible' : 'hidden'
                  }
                />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t('resend-control-result-by-list-dialog.title', { nChassis })}
      loading={loading}
      onConfirm={onResend}
      openButtonTitle={t('resend-control-result-by-list-dialog.confirm-title')}
      confirmDisabled={emailListsSelectionState.size <= 0}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography id="subtitle" align="left" variant="h6">
            {t('resend-control-result-by-list-dialog.subtitle', { nChassis })}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" wrap="nowrap">
            <Grid item style={{ flex: 1 }}>
              <TextField
                variant="standard"
                id="newEmail"
                label={t(
                  'resend-control-result-by-list-dialog.email-list-placelhoder'
                )}
                fullWidth
                value={searchEmailList}
                onChange={(event) => setSearchEmailList(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography
            id="subtitle"
            align="left"
            variant="h6"
            className={classes.emailsListTitle}
          >
            {t('resend-control-result-by-list-dialog.emails-lists-title')}
          </Typography>

          {loadingEmailLists ? (
            <Grid container direction="column" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            emailsList()
          )}
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
